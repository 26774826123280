@import url('../../../global.css');
.inputfield-label-h {
  font-family: var(--def-font);
  font-size: 2vh;
  margin: 0 2vh 0 0;
}

.inputfield-label-v {
  font-family: var(--def-font);
  font-size: 2vh;
  margin: 0 0 2vh 0;
}

.input-icon {
  height: 3vh;
  width: 3vh;
}

.inputField-container-h {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.inputField-container-v {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.inputField-input-norm {
  height: fit-content;
  font-family: var(--def-font);
  font-size: var(--font-size);
  border: 1px solid #d5d5d5;
  padding: 0.3rem 0.5rem;
  border-radius: 0.5vh;
}

.inputField-input-norm:focus {
  border: 1px solid #000;
}

.inputField-input-error {
  font-family: var(--def-font);
  font-size: var(--font-size);
  border: 1px solid red;
  padding: 1vh;
  border-radius: 0.5vh;
}

.inputField-error-message {
  position: absolute;
  bottom: 5vh;
  left: 0;
  font-family: var(--def-font);
  font-size: 1.5vh;
  color: red;
}

.input-disabled {
  background-color: #eeeeee;
}

.input-date-container {
  position: relative;
  border: 0;
}

.input-date-container .date-info-overlay {
  position: absolute;
  top: 1vh;
  left: 0.5vw;
  font-size: calc(var(--font-size) * 1.1);
  z-index: 5;
  cursor: pointer;
}
.input-date-container .date-overlay {
  position: absolute;
  width: 80%;
  height: 90%;
  top: 2px;
  left: 2px;
  z-index: 3;
  user-select: none;
  background-color: white;
}
