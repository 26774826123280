:root {
  --def-font: 'Poppins', sans-serif;
}

.lp-main-container {
  height: 100vh;
  width: 100vw;
  background-color: white;
}

.lp-scroll-area {
  overflow-y: scroll;
  overflow-x: hidden;
}

.lp-s2 {
  background-color: white;
  height: fit-content;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10vh;
}

.lp-s2-title {
  font-family: var(--def-font);
  color: #1f88db;
  font-size: 3vh;
  font-weight: bold;
}

.lp-s2-div1 {
  background-color: #f37c21;
  border-radius: 1vh;
  height: 0.5vh;
  width: 5vw;
}

.lp-s2-sub-title {
  text-align: center;
  color: #252731;
  font-size: 4.5vh;
  font-family: var(--def-font);
  font-weight: bold;
  margin-bottom: 10vh;
}

.lp-s2-card-arrange {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 2vw;
}

@media (min-width: 600px) {
  .lp-s2-card-arrange {
    display: flex;
    flex-direction: row;
  }
}

.lp-s2-card-back-1 {
  background-color: white;
  border-radius: 2.5vh;
  z-index: 1;
  box-shadow: -2px 0px 4vh #00000022;
  margin: 2vh -2vh 2vh 0;
}

@media (min-width: 600px) {
  .lp-s2-card-back-1 {
    height: 40vh;
    width: 20vw;
  }
}

.lp-s2-card-back-2 {
  background-color: white;
  border-radius: 2.5vh;
  z-index: 2;
  box-shadow: -2vh 0vh 4vh #00000022;
  margin: 2vh -2vh 2vh 0;
}

@media (min-width: 600px) {
  .lp-s2-card-back-2 {
    height: 40vh;
    width: 20vw;
  }
}

.lp-s2-card-back-3 {
  background-color: white;
  border-radius: 2.5vh;
  z-index: 3;
  box-shadow: -2vh 0vh 4vh #00000022;
  margin: 2vh -2vh 2vh 0;
}

@media (min-width: 600px) {
  .lp-s2-card-back-3 {
    height: 40vh;
    width: 20vw;
  }
}

.lp-s2-card-back-4 {
  background-color: white;
  border-radius: 2.5vh;
  z-index: 4;
  box-shadow: -2vh 0vh 4vh #00000022;
  margin: 2vh -2vh 2vh 0;
}

@media (min-width: 600px) {
  .lp-s2-card-back-4 {
    height: 40vh;
    width: 20vw;
  }
}

.demoVideoContainer {
  width: 90vw;
  height: fit-content;
  border: 4px solid transparent;
  background-color: #1c76b340;
  border-radius: 15px;
  margin: 8vh auto auto auto;
}

@media (min-width: 600px) {
  .demoVideoContainer {
    width: 70vw;
    border: 20px solid transparent;
  }
}

.video {
  border-radius: 2px;
}

@media (min-width: 600px) {
  .video {
    border-radius: 15px;
  }
}

.align-vertical {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.lp-s2-card-icon {
  height: 10vh;
  width: 10vh;
  margin: 2vh 4vh;
}

.lp-s2-card-title {
  font-family: var(--def-font);
  text-align: left;
  margin: 0vh 4vh;
  font-size: 2.5vh;
  font-weight: 600;
  color: #252731;
}

.lp-s2-card-description {
  font-family: var(--def-font);
  text-align: left;
  margin: 2vh 4vh;
  font-size: 2vh;
  color: #252731;
}

.lp-s3 {
  height: 70vh;
  width: 80vw;
  margin: 5vh auto;
  background-color: #f0624826;
  border-radius: 0 0 60px 60px;
  overflow: hidden;
  display: none;
}

@media (min-width: 600px) {
  .lp-s3 {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
  }
}

.lp-s3-s1 {
  height: 100%;
  width: 100%;
  background-color: #0078d7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.lp-s3-s1-cc {
  background-color: white;
  height: 35vh;
  width: 35vh;
  border-radius: 20vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lp-s3-s1-logo {
  height: 10vh;
}

.lp-s3-s1-tr {
  position: absolute;
  top: 5vh;
  right: 5vh;
}

.lp-s3-s1-bl {
  position: absolute;
  bottom: 5vh;
  left: 5vh;
  transform: rotate(180deg);
}

.lp-s3-s1-ci {
  top: 5vh;
  right: -5vh;
  position: absolute;
}
.lp-s3-s1-si {
  height: 15vh;
  bottom: 5vh;
  right: -5vh;
  position: absolute;
}

.lp-s3-s2-t1 {
  font-family: var(--def-font);
  font-weight: 700;
  font-size: 3vh;
  color: #0078d7;
}

.lp-s3-s2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 8vh;
  position: relative;
}

.lp-s3-s2-div {
  background-color: #f37c21;
  height: 1vh;
  width: 10vh;
  border-radius: 1vh;
  margin-top: -1vh;
}

.lp-s3-s2-t2 {
  font-family: var(--def-font);
  font-size: 2.5vh;
}

.lp-s3-s2-t3 {
  font-family: var(--def-font);
  text-align: left;
  font-size: 2vh;
}

.lp-s3-s2-t4 {
  list-style-image: url('../../assets/img/about-icon-1.svg');
  text-align: left;
  font-family: var(--def-font);
  color: #252731;
  font-size: 2vh;
  margin: 2vh 0 0 2vh;
}

.lp-s3-s2-t5 {
  list-style-image: url('../../assets/img/about-icon-2.svg');
  text-align: left;
  font-family: var(--def-font);
  color: #252731;
  font-size: 2vh;
  margin: 2vh 0 0 2vh;
}

.lp-s3-s2-t6 {
  color: #f37c21;
  text-decoration: underline;
  position: absolute;
  bottom: 4vh;
  right: 8vh;
  cursor: pointer;
}

.lp-s4 {
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lp-s4-t1 {
  font-size: 2.5vh;
  font-weight: 700;
  font-family: var(--def-font);
  color: #0078d7;
}

.lp-s4-div {
  height: 1vh;
  width: 10vh;
  border-radius: 1vh;
  background-color: #f37c21;
}

.lp-s4-t2 {
  font-family: var(--def-font);
  font-size: 4vh;
  font-weight: 700;
  margin-top: 2vh;
}

.lp-s4-cont-selected {
  background-color: #f37c21;
  color: white;
  height: 8vh;
  width: fit-content;
  border-radius: 1vh;
  font-size: 2vh;
  font-weight: 700;
  text-align: left;
  padding: 0 2vh 0 2vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: var(--def-font);
  margin: 1vh 3vh 1vh 1vh;
  white-space: nowrap;
}

@media (min-width: 600px) {
  .lp-s4-cont-selected {
    width: 25vw;
    border-radius: 1.5vh;
    padding-left: 0 5vh 0 5vh;
    margin: 1vh 10vh 1vh 1vh;
    white-space: normal;
  }
}

.lp-s4-cont-nselec {
  background-color: #f9f9fc;
  color: black;
  height: 8vh;
  width: fit-content;
  border-radius: 1vh;
  font-size: 2vh;
  font-weight: 700;
  text-align: left;
  padding: 0 2vh 0 2vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1vh 3vh 1vh 1vh;
  font-family: var(--def-font);
  white-space: nowrap;
}

@media (min-width: 600px) {
  .lp-s4-cont-nselec {
    width: 25vw;
    border-radius: 1.5vh;
    padding-left: 0 5vh 0 5vh;
    margin: 1vh 10vh 1vh 1vh;
    cursor: pointer;
  }
}

.lp-s4-cont-s1 {
  display: flex;
  flex-direction: row;
  justify-content: start;
  overflow: auto;
}

@media (min-width: 600px) {
  .lp-s4-cont-s1 {
    flex-direction: column;
    justify-content: center;
  }
}

.lp-s4-cont {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

@media (min-width: 600px) {
  .lp-s4-cont {
    flex-direction: row;
    width: auto;
  }
}

.preview-image {
  width: 95vw;
}

@media (min-width: 600px) {
  .preview-image {
    height: 60vh;
    width: 50vw;
  }
}

.lp-s5-img {
  width: 150vw;
}

.lp-s6 {
  height: 90vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.video-source {
  width: 90vw;
  height: 50.625vw;
  background-color: #0078d7;
  border-radius: 30px;
  z-index: 2;
}

@media (min-width: 600px) {
  .video-source {
    height: 33.75vw;
    width: 60vw;
  }
}

.play-icon {
  height: 10vw;
  width: 10vw;
}

@media (min-width: 600px) {
  .play-icon {
    height: 6vw;
    width: 6vw;
  }
}

.lp-s6-p1 {
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 10vw;
}

.lp-s7 {
  height: fit-content;
  background-color: #252731;
  padding: 7vh 9vw 4vh 9vw;
}

@media (min-width: 600px) {
  .lp-s7 {
    height: 60vh;
    display: grid;
    grid-template-columns: 1.5fr 2fr 1fr;
    grid-template-rows: 20vh;
    column-gap: 5vw;
    row-gap: 6vh;
    padding: 11vh 9vw 7vh 9vw;
  }
}

.lp-s7-t1 {
  color: white;
  font-family: var(--def-font);
  text-align: left;
  font-size: 2vh;
}

.lp-s7-t2 {
  font-family: var(--def-font);
  color: white;
  font-size: 2vh;
}

.lp-s7-t3 {
  font-family: var(--def-font);
  color: white;
  text-align: left;
  font-size: 2vh;
}

@media (min-width: 600px) {
  .lp-s7-t3 {
    font-size: 1.8vh;
  }
}

.lp-s7-t2-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2vh;
}

@media (min-width: 600px) {
  .lp-s7-t2-cont {
    margin-top: 0;
  }
}

.contact-icon {
  height: 2.5vh;
  width: 2.5vh;
}

.lp-s7-t2-contact {
  padding: 1vh;
  background-color: #1f88db;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin: 0.5vh;
  min-width: 10vw;
  border-radius: 0.5vh;
}

.contact-content {
  color: white;
  font-family: var(--def-font);
  cursor: pointer;
  margin: 0 0 0 1vh;
}

.social-handles {
  text-align: left;
  display: flex;
  width: 50vw;
  justify-content: space-between;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

@media (min-width: 600px) {
  .social-handles {
    width: 15vw;
  }
}

.contact-icons {
  height: 4rem;
  width: 3rem;
}

.book-demo {
  padding: 1vh;
  font-size: 2vh;
  text-align: center;
  font-family: var(--def-font);
  color: #f37c21;
  border: 2px solid #f37c21;
  border-radius: 4vh;
  margin-bottom: 1vh;
  margin-top: 2vh;
}

@media (min-width: 600px) {
  .book-demo {
    width: 15vw;
    margin-top: 0;
    cursor: pointer;
  }
}

.lp-s7-t6 {
  display: flex;
  flex-direction: column;
}

.lp-s7-t123 {
  display: flex;
  flex-direction: column;
}

.lp-s8 {
  background-color: #252731;
  color: white;
  font-size: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2vh;
  height: 10vh;
  width: 100vw;
  font-family: var(--def-font);
}
