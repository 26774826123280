@import '../../../assets/scss/variable.scss';
@import '../../../assets/scss/mixins.scss';
.multiDropdown2 {
  .dropdown-search {
    width: 100%;
    display: flex;
    height: fit-content;
    align-items: center;
    box-shadow: 0px 0px 3px #00000029;
    border-radius: 4px;
    padding: 0 6px;
    margin-bottom: 10px;
    input {
      font-family: $roboto;
      font-size: 1.9vh;
      line-height: 1.9vh;
      border: none;
      flex-grow: 1;
      height: 1.7rem;
    }
    input:focus {
      border: solid orange 2px !important;
      outline: none;
      box-shadow: none !important;
    }
    input::placeholder {
      font: normal normal normal 12.5px/10px Segoe UI;
      letter-spacing: 0px;
      color: #bbbed6;
    }
    .search-icon {
      width: 12.5px;
      height: 12.5px;
      filter: invert(86%) sepia(11%) saturate(429%) hue-rotate(196deg)
        brightness(90%) contrast(85%);
      margin-right: 8px;
    }
  }

  input.finalValueShow {
    box-shadow: 0px 0px 3px #00000029;
    border: 0.6000000238418579px solid #bab9b9;
    border-radius: 4px !important;
    background: $white;
    font-family: $roboto;
    font-size: 2.9vh;
    line-height: 1.9vh;
    padding: 6px 15px;
    cursor: pointer;
    border-radius: 10px;
    width: 299px;
    margin: 0 14px;
    @include breakpoint(lgdesktop) {
      width: 270px;
    }
    font-weight: 400;
  }
  input.finalValueShow:focus {
    box-shadow: 0px 0px 3px #00000029 !important;
    border: 0.6000000238418579px solid #bab9b9 !important;
    border-radius: 4px !important;
  }
  input.finalValueShow::placeholder {
    color: #aeafb9;
  }
  .formWrapper {
    box-shadow: 0px 3px 6px #00000029;
    background: $white;
    border-radius: 10px;
    padding: 12px 30px;
    margin-top: -42px;
    margin-left: 15px;
    label {
      font-family: $roboto;
      font-size: 1.9vh !important;
      color: $black;
      line-height: 3.6vh !important;
      background: $white;
      text-align: left;
      margin-bottom: 5px;
      margin-top: 15px;
      font-weight: 500;
      width: 100% !important;
      text-transform: capitalize;
      &:first-child {
        margin-bottom: 15px;
      }
    }

    .form-label {
      font-size: 2.4vh !important;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: bold;
      display: block;
    }

    select {
      background: $white;
      box-shadow: $dark-btm-shadow;
      font-family: $roboto;
      font-size: 1.9vh;
      color: $black;
      line-height: 1.9vh;
      border: 1px solid #c3c3c3;
      border-radius: 10px;
      position: relative;
      height: 36px !important;
      width: 100% !important;
      margin-bottom: 10px;
      &:after {
        content: ' ';
        background: url('../../../assets/img/down-arrow-icon-small.png') 0 0
          no-repeat;
        position: absolute;
        background-position: 0 0;
        height: 12px;
        width: 15px;
        right: 15px;
      }
    }
  }
  select {
    background: $white;
    box-shadow: $dark-btm-shadow;
    font-family: $roboto;
    font-size: 1.9vh;
    color: $black;
    line-height: 1.9vh;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    position: relative;
    height: 36px !important;
    width: 100% !important;
    margin-bottom: 10px;
    &:after {
      content: ' ';
      background: url('../../../assets/img/down-arrow-icon-small.png') 0 0
        no-repeat;
      position: absolute;
      background-position: 0 0;
      height: 12px;
      width: 15px;
      right: 15px;
    }
  }
  .inactive {
    visibility: hidden;
  }
}
select:focus {
  border: solid orange 2px !important;
  outline: none;
  box-shadow: none !important;
}
.mainWrapperDropdown2 {
  position: absolute;
  background: $white;
  box-shadow: 0px 0px 3px #00000029;
  border: 0.6000000238418579px solid #bab9b9;
  border-radius: 4px;
  padding: 6px;
  z-index: 9;
  width: 299px;
  margin-left: 14px !important;
  margin-top: 10px;
  @include breakpoint(lgdesktop) {
    width: 270px;
  }
  top: -10px;
  height: fit-content;

  .tabs {
    display: flex;
    margin-bottom: 0.7rem;
    li {
      width: fit-content;
      display: flex;
      align-items: center;
      flex-grow: 1;
      cursor: pointer;
      color: #bbbed6;
      span {
        text-align: left;
        font: normal normal normal 13px/10px Segoe UI;
        letter-spacing: 0px;
        padding: 5px 7px;
        line-height: 1.2.4vh;
      }
    }
    li.selected-item {
      span {
        background: #e5f1ff 0% 0% no-repeat padding-box;
        border-radius: 16px;
        color: #0269ba;
      }
    }
  }

  .tab-options {
    margin: 0 -6px 0 -6px;
    overflow-y: auto;
    max-height: 200px;
    li {
      text-align: left;
      padding: 0.75rem 0 0.75rem 0.8rem;
      font: normal normal normal 12.5px/10px Roboto;
      letter-spacing: 0px;
      font-weight: 500;
      cursor: pointer;
    }
    li:hover {
      background-color: #f8f8f8;
    }
  }
}

.selectedAnswer {
  position: relative;
  min-width: 20px;
  font-size: 2.1vh;
  padding: 0 5px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 0px 0px 3px #00000029;
  border: 0.6000000238418579px solid #2e354b;
  border-radius: 4px;
  min-height: 37px;
  display: flex;
  align-items: center;
  p {
    margin: 0 !important;
  }
}

.selectedAnswer:hover {
  background-color: #ebf6ff;
  border-radius: 4px;
}

.selectedAnswer:hover .answerBtn {
  display: unset;
}

.answerBtn {
  top: -10px;
  right: -7px;
  width: 18px;
  border: 0;
  cursor: pointer;
  height: 18px;
  display: none;
  z-index: 99;
  position: absolute;
  background: #ff001f;
  border-radius: 50%;
}

.answerSvg {
  color: #fff;
  padding-top: 0px;
  fill: currentColor;
  width: 2.4vh;
  height: 2.4vh;
  display: inline-block;
  font-size: 15px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
  margin-top: 1.4px;
  margin-left: 0.8px;
}

svg {
  vertical-align: top !important;
}

.min-max {
  display: flex;
  input {
    width: 50% !important;
    margin: 0 5px;
  }
}
.buttons-wrapper {
  display: flex;
  margin-top: 15px;
  .cancel-btn {
    background-color: white;
    color: #0078d7;
  }
  .cancel-btn:hover {
    background-color: white;
    color: #0078d7;
  }
  button {
    width: 50%;
    margin: 5px;
  }
}

// as a modal
.formWrapper {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99;
  width: 234px;
}

input::placeholder {
  font-family: $roboto;
  font-weight: 400;
  font-size: 1.9vh;
}

.operatorDropdown {
  background: #e1e6ea;
  padding: 4px 13px;
  height: auto;
  max-height: 190px;
  overflow-y: auto;
  li {
    cursor: pointer;
    border-radius: 5px;
    background: #ffffff;
    color: #000000;
    font-family: $roboto;
    font-size: 2.4vh;
    line-height: 2.4vh;
    padding: 14px 17px;
    text-align: center;
    margin: 2px 0;
  }
}
