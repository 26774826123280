.nestedDropdown {
  margin: 0.5vw 1vw;
  /* overflow: auto; */
  height: 100%;
}

.dropdown {
  /* overflow-y: auto; */
  /* max-height: 600px; */
  height: 100%;
}

.menu-items {
  margin: 0.3vw 0;
  margin-left: 0.5vw;
}

.item-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.8vh;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  padding: 0 0.1vw 0.3vw 0.3vw;
  padding-bottom: 0;
}

.item-data-value {
  text-align: center;
  padding: 0.1vw 0;
}

.item-data:hover {
  background-color: #f4f4f4;
}

.dropdown-linker-icon {
  height: 2.1vh;
}

.icons {
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
}

.icon-container {
  display: flex;
  align-items: center;
  margin: 0 0.5vw;
}

.icon {
  height: 1.8vh;
  width: 2.3vh;
  cursor: pointer;
}

.off-icon {
  display: none;
}
.item-data:hover .off-icon {
  display: block;
}

.status {
  height: 1.8vh;
  width: 1.8vh;
  border-radius: 50%;
}

#ops-dropdown-tooltip > .tooltip-inner {
  background-color: #ffffff;
  color: black;
  width: 100%;
  font: normal normal 1.8vh Roboto;
  letter-spacing: 0px;
  border: 1px solid black;
  height: 4vh;
}
