@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.login-main-wrapper {
  .form-wraper {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    background-color: #3382b702;
    @include breakpoint(lgdesktop) {
      width: 40%;
      padding: 0 0px;
    }
    .center-contanier {
      max-width: 465px;
      width: 100%;
      @include breakpoint(lgdesktop) {
        max-width: 390px;
      }
      form {
        margin-top: 100px;
        @include breakpoint(lgdesktop) {
          height: auto;
          width: 300px;
          margin: 30px auto 0 auto;
        }

        h1 {
          text-align: left;
          font-size: 44px;
          line-height: 58px;
          color: #34395f;
          font-family: 'Roboto', sans-serif;
          font-weight: 500;
          margin-bottom: -10px;
          @include breakpoint(lgdesktop) {
            margin-bottom: -5px;
            font-size: $thirty;
            line-height: 30px;
          }
        }
        .form-group {
          text-align: left;
          margin-bottom: 30px;
          @include breakpoint(lgdesktop) {
            margin-bottom: 15px;
          }
          label {
            font-size: 1em;
            line-height: 29px;
            color: #34395f;
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            margin-bottom: 10px;
          }
          input::-ms-reveal,
          input::-ms-clear {
            display: none;
          }
          input {
            border-radius: 5px;
            border: 1px solid grey;
            font-size: 24px;
            line-height: 32px;
            color: $darkblue;
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            &:focus {
              border: 0.5px solid $blue;
              box-shadow: 0px 0px 6px #0000001a;
            }

            @include breakpoint(lgdesktop) {
              font-size: $twelve;
            }
          }
          small {
            text-align: right;
            font-size: 1em;
            line-height: 24px;
            color: #0078d7;
            font-family: 'Roboto', sans-serif;
            margin-bottom: 10px;
            cursor: pointer;
          }
          .resend-disabled #resendBtn {
            cursor: default;
            color: #9d9d9d88;
          }
        }
        .form-error {
          label {
            color: #e44125 !important;
          }
          input {
            border-color: #e44125 !important;
          }
          color: #e44125 !important;
        }
        button {
          font-size: 26px;
          line-height: 34px;
          font-family: 'Roboto', sans-serif;
          font-weight: 500;
          display: block;
          height: 70px;
          border-radius: 10px;
          width: 100%;
          background: #f37c20;
          border: none;
          @include breakpoint(lgdesktop) {
            font-size: $fourteen;
            height: 45px;
          }
        }
      }
    }
  }
  .text-center img {
    width: 160px;
    height: 80px;
    @include breakpoint(lgdesktop) {
      width: 115px;
      height: 60px;
    }
  }

  .login-img-wraper {
    width: 67%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3382b7;
  }
  .line_wrapper {
    text-align: left;
    margin-bottom: 30px;
    @include breakpoint(lgdesktop) {
      margin-bottom: 15px;
    }
    .line-img {
      height: 5px;
      width: 77px;
      @include breakpoint(lgdesktop) {
        height: 5px !important;
        width: 48px !important;
      }
    }
  }
}

.forgot-wrapper {
  p {
    color: $blue;
    font-size: $twentytwo;
    line-height: 29px;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    font-weight: 400;
    @include breakpoint(lgdesktop) {
      font-size: $sixteen;
    }
  }
}

.otp-wrapper {
  p {
    color: $blue;
    font-size: $twentytwo;
    line-height: 29px;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    font-weight: 400;
    @include breakpoint(lgdesktop) {
      font-size: $sixteen;
    }
  }
  small {
    font-size: 1em;
    line-height: 29px;
    color: #34395f;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    margin-bottom: 10px;
  }
}

.otp-buttons {
  margin-left: 242px;
  margin-right: -118px;
  @include breakpoint(lgdesktop) {
    margin-left: 115px;
    margin-right: -88px;
  }
}
.colormodal-global .popup.success {
  @include breakpoint(lgdesktop) {
    min-width: 300px !important; // SHUBHAM_POPUP_WIDTH
    max-width: 600px !important;
    // height: 113px !important;
  }
}
