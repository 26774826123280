@import '../../../assets/scss/mixins.scss';
@import '../../../assets/scss/variable.scss';

.trading-dashboard-menu {
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  padding-top: 4px;
  border-radius: 8px 8px 0px 0px;
  list-style: none;
  display: flex;
  margin-bottom: 0;
  font: normal normal bold 25px/33px Roboto;
  line-height: unset !important;
  color: #97999f !important;
  align-items: center;
  border-bottom: 1px solid #cecece;
  @include breakpoint(xlgdesktop) {
    font: normal normal bold 16px/20px Roboto;
    display: flex;
    align-items: center;
  }
  a {
    padding: 0 30px 0 30px;
    cursor: pointer;
    color: #97999f !important;
    @include breakpoint(lgdesktop) {
      padding: 10px 22px;
      font-size: 12px;
    }
    &.hover {
      background: #1c689c !important;
      color: $white !important ;
    }
    &.active {
      // background: #1c689c !important;
      color: #000000;
    }
  }
  .main_search {
    position: absolute;
    right: 40px;
  }
  .search_bar {
    width: 523px;
    background: #ffffff;
    border-radius: 24px;
    color: #000;
    color: #413f46;
    position: relative;
    .dropdown-menu {
      box-shadow: 0px 3px 6px #63a8ed29;
      border: 1px solid #63a8ed;
      border-radius: 0px 20px 20px 20px;
      overflow: hidden;
      padding: 0;
      left: 20px !important;
      a {
        color: #000 !important;
        font: normal normal normal 20px/20px Roboto;
        padding: 10px;
        &:active {
          background: #ebebff !important;
        }
        @include breakpoint(lgdesktop) {
          font-size: 14px;
        }
      }
    }
    .box {
      display: flex;
      align-items: center;
      height: 100%;
      .dropdown {
        &.show {
          .search::after {
            transform: rotate(180deg);
          }
        }
      }
      .search {
        text-align: left;
        width: 200px;
        background: #ebebff;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font: normal normal normal 16px/24px Roboto;
        color: black;
        border-radius: 24px 0 0 24px;
        border: none;
        @include breakpoint(lgdesktop) {
          height: 40px;
        }
        &:active {
          background: #ebebff;
          border: none;
          color: #000;
        }
        &::after {
          content: '';
          width: 0px;
          height: 0px;
          display: inline;
          margin-left: 15px;
          border-top: 6px solid #000000;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          cursor: pointer;
        }
      }
      input {
        width: 49%;
        margin-left: 10px;
        border: none;
        background: transparent !important;
        padding-right: 10px;
      }
      .icon {
        position: absolute;
        right: 30px;
        img {
          width: 20px;
        }
      }
    }
    .search {
      color: black;
    }
  }
  @media (max-width: 1400px) {
    .search_bar {
      width: 425px;
      .box {
        .search {
          width: 170px;
          color: black;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .search_bar {
      width: 200px;
      height: 27px;
      .box {
        .search {
          width: 70px;
          font-size: 10px;
          color: black;
          &::after {
            margin-left: 2px;
            border-top: 3px solid #000000;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            color: black;
          }
        }
        .icon {
          right: 8px;
          top: 3px;
          img {
            width: 10px;
          }
        }
      }
    }
  }
}
.trading-dashboard-menu#scrollheder {
  border: none;
}
.newWindow {
  cursor: pointer;
  img {
    height: 2.6vh;
    width: 2.6vh;
  }
}

.trading-dashboard-menu a:first-child {
  border-radius: 8px 0px 0px 0px;
}

.trading-dashboard-menu .search_bar a.active {
  background: #ebebff !important;
}

.trading-window {
  .trading-dashboard-menu {
    border-radius: 0px !important;
  }
}

.TradingWindowHeader-header {
  height: 4rem;
}
.TradingWindowHeader-body {
  overflow-y: auto;
}
