@import '../../../assets/scss/variable.scss';
@import url('../../../global.css');
@import '../../../assets/scss/mixins.scss';
.market-watch {
  background: #fff;
  box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  // overflow: hidden;
  .spinner-border {
    position: absolute;
    top: 12em;
    @include breakpoint(lgdesktop) {
      top: 9em;
    }
  }

  .liststyle {
    position: relative;
    height: 337px;
    width: 350px;
    overflow: auto;
    will-change: transform;
    direction: ltr;
  }
  .listRow {
    left: 0px;
    top: 0px;
    height: 42px;
    width: 100%;
  }
  // market-watch-Header section
  .market-watch-header {
    display: grid;
    grid-template-columns: 3.5fr 0.5fr;
    align-items: center;
    background: #ffffff 0 0 no-repeat padding-box;
    border-bottom: 1px solid #707070;
    padding: 3px 0px 3px 0px;
    width: 100%;
    .market-input {
      position: relative;
      .security-count {
        position: absolute;
        right: 5%;
        top: 4px;
        font-size: 0.82rem;
        color: dimgray;
        z-index: 9;
      }
      .input-group {
        position: relative;
        width: 95%;
        margin: auto;
        border-radius: 5px;
        // border: 0.3px solid #97999f;
        @include breakpoint(desktop) {
          width: 100%;
        }
        &::before {
          content: '';
          background: url(../../../assets/img/icon-search.png);
          width: 13px;
          height: 13px;
          background-repeat: no-repeat;
          background-size: 13px;
          position: absolute;
          left: 8px;
          z-index: 900;
          top: 8px;
          opacity: 0.7;
        }
        .search-box {
          padding-left: 30px;
          font-size: 14px;
          padding-right: 45px;
          @include breakpoint(desktop) {
            font-size: 13px;
          }
          &::-webkit-input-placeholder {
            font-size: 13px;
            @include breakpoint(desktop) {
              font-size: 12px;
            }
          }
        }
      }
    }
    .selector-wrapper {
      h3 {
        font: normal normal bold 18px/24px Segoe UI;
        color: #171725;
        line-height: 40px;
        font-size: 1.2em;
        margin-bottom: 0;
        text-align: left;
      }
      .MuiAutocomplete-root {
        input.MuiAutocomplete-input {
          width: 100% !important;
          height: 38px;
          border-radius: 25px;
          border-bottom: none;
          padding: 23px 14px;
          font-family: $roboto;
          color: #707070;
          font-size: 1em;
          @include breakpoint(lgdesktop) {
            font-size: 12px;
            padding: 18px 14px;
          }
        }
      }
    }
    // Update name wrapper header
    .updateName {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center;

      .input-btn {
        color: $white;
        font-size: 1.2em;
        font-family: $roboto;
        border: 1px solid #c3c3c3;
        border-radius: 10px;
        // box-shadow: 0 3px 6px rgb(0, 0, 0 / 5%);
        margin-bottom: 0;
        padding: 9px 28px;
        margin-right: 8px;
        max-width: 250px;
        margin: 0px 0 0px 40px;
        background: #1c1c2b !important;
        @include breakpoint(lgdesktop) {
          font-size: 12px;
          margin-right: 10px;
        }
      }
      .update-name {
        font-family: $roboto;
        font-size: 0.8em;
        color: $white;
        padding: 5px 25px;
        font-weight: 600;
        text-transform: uppercase;
        width: 170px;
        margin-bottom: 0px;
        @include breakpoint(lgdesktop) {
          font-size: 10px;
        }
      }
    }
    // Nifty wrapper header
    .nifty-liveInHeader {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: flex-end;
      position: relative;
      padding-right: 20px;
      margin-left: 1vw;
      @include breakpoint(desktop) {
        padding-right: 10px;
      }
      .nifties {
        display: flex;
        margin: 0 5px 0 0;
        @include breakpoint(desktop) {
          margin: 0 8px 0 0;
        }
        width: 13vw;
        &--small {
          width: 11vw;
        }
        @include breakpoint(smdesktop) {
          width: 14vw;
          &--small {
            width: 11vw; // New width for small items when screen is small
          }
        }
        p {
          font: normal normal bold 1.5vh/1.25rem var(--def-font);
          color: #171725;
          margin-bottom: 0;
          @include breakpoint(lgdesktop) {
            font-size: 11px;
          }
          margin-right: 5px;
        }
        h3 {
          font: normal normal bold 1.5vh/1.25rem var(--def-font);
          margin-bottom: 0;
          &.green {
            color: #53bf3a;
            @include breakpoint(lgdesktop) {
              font-size: 11px;
            }
          }
          &.red {
            color: #e44125;
            @include breakpoint(lgdesktop) {
              font-size: 11px;
            }
          }
        }
      }
    }

    .market-watch-header-start {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 1vw;
    }

    .market-watch-header-middle {
      padding: 2px;
      //flex: 1 1 10;
      padding-left: 20px;
    }

    .market-watch-header-end {
      display: flex;
      align-items: center;
      margin-right: 3vw;
      justify-content: flex-end;

      .newWindowButton {
        margin-left: 2vw;
        .newWindowIcon {
          height: 2.5vh;
          width: 2.5vh;
        }
      }

      .newWindowButton:focus {
        border: solid orange 2px;
      }
    }
  }
  .market-watch-table {
    min-height: 345px;
    width: 100px;
    overflow-y: auto;
    overflow-x: auto;
    background-color: #25282e;
    margin: 0 15px;
    padding: 0px 0px;
  }
  .rowBodyClass {
    cursor: pointer;
    &:focus {
      background: #fff !important;
    }
    td {
      &:nth-child(1) {
        p {
          color: #efd700 !important;
        }
      }
      &:nth-child(2) {
        p {
          color: #efd700 !important;
        }
      }
    }
  }
  .right-modal {
    position: absolute;
    background: #fff;
    color: black;
    padding: 5px;
    width: 250px;
    text-align: left;
    z-index: 99;
    border-radius: 5px;
    p {
      margin-bottom: 0px;
      font-weight: 600;
    }
    ul {
      background: #007bff40;
      margin-bottom: 0px;
      li {
        padding-left: 5px;
        border-bottom: 2px solid #fff;
        cursor: pointer;
      }
    }
  }
  // market-watch-table live data
  .live-trading-data {
    padding: 0 0 15px 20px;
    table {
      background: #25282e !important;
      thead {
        tr {
          background: #25282e !important;
          th {
            color: #929292;
            font-family: $roboto;
            font-size: 1em;
            font-weight: 500;
            padding: 10px 3px;
            min-width: 0% !important;
            @include breakpoint(lgdesktop) {
              font-size: 12px;
              color: #929292;
            }
            .MuiSvgIcon-root {
              width: 0.6em;
              height: 0.6em;
              opacity: 0.6;
              border-radius: 8px;
              margin-top: 3px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            border: 0px solid #cecece !important;
            //   border-top: none;
            //   background: #25282e;
            //   border-bottom: none;
            //   color: #ffffff;
            //   padding-left: 5px;
            //   padding-right: 5px;
            //   p {
            //     margin-bottom: 0;
            //     font-size: 16px;
            //     @include breakpoint(lgdesktop) {
            //       font-size: 12px;
            //     }
            //   }
            //   .inLoad {
            //     display: none;
            //   }
          }
        }
      }
    }
  }
  .List {
    border: 1px solid #d9dddd;
    position: absolute !important;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0px 2px 5px #000;
    border-radius: 6px;
    width: 100%;
  }
  .ListItem {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid #dfe6e9;
    align-items: center;
    padding: 8px;
    padding-right: 5px;
    padding-left: 10px;
    span {
      color: black;
      font-size: 13px;
      position: relative;
    }
    .security_name {
      &:before {
        position: absolute;
        content: ' ';
        right: -17px;
        top: 5px;
        height: 13px;
        width: 13px;
        background-size: 13px !important;
        background: url(../../../assets/img/icons8-checked.svg) no-repeat;
        @include breakpoint(lgdesktop) {
          background-size: 12px !important;
        }
      }
    }
    .addSecurity {
      background: #27ae60;
      border-color: #27ae60;
      height: 28px;
      width: 35px;
      display: flex;
      justify-content: center;
      font-size: 18px;
      line-height: 1.4;
      padding: 0;
    }
    .addSecurity:hover {
      opacity: 0.9;
    }
    .removeSecurity {
      background: #e74c3c;
      border-color: #e74c3c;
      height: 28px;
      width: 35px;
      display: flex;
      justify-content: center;
      font-size: 18px;
      line-height: 1.4;
      padding: 0;
    }
    .removeSecurity:hover {
      opacity: 0.9;
    }
  }
  .inactive {
    opacity: 0;
    visibility: hidden;
    p {
      color: aliceblue;
    }
  }
  .active {
    opacity: 1;
    visibility: visible;
    p {
      color: aliceblue;
    }
  }
  .List::-webkit-scrollbar {
    width: 7px;
  }
  .List::-webkit-scrollbar-track {
    background: #fff;
  }
  .List::-webkit-scrollbar-thumb {
    background-color: gray;

    border-radius: 20px;
  }
}

.trading-container {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 1vh 0 1vh 0;
  border-radius: 0.5vh;
  border: 1px solid #cecece;
  overflow: auto;
}

.dropBox {
  .active {
    width: 95%;
    position: relative;
    padding-top: 2px;
    margin: auto;
    @include breakpoint(desktop) {
      width: 100%;
    }
  }
}

.input-group > .form-control {
  font-size: 1em;
  border-radius: 20px;
  height: 38px;
}

.input-group > .form-control:focus {
  box-shadow: 0 0 0 0.2rem #f37c21;
}

.market-watch-wrapper {
  top: 0;
  position: relative;
  padding: 0vh 0 3vh 1vw;
  @include breakpoint(xlgdesktop) {
    top: 0px;
  }
}

.actionBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    color: #fff;
    background: #53bf3a;
    font-size: 18px;
    line-height: 12px;
    margin: 2px;
    border: 0;
    padding: 0;
    padding-bottom: 3px;
  }
  button:hover {
    background: #53bf3a;
    opacity: 0.9;
  }
  button.removeAction {
    background: #e44125;
  }
  button.removeAction:hover {
    background: #e44125;
    opacity: 0.9;
  }
}

.windowLiveTrading {
  background: #1a1a1a;
  min-height: 100vh;
  .market-watch-wrapper {
    top: 0px;
    padding: 0 0;
    .market-watch {
      margin-top: 0px;
      border-radius: 0;
      box-shadow: none;
      .market-watch-table {
        min-height: 90vh;
        .over-table {
          min-height: 75vh !important;
        }
      }
    }
  }
}

.market-watch-title {
  font-family: var(--def-font);
  font-size: 1.5vh;
  width: 15vw;
  font-weight: 700;
}

.trading-header {
  height: 10%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.trading-body {
  height: 90%;
  overflow-y: auto;
}
