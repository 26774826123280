/*Single Item CSS*/
@import url('../../../../global.css');
.simple-dropdown .list-item {
	display: flex;
	width: 98%;
	margin: auto;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0.5vh 0.5vw;
}

.simple-dropdown .list-item-func {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 7vw;
	justify-content: space-evenly;
}

.simple-dropdown .child-item {
	padding-left: 1.3vw !important;
	border-radius: 5px;
	cursor: pointer;
}

.simple-dropdown .active-node {
	background-color: #f4f4f4;
}

.simple-dropdown .main-item {
	font-weight: 500;
	padding-top: 0.8vh;
	padding-bottom: 0.8vh;
	border-radius: 5px;
}

.simple-dropdown .main-item:hover {
	cursor: pointer;
}

.simple-dropdown .child-item:hover {
	background-color: #f4f4f4;
}

.simple-dropdown .drop-focus {
	background-color: #f4f4f4;
}

/*Generate Item CSS*/

/*Dropdown icon*/
.simple-dropdown .dropdown-icon-container {
	height: 100%;
	width: 4vh;
	/* background-color: beige; */
}
.simple-dropdown .dropdown-icon-container .icon {
	height: 90%;
	width: 90%;
	object-fit: cover;
	cursor: pointer;
}

.simple-dropdown .list-item-head {
	font-size: var(--font-size);
	font-family: var(--def-font);
}
/*Status icon*/
.simple-dropdown .status {
	height: 1.2vh;
	width: 1.2vh;
	border-radius: 50%;
}

.simple-dropdown .status:hover {
	cursor: default;
}

.simple-dropdown .back-red {
	background-color: red;
}
.simple-dropdown .back-green {
	background-color: green;
}
.simple-dropdown .back-yellow {
	background-color: var(--focus);
}
.simple-dropdown .back-grey {
	background-color: grey;
}

/* start button*/

.simple-dropdown .start-button {
	padding: 0.25vh 0.25vw !important;
	font-size: 1.5vh;
	background-color: white !important;

	border: 1px solid 5px;
	color: var(--primary-color);
}

.simple-dropdown .start-button:hover {
	background-color: #d5eafa !important;
	color: var(--primary-color) !important;
}

.simple-dropdown .start-button:focus {
	outline: none !important;
	box-shadow: none !important;
	background-color: white;
	border: none;
	border: 1px solid !important;
	/* border: transparent; */
	color: var(--primary-color) !important;
}
.simple-dropdown .load-icon {
	aspect-ratio: 1;
	width: 1.2vw;
}

.simple-dropdown .child-item .hover-show {
	transform: scale(0);
}

.simple-dropdown .child-item:hover .hover-show {
	transform: scale(1);
}

.simple-dropdown #no-functionality {
	outline: none !important;
	color: #add7f9 !important;
	/* border: 1px solid 5px !important; */
	background-color: white !important;
	border: 1px solid !important;
}
