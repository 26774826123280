@import '../../../assets/scss/variable.scss';
@import '../../../assets/scss/mixins.scss';
@import url('../../../global.css');
:root {
  --bc: 1px solid #cecece;
}

.orderDetailTable {
  table {
    tbody {
      tr {
        td {
          &:nth-child(5) {
            @include breakpoint(lgdesktop) {
              width: 25% !important;
            }
          }
        }
      }
    }
  }
  p {
    margin-bottom: 0;
    font-size: 1em;
    font-family: $roboto;
    color: #171725;
    span {
      text-transform: capitalize;
      font-weight: 500;
      &.red {
        color: $red;
      }
      &.green {
        color: $green;
      }
    }
  }
  input {
    // width: 75px !important;
    text-align: center;
    margin-left: 10px;
    @include breakpoint(lgdesktop) {
      // width: 40px !important;
    }
  }
  .alogapplied {
    // box-shadow: $dark-btm-shadow;
    border: solid #bab9b9 1px;
    padding: 0 15px;
    margin-bottom: 15px;
  }
}

.direction.fwd{
	color: green;
	text-align: center;
}

.direction.rev {
	color: red;
	text-align: center;
}

.focus:focus {
  border: 2px orange solid !important;
  // box-shadow: 2px 2px 2px 2px orange !important;
}
// css Modal for new window
.previewModalRootWrpaer {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  #tooltip-top {
    margin-left: 0 !important;
  }
  .templateNameWrapper {
    background: #0078d7;
    padding: 16px 15px;
    // margin-bottom: 30px;
    // border-radius: 0 0 10px 10px;
    border-bottom: solid #cecece 1px;
    .field {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      label {
        color: #171725;
        font-size: 1em;
        line-height: 1em;
        font-family: $roboto;
        width: auto !important;
        font-weight: 400;
        white-space: nowrap;
        margin-right: 15px !important;
        margin-bottom: 0px;
      }
    }
  }
  .middleWrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    .algo-wrapper-strategy {
      .table {
        tr {
          display: flex;
          @include breakpoint(smdesktop) {
            flex-wrap: wrap;
          }
        }
        td {
          display: flex;
          align-items: center;
          width: 20%;
          border: none;

          &:nth-child(1) {
            @media (min-width: 1024px) {
              padding-left: 0;
            }
          }
          @include breakpoint(smdesktop) {
            width: 50%;
          }
          label {
            margin: 0 10px 0 0;
            @include breakpoint(smdesktop) {
              width: 230px;
              text-align: left;
            }
          }
          .MuiFormControl-marginNormal {
            margin: 0;
            .MuiInputBase-input {
              // background: #ffffff;
              // box-shadow: $dark-btm-shadow;
              width: 10vw !important;
              border-radius: 10px;
              display: inline;
              // border: solid #bab9b9 1px;
            }
            button {
              img {
                margin-right: 0 !important;
              }
            }
          }
        }
      }
    }
    .sectionHeadings {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-align-items: center;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 15px;
      h3 {
        margin-bottom: 0;
        font-size: 1em;
        line-height: 1em;
        font-family: $roboto;
        font-weight: 600;
        color: #171725;
      }
      .toggleRightSection {
        display: flex;
        align-items: center;
        flex-direction: row;
      }
      .isRuleReloadReq {
        display: flex;
        align-items: center;
        p {
          margin-bottom: 0;
          color: $black;
          margin-right: 5px;
          font-size: 1em;
          line-height: 1em;
          font-family: $roboto;
          font-weight: 400;
        }
        img {
          margin-right: 5px;
          height: 20px;
          width: 20px;
        }
        label {
          width: auto !important;
          margin-right: 0 !important;
          font: normal normal normal 1em Segoe UI;
          color: $black;
          line-height: 24px !important;
        }
      }
      .assist {
        display: flex;
        align-items: center;
        p {
          margin-left: 5px;
          margin-bottom: 0;
          color: $black;
          margin-right: 5px;
          font-size: 1em;
          line-height: 1em;
          font-family: $roboto;
          font-weight: 400;
        }
        img {
          margin-right: 5px;
          height: 20px;
          width: 20px;
        }
        label {
          width: auto !important;
          margin-right: 0 !important;
          font: normal normal normal 1em Segoe UI;
          color: $black;
          line-height: 24px !important;
        }
      }
    }
    .table-striped tbody tr:nth-of-type(odd) {
      background: #fff;
    }
    .table-striped tbody tr:nth-of-type(even) {
      background: #f8fdff !important;
    }
    .table {
      thead {
        tr {
          background: #f4f4f4 !important;
          // border: solid #cecece 2px;
          // border-radius: 8px 8px 0px 0px;
          th {
            padding: 10px !important;
            font: 0.85em/21px Segoe UI;
            font-weight: 600;
            letter-spacing: 0px;
            color: black;
            text-align: left;
            // border: none;
            vertical-align: middle;
            text-transform: none;
          }
        }
      }
      tbody {
        tr {
          td {
            select {
              width: 95px;
              border-radius: 10px;
              margin: 0 auto;
              height: 50px;
              @include breakpoint(lgdesktop) {
                height: 40px;
                font-size: 12px;
              }
            }
          }
          tbody {
            tr {
              td {
                select {
                  width: 100%;
                  border-radius: 10px;
                  margin: 0 auto;
                  height: 50px;
                  @include breakpoint(lgdesktop) {
                    height: 40px;
                    font-size: 10px;
                  }
                }
                .MuiAutocomplete-hasPopupIcon {
                  width: 95px;
                  margin: 0 auto;
                }
                input {
                  padding: 24px;
                  height: 34px;
                  background: #ffffff;
                  // box-shadow: $dark-btm-shadow;
                  border-radius: 10px;
                  display: inline;
                  // border: none;
                  font-size: 1rem;
                }
                .input-group {
                  padding-right: 12px;
                }
              }
            }
          }
        }
      }
    }
    .genric-table {
      box-shadow: none;

      .MuiAutocomplete-root {
        .MuiInputBase-root.Mui-disabled {
          background-color: #e9ecef;
          border-radius: 4px;
        }
        .MuiInputBase-input.Mui-disabled {
          opacity: 1;
          &::placeholder {
            color: #00000078;
            font-size: 14px;
          }
        }
      }
      .over-table table tbody tr td {
        font-family: var(--def-font);
        letter-spacing: 0px;
        color: #63a8ed;
        text-align: left;
        vertical-align: middle;
        padding: 10px 15px;
        // border-bottom: 0 solid #ffffff;
        // border: var(--bc);
        p {
          margin-top: 0;
        }
        .market {
          .formWrapper {
            top: -126px;
            @include breakpoint(lgdesktop) {
              top: -108px;
            }
          }
          .multiDropdown-edit {
            .formWrapper {
              left: 10px;
              top: -167px !important;
              @include breakpoint(lgdesktop) {
                top: -149px !important;
              }
            }
          }
        }
        .pointer {
          pointer-events: none;
        }
        .mainWrapperDropdown {
          top: -97px;
          margin: 0 !important;
          width: 100%;
          li {
            padding-top: 0;
            span {
              font: normal normal normal 12px Roboto;
            }
          }
        }
        .formWrapper {
          padding: 12px 15px;
          top: -239px;
          margin: 0;
          @include breakpoint(lgdesktop) {
            top: -171px;
          }
          label {
            width: 100% !important;
            text-align: left;
            margin-top: 10px;
            margin-bottom: 5px;
          }
          .input-group {
            padding-right: 0;
            input {
              width: 100% !important;
              margin: 0;
              font: normal normal normal 13px/18px Segoe UI !important;
            }
          }
        }
        .multiDropdown-edit {
          .formWrapper {
            top: -280px !important;
            left: 10px;
            @include breakpoint(lgdesktop) {
              top: -212px !important;
            }
          }
        }
        .finalValueShow {
          width: 100% !important;
          font-size: 14px;
          height: 35px;
        }
      }
    }
  }
  .submitsbuttons {
    margin-top: auto;

    // border-top: none;
    text-align: right;
    border: 0;
    .btn-primary {
      padding: 10px 20px;
      border-radius: 5px;
      text-transform: uppercase;
      font-size: 0.85em;
      border: none;
      height: 44px;
      text-transform: uppercase;
      font-weight: 600;
      margin-left: 0.5rem;
      &:focus {
        border: 2px solid orange;
        background: orange !important;
        color: #ffffff;
      }
    }
    .transparentBtns {
      &.btn-primary {
        border: 2px solid #2479de;
        background: none !important;
        color: #2f78d7;
        margin-left: 0.5rem;
        &:focus {
          border: 2px solid orange;
          background: none !important;
          color: orange;
        }
      }
    }
  }
}

.table thead th:nth-child(1) {
  // border-top-left-radius: 10px;
}

.table thead th:last-child {
  // border-top-right-radius: 10px;
}

.middleWrapper {
  .custom-switch {
    input {
      width: auto !important;
    }
  }
}

.newTemplateName {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1em;
  @include breakpoint(lgdesktop) {
    margin-bottom: 0em;
  }
  div {
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 5px;
    p {
      color: $black;
      font-family: $roboto;
      margin-right: 17px;
      font-size: 1em;
      margin-bottom: 0;
      white-space: nowrap;
      @include breakpoint(lgdesktop) {
        font-size: 13px;
      }
    }
    input {
      padding: 14px;
      height: 34px;
      background: #ffffff;
      // box-shadow: $dark-btm-shadow;
      border-radius: 10px;
      display: inline;
      border: solid #bab9b9 1px;
      font-size: 1rem;
    }
  }
}

.previewModalRootWrpaer {
  width: 100% !important;
}

.createstretgy {
  .MuiAutocomplete-root {
    width: 100%;
    border-radius: 10px;
    margin: 0 auto;
    height: 50px;
    @include breakpoint(lgdesktop) {
      height: 40px;
    }
    border: 1px solid #ced4da;
    .MuiInputBase-root {
      height: 50px;
      background: #fff;
      border-radius: 10px;
      @include breakpoint(lgdesktop) {
        height: 38px;
        font-size: 12px;
      }
      input::placeholder {
        font-size: 1em;
      }
    }
  }
}

.algo-wrapper-strategy {
  td {
    // border-top: none;
  }
}

.createstretgy {
  .genric-table {
    // box-shadow: $dark-btm-shadow;
    // border: solid #bab9b9 1px;
    // border-radius: 15px;
    margin-left: 30px;
    margin-right: 30px;
    // overflow: auto;
    th {
      // border-top: none !important;
    }
    td {
      // border-top: none !important;
    }
  }
  .algo-wrapper-strategy {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;
    .input-group {
      .form-control {
        border-radius: 10px;
      }
    }
  }

  select:focus {
    border-color: #ced4da !important;
    outline: 0;
    // box-shadow: inherit !important;
    border: solid #bab9b9 1px;
  }
  .MuiInput-underline:before {
    border-bottom: none !important;
    border-bottom-style: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
    border-bottom-style: none !important;
  }
}

.btn-primary:focus {
  border: 2px solid orange;
  background: orange !important;
  color: #ffffff;
}

.genric-table .over-table {
  margin: 0;
}

.previewModalRootWrpaer .middleWrapper .table thead tr th {
  padding: 0px 10px 0px !important;
  font-weight: 700;
  font-family: var(--def-font);
  // border-top: var(--bc);
}

.previewModalRootWrpaer .middleWrapper .table tbody tr td select {
  width: 100%;
  // box-shadow: $dark-btm-shadow;
  border: solid #bab9b9 1px;
  border: 0;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
}

.previewModalRootWrpaer .middleWrapper .table tbody tr td select:focus {
  width: 100%;
  // box-shadow: $dark-btm-shadow;
  border: solid orange 2px !important;
  // border: 0;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
}

.createstretgy .MuiAutocomplete-root .MuiInputBase-root {
  width: 100%;
  // box-shadow: $dark-btm-shadow;
  border: solid #bab9b9 1px;
  border: 0;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
}

.createstretgy .MuiAutocomplete-root {
  border: 0;
}

.MuiAutocomplete-hasPopupIcon {
  width: 182px;
  margin: 0 auto;
  height: 40px;
  cursor: pointer;
  &.error-input {
    .MuiAutocomplete-inputRoot {
      input {
        border: 1px solid red;
      }
    }
  }
  @include breakpoint(lgdesktop) {
    width: 150px;
  }
}

.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot {
  padding: 0 0 0 0 !important;
  input {
    width: 100% !important;
    padding: 0.375rem 0.75rem !important;
    height: 25px;
    border-radius: 10px;
    font-family: $roboto;
    font-size: 0.85em;
    cursor: pointer;
    text-align: left;
  }
}

.over-table .input-group .form-control {
  width: 100%;
  // box-shadow: $dark-btm-shadow;
  // border: solid #bab9b9 1px;
  border: var(--bc) !important;
  // border: 0;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
}

.orderDetailTable td span {
  font: normal normal bold 1em/50px Roboto;
}

.templateNameWrapper .form-control {
  height: 40px !important;
  font-size: 14px;
  border-radius: 1vh !important;
  border: 0;
  // box-shadow: $dark-btm-shadow;
  border: var(--bc) !important;
}

.templateNameWrapper .form-control:focus {
  // box-shadow: 0px 3px 6px #00000029 !important;
  border: solid orange 2px !important;
  box-shadow: none !important;
  outline: none;
}

.genric-table .over-table table thead th {
  min-width: 0% !important;
}

.genric-table .over-table table tbody tr td {
  vertical-align: middle;
}

.genric-table .over-table table tbody tr td label {
  margin: 4px 0;
}

.orderDetailTable p {
  width: 100% !important;
  text-align: center;
}

.over-table .input-group .form-control {
  margin: 0 !important;
}

.over-table .input-group .form-control:focus {
  border: solid orange 2px !important;
  outline: none;
  box-shadow: none !important;
}

.orderDetailTable2 .genric-table .over-table table thead th {
  min-width: calc(100% / 6);
  white-space: nowrap;
}

.newTemplateName div {
  padding: 0;
}

.newTemplateName .input-group > .form-control {
  border-radius: 8px;
  border: var(--bc) !important;
  padding: 10px 15px;
  box-shadow: none !important;
}

.newTemplateName .input-group > .form-control:focus {
  box-shadow: none;
  outline: none;
}

.newTemplateName div p {
  font: normal normal normal 1em/50px Roboto;
}
.createstretgy {
  .colormodal-global {
    .popup {
      margin-top: 65px !important;
      background: #fff;
      @include breakpoint(lgdesktop) {
        margin-top: 34px !important;
      }
    }
  }
  .divider {
    width: 110%;
    margin: 0 0 15px -30px;
  }
  .sectionHeadings {
    margin-left: 30px;
    margin-right: 30px;
  }
  .newTemplateName {
    margin-left: 30px;
    margin-right: 30px;
    div {
      &:nth-child(1) {
        margin-top: 20px;
        padding-bottom: 20px;
      }
    }
    .input-group {
      .form-control {
        // background: #ffffff;
        box-shadow: 0 0 0 #00000000;
        // border: solid #bab9b9 1px;
        border: var(--bc) !important;
        border-radius: 10px;
        display: inline;
        // border: none;
      }
    }
  }
  .GenButton-text-emt {
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
  }

  .genric-table {
    .over-table {
      table {
        thead {
          th {
            width: fit-content;
            padding: 0.2rem;
            // border: var(--bc);
          }
        }
        td {
          width: fit-content;
        }
      }
    }
  }
  .orderDetailTable.orderDetailTable2 th,
  .orderDetailTable.orderDetailTable2 td {
    width: auto !important;
    min-width: 20% !important;
  }
  .orderDetailTable2 {
    table {
      tbody {
        tr {
          td {
            &:nth-child(5) {
              width: 20% !important;
              @include breakpoint(lgdesktop) {
                width: 25% !important;
              }
            }
          }
        }
      }
    }
    .error-input {
      .multiDropdown {
        .finalValueShow {
          border: 1px solid red !important;
        }
      }
    }
    .multiDropdown {
      .finalValueShow {
        // box-shadow: 0px 3px 6px #00000029 !important;
        border: solid #bab9b9 1px;
        height: 40px !important;
        font-size: 14px !important;
        margin: 0;
      }
      .mainWrapperDropdown {
        top: 41px !important;
      }
      .formWrapper {
        top: 41px !important;
      }
    }
    .market {
      .multiDropdown {
        .formWrapper {
          top: 41px !important;
        }
      }
    }
  }
  .orderDetailTable2 {
    .genric-table {
      .over-table {
        table {
          thead {
            th {
              &:nth-child(3),
              &:nth-child(4) {
                width: 18% !important;
              }
            }
          }
        }
      }
    }
  }
}
.createstretgy
  .middleWrapper
  .genric-table
  .over-table
  table
  tbody
  tr
  td
  .multiDropdown-edit
  .formWrapper {
  top: 0px !important;
}

.previewModalRootWrpaer.createstretgy
  .middleWrapper
  .genric-table
  .over-table
  table
  tbody
  tr
  td
  .market
  .multiDropdown-edit
  .formWrapper {
  top: 0px !important;
}

.error-input .multiDropdown input {
  border: 1px solid red !important;
}

.isDisable {
  pointer-events: none;
}

.es-argument-container {
  display: flex;
  flex-direction: row;
  height: 10vh;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.rule-create-align-items-center-container {
  display: flex;
  flex-direction: row;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.rule-create-align-items-center-item {
  font-weight: 700;
}

.ex-la-cls {
  font-family: var(--def-font) !important;
  color: white !important;
}

.sec-header {
  font-family: var(--def-font);
  font-weight: 500;
  font-size: calc(var(--font-size) * 1.4);
}

.previewModalRootWrpaer
  .middleWrapper
  .genric-table
  .over-table
  table
  tbody
  tr
  td {
  border: var(--bc) !important;
  font-size: 0.8rem;
  // border-spacing: 0;
}

.int-table-td {
  // border: var(--bc) !important;
  border-right: var(--bc) !important;
}

.int-table-tr {
  border-top: var(--bc) !important;
  border-bottom: var(--bc) !important;
}

.createStrategy-bottom-foot {
  display: grid;
  background-color: #dedede55;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
  height: 8vh;
  margin-top: auto;
}

.previewmodal {
  display: flex;
  flex-direction: column;
}
.buySellColumn {
  width: 8rem !important;
}

.OrderDetailsCreateStrategyV2-title-container {
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem 1rem;
  cursor: pointer;
  border-radius: var(--border-radius);
  border: var(--borderInactive);
}

.OrderDetailsCreateStrategyV2-title-container:focus {
  border: var(--focusBorder);
}

.OrderDetailsCreateStrategyV2-title-container:hover {
  border: var(--borderHover);
}

.OrderDetailsCreateStrategyV2-title {
  font-family: var(--def-font);
  font-weight: 500;
  font-size: calc(var(--font-size) * 1.4);
  margin: 0.5rem 0.5rem;
}

.OrderDetailsCreateStrategyV2-title-dropdown {
  height: 1.5rem;
  width: 1.5rem;
  margin: 0.5rem 0.5rem;
}
