@import url('../../../global.css');

.multiCheck-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.multiCheck-label {
  font-family: var(--def-font);
  font-size: 2.5vh;
  color: var(--black);
  margin: 0 0 1vh 0;
}

.multiCheck-options {
  display: flex;
  flex-direction: row;
}

.multiCheck-option-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.multicheck-opt-check {
  height: 3vh;
  width: 3vh;
}

.multicheck-opt-label {
  font-family: var(--def-font);
  font-size: var(--font-size);
  color: var(--black);
  margin: 0 1vh 0 1vh;
}

.multiCheck-select-box {
  position: relative;
  background-color: white;
  min-height: 1.5rem;
  height: 1.5rem;
  width: 90%;
  border: 1px solid #c1c1c1;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.multiCheck-select-box:hover {
  height: 4vh;
  border: 1px solid #cecece;
  border-radius: 0.5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.multiCheck-select-selected {
  font-family: var(--def-font);
  font-size: var(--font-size);
  margin: 0 0 0 0.5rem;
  max-width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.multiCheck-select-placeholder {
  font-family: var(--def-font);
  font-size: var(--font-size);
  margin: 0 0 0 0.5vw;
  color: #d4d6e5;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.multicheck-select-ddicon {
  height: 1rem;
  width: 1rem;
  margin: 0 0.5vw 0 0;
}

.dd-multiselect-area {
  width: 100%;
  border: 1px solid #c1c1c1;
  border-radius: 0.5vh;
  padding: 1vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 3;
}

.dd-meultiselect-scrollable {
  min-height: 10vh;
  max-height: 20vh;
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
