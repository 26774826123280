@import '../../../../assets/scss/variable.scss';
@import '../../../../assets//scss/mixins.scss';

.select-checkbox {
  padding: 0;
  margin: -10px 0 15px 0;
  @include breakpoint(lgdesktop) {
  }

  ul {
    list-style: none;
    display: -webkit-flex;
    display: flex;
    background: #0022a8;
    color: #fff;
    height: 93px;
    font: normal normal 700 25px/33px Segoe UI;
    line-height: 78px;
    overflow: hidden;
    margin-bottom: 0;
    border: 1px solid #707070;

    @include breakpoint(lgdesktop) {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      font: normal normal 700 16px/20px Segoe UI;
      height: 40px !important;
    }
  }

  .unslect-bar {
    align-items: center;
    line-height: 30px !important;
    background: #0023ffbf !important;
    border-radius: 0 !important;
    padding: 18px 18px;
    border-radius: 5px !important;
    position: relative;
    height: 100px !important;
    margin-top: 15px;
    @include breakpoint(lgdesktop) {
      height: 70px !important;
    }

    li:first-child {
      font-family: $roboto;
      font-size: 20px;
      line-height: 26px;
      color: #f3f5f9;
      padding-right: 80px !important;
      margin-right: 80px !important;
      border-right: 1px solid #ffffff30;
      font-weight: 500;
      @include breakpoint(lgdesktop) {
        font-size: 14px;
        padding-right: 70px !important;
        margin-right: 70px !important;
      }
    }
    .close-right {
      .cancel-img {
        position: absolute;
        right: 40px;
        width: 25px;
        @include breakpoint(lgdesktop) {
          width: 15px;
          right: 22px;
        }
      }
    }
    li {
      padding: 0 !important;
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;
      .status-button {
        border: none;
        background: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
    
      }
      .sq-button {
    
        border: none;
        background: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
        @include breakpoint(lgdesktop) {
        
        }
    
      }
      .sqrOff-image {
     
        @include breakpoint(lgdesktop) {
        }
       
      }
      
      img {
        width: 70px;
        @include breakpoint(lgdesktop) {
          width: 50px;
        }
      }
    }
  }
}
