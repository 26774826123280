#mainPanel {
  height: 6vh;
  width: 50%;
  margin-left: 1rem;
  background-color: #63a8ed;
  border-radius: 6px;
  display: grid;
  grid-template-columns: 50% 48% 2%;
}

.options {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.options2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}

.textCont {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  margin: auto 1rem auto 1rem;
  font-size: 1em;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
}

.liveButton {
  margin-left: 10px;
}
