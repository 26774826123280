@import '../../../assets/scss/variable.scss';
@import '../../../assets/scss/mixins.scss';
@import url('../../../global.css');

.analyzer {
	.red {
		color: #800000;
	}
	.green {
		color: #157200;
	}
	box-shadow: 0px 0px 5px #00000012;

	.search_bar {
		width: 523px;
		background: #ffffff;
		border-radius: 24px;
		color: #000;
		color: #413f46;
		position: relative;
		.dropdown-menu {
			box-shadow: 0px 3px 6px #63a8ed29;
			border: 1px solid #63a8ed;
			border-radius: 5px;
			overflow: hidden;
			padding: 0;
			left: -2px !important;
			a {
				color: #000 !important;
				font: normal normal normal 20px/20px Roboto;
				padding: 10px;
				&:active {
					background: #ebebff !important;
				}
				@include breakpoint(lgdesktop) {
					font-size: 14px;
				}
			}
		}
		.box {
			display: flex;
			align-items: center;
			height: 100%;
			border: 0.3px solid #dadada;
			border-radius: 4px;
			.dropdown {
				&.show {
					.search::after {
						transform: rotate(180deg);
					}
				}
			}
			.search {
				text-align: left;
				width: 200px;
				background: #f4f4f4 0% 0% no-repeat padding-box;
				height: 33px;
				display: flex;
				justify-content: center;
				align-items: center;
				font: normal normal normal 16px/24px Roboto;
				color: #97999f;
				border: 1px solid transparent;
				@include breakpoint(lgdesktop) {
					height: 32px;
					font: normal normal normal 12px/24px Roboto;
				}
				@include breakpoint(tablet) {
					height: 27px;
				}
				&:active {
					background: #ebebff;
					border: 24px 0 0 24px;
					border: 1px solid transparent;
					color: #000;
				}
				&:focus {
					border: solid orange 2px !important;
					outline: none;
					box-shadow: none !important;
				}
				&::after {
					content: '';
					width: 0px;
					height: 0px;
					display: inline;
					margin-left: 15px;
					border-top: 6px solid #000000;
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					cursor: pointer;
				}
			}
			input {
				width: 62%;
				height: 33px;
				background: transparent !important;
				padding-right: 30px;
				padding-left: 5px;
				border-color: transparent;
				@include breakpoint(lgdesktop) {
					width: 59%;
					height: 32px;
				}
				&:focus {
					border: solid orange 2px !important;
					outline: none;
					box-shadow: none !important;
				}
			}
			.icon {
				margin-right: 10px;
				height: unset;

				img {
					width: 14px;
				}
				&:focus {
					border: solid orange 2px !important;
					outline: none;
					box-shadow: none !important;
				}
			}
		}
		.search {
			color: #000;
		}
	}

	.expandableList {
		cursor: pointer;
		font-size: var(--font-size);
	}

	.strategy_analyzer_traded_qty {
		display: block;
	}

	.expandableList:focus {
		li {
			color: orange !important;
		}
	}

	.custom-dropdwon {
		img {
			height: 17px !important;
			margin-left: 2px;
			@include breakpoint(lgdesktop) {
				height: 14px !important;
			}
		}
		&:after {
			display: none;
		}
	}
	@media (max-width: 1400px) {
		.search_bar {
			width: 425px;
			.box {
				.search {
					width: 120px;
					color: #000;
				}
			}
		}
	}

	@media (max-width: 1200px) {
		.search_bar {
			width: 200px;
			height: 27px;
			.box {
				.search {
					width: 70px;
					font-size: 10px;
					color: black;
					&::after {
						margin-left: 2px;
						border-top: 3px solid #000000;
						border-left: 3px solid transparent;
						border-right: 3px solid transparent;
						color: black;
					}
				}
				.icon {
					right: 8px;
					top: 3px;
					@include breakpoint(tablet) {
						top: 0px;
					}
					img {
						width: 10px;
					}
				}
			}
		}
	}
	.analyzer-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 5px 25px 5px 10px;
		background: white;
		p {
			margin-bottom: 0 !important;
			font: normal normal normal 1em/50px Roboto;
			@include breakpoint(lgdesktop) {
				font: normal normal normal 0.75em Roboto;
			}
		}
		.analyzer-left {
			display: flex;
			align-items: center;
			margin: 0px 0px 0px 10px;
		}
		.analyzer-right {
			display: flex;
			align-items: center;
			p {
				margin-right: 20px;
				color: #63a8ed;
				font: normal normal normal 1em/50px Roboto;
				@include breakpoint(lgdesktop) {
					font: normal normal normal 0.75em Roboto;
				}
				img {
					margin-right: 10px;
				}
				.analyzer-hover {
					display: none;
				}
				&:hover {
					.analyzer-hover {
						display: inline;
					}
					img {
						display: none;
					}
				}
			}
			.trade-modify-toggle {
				display: flex;
				align-items: center;
				p {
					margin-bottom: 0;
					color: $black;
					margin-right: 5px;
					font-size: 0.8em;
					line-height: 0.8em;
					font-family: $roboto;
					font-weight: 400;
				}
				img {
					margin-right: 5px;
					height: 20px;
					width: 20px;
				}
				label {
					width: auto !important;
					margin-right: 0 !important;
					font: normal normal normal 1em Segoe UI;
					color: $black;
					line-height: 24px !important;
				}
			}
		}
		select {
			margin: 0 10px;
			background: #ffffff;
			// box-shadow: $dark-btm-shadow;
			border-radius: 5px;
			width: 55px;
			border: none !important;
			-webkit-appearance: none;
			appearance: none;
			background-image: url('../../../assets/img/arrow-down-filled-triangle.svg');
			background-repeat: no-repeat;
			background-position: 90% 55%;
			background-size: 10px;
			@media (max-width: 1300px) {
				font-size: 14px;
			}
			option {
				font-size: 14px;
				@include breakpoint(lgdesktop) {
					font-size: 10px;
				}
			}
		}
		select:focus {
			border: solid orange 2px !important;
			outline: none;
			box-shadow: none !important;
		}
	}
	.genric-table .over-table table thead tr {
		background: transparent linear-gradient(180deg, #04135d, #010413) 0 0
			no-repeat padding-box#413f46;
	}
	.genric-table .over-table table thead th {
		color: black !important;
	}
	// .genric-table table tbody tr:nth-of-type(even) {
	//   background-color: #f8fdff !important;
	// }
	.p-right {
		margin-right: -50px;
	}
	.table thead th:nth-child(1) {
		border-top-left-radius: 0px;
	}
	.table thead th:last-child {
		border-top-right-radius: 0px;
	}
	.p-left {
		margin-left: -50px;
		margin-right: -26px;
		color: #63a8ed;
		font: normal normal normal 1em/50px Roboto;
		margin-bottom: 0;
		span {
			margin-left: 10px;
		}
	}

	.p-left {
		margin-left: -11px;
		margin-bottom: 0;
		span {
			margin-left: 10px;
		}
	}

	.form-control {
		margin-top: -4px;
	}

	.genric-table .strategy_1 {
		text-align: left;
		span {
			color: #0020ff;
			font-weight: 700;
		}
	}
	.genric-table table tbody tr {
		.strategy_1 {
			text-align: center;
		}
	}

	.genric-table .over-table table tbody tr td:nth-child(1) {
		text-align: center;
		padding-top: 5px;
		width: 3%;
		padding: 0px;
	}
	.genric-table .over-table table tbody tr td:nth-child(2) {
		text-align: left;
	}
	.genric-table .over-table .table thead th:nth-child(2) {
		text-align: left;
	}
	.genric-table .over-table .table thead th:nth-child(1) {
		text-align: center;
		margin-left: 10px;
		.checkmark {
			top: -14px;
		}
	}
	.genric-table .over-table table tbody tr td:nth-child(3) {
		color: blue;
		font-weight: 600;
	}

	.genric-table .over-table table tbody tr td {
		padding: 0 5px;
		border-bottom: 1px solid #ffffff;
		@include breakpoint(lgdesktop) {
		}
		span {
			img:focus {
				border: solid orange 1px !important;
				outline: none;
				box-shadow: none !important;
			}
		}
		ul {
			margin-bottom: 0;
		}
	}

	.genric-table .over-table table thead tr th {
		padding: 0px 5px;
		line-height: 15px;
		min-width: 0% !important;
		color: #fff;
		font-size: 16px;
		@include breakpoint(lgdesktop) {
			font-size: 10px;
		}
	}

	.in-process {
		pointer-events: none;
	}
	.in-edit {
		pointer-events: none;
	}
	.in-pause {
		color: #800000;
		font: normal normal bold 16px/21px Roboto;
		@include breakpoint(lgdesktop) {
			font: normal normal bold 10px Roboto;
		}
	}
	.status-color {
		color: #0000ff;
		font: normal normal bold 16px/21px Segoe UI;
		@include breakpoint(lgdesktop) {
			font: normal normal bold 10px Segoe UI;
		}
	}
	.expand-btn {
		color: #0000ff;
		font-weight: 500;
		cursor: pointer;
		font: italic normal normal 16px/21px Roboto;
		@include breakpoint(lgdesktop) {
			font: italic normal normal 10px/21px Roboto;
			font-weight: 500;
		}
	}
	.pl-hide {
		display: none;
	}
	.gross-hide {
		display: none;
	}
	.no-status {
		display: none;
	}
	.pagination {
		justify-content: center !important;
		.table-pagination {
			display: none;
		}
	}
	li {
		list-style-type: none;
	}

	/****select box design fix****/
	.select-check-box-custom {
		position: relative;
		.inactive {
			display: none;
		}

		.active {
			position: absolute;
			width: 230px;
			// background-color: #ffffff;
			z-index: +2;
			left: 50%;
			top: 39px;
			transform: translate(-50%);
			box-shadow: 0px 3px 6px #63a8ed29;
			border: 1px solid #63a8ed;
			border-radius: 20px 20px 20px 20px;
			overflow: hidden;
			padding: 0;
			li {
				color: #000 !important;
				font: normal normal normal 20px/20px Roboto;

				display: flex;
				align-items: center;
				@include breakpoint(lgdesktop) {
					font-size: 10px;
				}
			}
			@include breakpoint(lgdesktop) {
				width: 159px;
				top: 29px;
			}
		}

		.toogledropdown {
			text-align: left;
			padding: 20px 10px;
			@include breakpoint(lgdesktop) {
				padding: 10px 10px;
			}
			li {
				padding-bottom: 15px;
				&:last-child {
					padding-bottom: 0px;
				}
				input {
					margin-right: 10px;
				}
			}
		}
	}
	/****select box design fix****/
	.table-main {
		border-collapse: collapse;
		// line-height: 0.1;
		td {
			border-bottom: 1px solid black;
		}
		thead {
			th {
				color: #4a4545;
			}
		}
		.dropdown-toggle {
			&::after {
				position: absolute;
				top: 48%;
			}
		}

		.up {
			&::after {
				transform: rotate(-180deg) !important;
			}
		}
	}
	/****status btn dropdown arrow****/

	.pagination {
		margin: 10px 0px;
	}
}

/* The container */
.containerr {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.containerr input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: -9px;
	left: 23px;
	height: 15px;
	width: 15px;
	background-color: #fff;
	border: 1px solid #96989d;
	border-radius: 3px;
	@include breakpoint(lgdesktop) {
		top: -13px !important;
	}
}

/* On mouse-over, add a grey background color */
.containerr:hover input ~ .checkmark {
	background-color: #ccc;
}

/* On Focus: Tab Movements */
.containerr input:focus ~ .checkmark {
	border: 2px solid orange !important;
}

/* When the checkbox is checked, add a blue background */
.containerr input:checked ~ .checkmark {
	background-color: #1c1c2b;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.containerr input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.containerr .checkmark:after {
	left: 4px;
	top: 1px;
	width: 5px;
	height: 8px;
	border: solid white;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
	@include breakpoint(lgdesktop) {
		left: 4px;
		top: 1px;
		width: 5px;
		height: 9px;
	}
}
.theadcheckbox {
	.checkmark {
		left: 20px;
		@include breakpoint(lgdesktop) {
			top: -17px;
		}
	}
}

.table-title {
	font-family: var(--def-font);
	color: #4a4545;
	font-size: 1.7vh;
	margin: 0;
	font-weight: 500;
	vertical-align: middle !important;
}

.border-sa {
	border: 1px solid #cecece !important;
}

.sa-action-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}
