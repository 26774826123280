@import url('../../../global.css');

.screenComp-container {
	border-radius: 8px;
	border: 1px solid #cecece;
	/* min-height: 200px;
	min-width: 400px; */
	background-color: white;
	border-radius: 8px;
	box-shadow: 4px 4px 10px #00000055;
}

.ScreenComp-buttongroup {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 999;
}

.screenComp-editIconReArrange {
	height: 2rem;
	width: 2rem;
	user-select: none;
	border-radius: 4px;
	border: 1px solid #cecece33;
	margin: 0.1rem;
	transition: all 0.3s;
}
.screenComp-editIconReArrange:active {
	height: 3rem;
	width: 3rem;
	transition: all 0.3s;
}

.ScreenComp-rearrange {
	background-color: blue;
	cursor: grab;
}

.ScreenComp-resize {
	background-color: green;
	cursor: nwse-resize;
}

.ScreenComp-close {
	background-color: red;
	cursor: pointer;
}
