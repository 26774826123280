@import url('../../../../global.css');

.settings-container {
	height: 3em;
	width: 3em;
	border-radius: 3em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: all 1s;
	cursor: pointer;
	border: 1px solid #ffffff;
}

.settings-container:hover {
	transition: all 1s;
	border: 1px solid #cecece;
}

.settings-icon {
	height: 1.4em;
	width: 1.4em;
}

.riskMeasPopCont {
	height: 60vh;
	width: 40vw;
	background-color: white;
	border-radius: 1em;
}

.riskMeasPopCont-titleBar {
	background-color: var(--primary-color);
	height: 6vh;
	display: flex;
	flex-direction: row;
}

.riskMeasPopCont-title {
	color: white;
	font-size: calc(var(--font-size) * 1.2);
	margin: auto 0 auto 1em;
}

.riskMeasPopCont-content {
	height: 46vh;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
}

.riskMeasPopCont-table {
	border-collapse: collapse;
	border: 1px solid #f4f4f4;
}

.riskMeasPopCont-td {
	border: 1px solid #f4f4f4;
	padding: 0.3em 0 0.3em 0.8em;
}

.riskMeasPopCont-thead {
	background-color: #f4f4f4;
	top: 0;
}

.riskMeasPopCont-th {
	padding: 0.3em 0 0.3em 0.8em;
}

.riskMeasPopCont-td {
	font-family: var(--def-font);
	font-size: var(--font-size);
}

.riskMeasPopCont-btnGrp {
	height: 8vh;
	background-color: #f4f4f4;
	display: flex;
	flex-direction: row;
	align-items: center;
}

/* .riskMeasPopCont-tbody {
	height: 10vh;
	overflow-y: scroll;
} */
