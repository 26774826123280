@import url('./scroller.css');

:root {
  --def-font: 'Roboto', sans-serif;
  --black: #5b5b5f;
  --white: #fff;
  --dark-black: #171725;
  --font-size: 0.9rem;
  --primary-color: #0078d7;
  --border-radius: 4px;
  --table-border: 1px solid #cecece !important;
  --focus: #ffc107;
  --focusBorder: 1px solid #ffc107;
  --borderActive: 1px solid #0078d7;
  --inDdInfieldSize: 4.5vh;
  --inDdInfieldBorder: 1px solid #cecece;
  --borderHover: 1px solid #171725;
  --borderInactive: 1px solid #ffffff;
  --hoverSelectBackgroundColor: #cecece33;
  --normal-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.app-container-main {
  height: 100vh;
  width: 100vw;
  background-color: #f0f3f2;
}

.main-container-app-area {
  display: flex;
  flex-direction: column;
  height: 92%;
  overflow-y: auto;
}

.font-l1 {
  font-family: var(--def-font);
  font-size: var(--font-size);
  line-height: 1rem;
  letter-spacing: 0px;
  font-weight: 400;
}

.gen-border {
  cursor: pointer;
  border-radius: 4px;
  border: var(--inDdInfieldBorder);
}

.gen-border:active {
  opacity: 0.6;
}

.gen-border:hover {
  border: var(--borderHover);
}

.bannerMessage-container {
  height: 5rem;
  width: 10rem;
  background-color: #0078d7;
}
