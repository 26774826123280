@import url('../../../global.css');

.StrategyAnalyzerV2-container {
  width: 100%;
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  position: relative;
}

.StrategyAnalyzerV2-searchbar {
  margin: 0 auto 0 1rem;
}

.StrategyAnalyzerV2-s1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 999;
}

.StrategyAnalyzerV2-s2 {
  z-index: 1;
}

.StrategyAnalyzerV2-allCheckBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border: var(--inDdInfieldBorder);
}

.StrategyAnalyzerV2-allcheck-label {
  margin: 1rem 1rem;
}

.StrategyAnalyzerV2-pill-btn {
  margin: 0 0.2rem;
}
