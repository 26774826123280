@import url('../../../global.css');

.datainit-container {
  height: 80vh;
  width: 80vw;
}

.datainit-header {
  height: 8vh;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.datainit-header-text {
  font-family: var(--def-font);
  font-size: 2.5vh;
  color: white;
  margin-left: 1vw;
}

.datainit-footer {
  background-color: #f5f5f5;
  display: flex;
  height: 8vh;
  align-items: center;
}

.datainit-body {
  height: 64vh;
  overflow-y: scroll;
}

.datainit-s1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 10vh;
}

.datainit-s2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 54vh;
}

.base-conf-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 8vh;
}

.base-conf-cont-label {
  font-size: var(--font-size);
  width: fit-content;
  margin: 0 1vw;
  overflow-wrap: break-word;
  white-space: nowrap;
}

.data-init-msg-err {
  font-size: 2vh;
  font-family: var(--def-font);
  margin: 0 0 0 1vw;
  color: red;
}

.data-init-msg-suc {
  font-size: 2vh;
  font-family: var(--def-font);
  margin: 0 0 0 1vw;
  color: green;
}
