@import url('../../../global.css');

.buttonTabNav-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  width: fit-content;
}

.buttonTabNav-tab-container {
  border-radius: var(--border-radius);
  border: var(--inDdInfieldBorder);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0.5rem 0.5rem;
}

.buttonTabNav-actve {
  background-color: #0078d722;
  border: var(--borderActive);
  color: var(--primary-color);
}
.buttonTabNav-tab-container:focus {
  border: var(--focusBorder);
}

.buttonTabNav-tab-text {
  margin: 0.5rem 1rem;
  user-select: none;
}
