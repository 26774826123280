@import url('../../../global.css');

.StrategyAnalyzerTabMan-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.StrategyAnalyzerTabMan-divider {
  height: 1px;
  width: 100%;
  background-color: var(--black);
}

.StrategyAnalyzerTabMan-top-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ManageStratgyAnalyzerTab-container {
  height: 90vh;
  width: 70vw;
  display: flex;
  flex-direction: column;
}

.ManageStratgyAnalyzerTab-header {
  height: 10vh;
  width: 100%;
  background-color: var(--primary-color);
  display: flex;
}

.ManageStratgyAnalyzerTab-body {
  height: 70vh;
  width: 100%;
  /* background-color: var(--primary-color); */
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.ManageStratgyAnalyzerTab-footer {
  height: 10vh;
  width: 100%;
  background-color: #cecece33;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.ManageStratgyAnalyzerTab-header-text {
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 1.4);
  margin: auto auto auto 1rem;
  color: var(--white);
}

.TabContainer-container {
  border: var(--table-border);
  width: 100%;
  height: fit-content;
  margin: 0 0 1rem 0;
  border-radius: var(--border-radius);
}

.TabContainer-header {
  height: 3rem;
  width: 100%;
  background-color: #cecece33;
  display: flex;
  border-bottom: var(--table-border);
}

.TabContainer-header-text {
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 1.1);
  color: var(--primary-color);
  margin: auto auto auto 1rem;
}

.TabContainer-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: auto;
}
