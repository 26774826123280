@import url('../../../../global.css');

.ban-security-s1 {
  display: flex;
  display: flex;
  height: 10vh;
  align-items: center;
  justify-content: space-between;
}

.ban-security-container {
  height: 80vh;
  margin-bottom: 5vh;
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ban-security-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ban-security-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sm-iconButton-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 4vh;
  width: 4vh;
  background-color: #0078d7;
  border-radius: 4vh;
  outline: none;
}

.sm-iconButton-icon {
  height: 2.4vh;
  width: 2.4vh;
}

.table-normal-data {
  font-family: var(--def-font);
  font-size: var(--font-size);
  color: #171725;
  white-space: nowrap;
  text-align: left;
  border: var(--table-border);
}

.ban-table-td-1 {
  font-family: var(--def-font);
  font-size: var(--font-size);
  color: #171725;
  text-align: left;
  white-space: nowrap;
  width: 2vw;
  border: var(--table-border);
}

.ban-table-td-2 {
  font-family: var(--def-font);
  font-size: var(--font-size);
  color: #171725;
  text-align: left;
  white-space: nowrap;
  width: 8vw;
  border: var(--table-border);
}

.banned-gr {
  font-family: var(--def-font);
  font-size: 2vh;
  color: green;
  border: var(--table-border);
}

.banned-re {
  font-family: var(--def-font);
  font-size: 2vh;
  color: red;
  border: var(--table-border);
}

.bansec-dealer-remark {
  font-family: var(--def-font);
  font-size: 2vh;
  border: 1px solid grey;
  border-radius: 1vh;
  height: 4vh;
  width: 12vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon-bansec {
  height: 2vh;
  width: 2vh;
  cursor: pointer;
  margin: auto;
}

.bs-tr-2 {
  background-color: grey !important;
}

.ban-sec-popup-cont {
  height: 80vh;
  width: 60vw;
}

.ban-sec-popup-title-red {
  height: 8vh;
  width: 100%;
  background-color: #e44125;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ban-sec-popup-title-green {
  height: 8vh;
  width: 100%;
  background-color: #30b212;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ban-sec-title-text {
  font-family: var(--def-font);
  font-size: 2.2vh;
  font-weight: 500;
  color: white;
  margin-right: 2vh;
}

.ban-sec-view-title-ban {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1vh;
  background-color: #e44125;
}

.ban-sec-view-title-unban {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1vh;
  background-color: #30b212;
}

.ban-sec-view-text {
  font-family: var(--def-font);
  font-size: 2vh;
  color: white;
  margin-left: 2vh;
}

.create-ban-sec1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  padding: 0 1vw 0 1vw;
}

.ban_sec-ban_level-cont {
  border: 1px solid #cecece;
  border-radius: 1vh;
  margin: 2vh 1vw 1vh 1vw;
  height: fit-content;
  overflow: hidden;
}

.ban_sec_ban_level-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1ch;
  background-color: #f4f4f4;
}

.ban-sec-internal-title {
  font-size: 2vh;
  font-family: var(--def-font);
  color: #0078d7;
}

.view-ban-table-cont {
  width: 95%;
  margin: 2vh auto 2vh auto;
  border: 1px solid #cecece;
  border-radius: 1vh;
}

.view-ban-table-header {
  background-color: #f4f4f4;
  border-radius: 1vh 1vh 0 0;
  height: 6vh;
  display: flex;
  align-items: center;
}

.view-ban-table-title {
  font-family: var(--def-font);
  color: #171725;
  font-size: 2vh;
  margin: 2vh;
}

.view-ban-table-cont-row-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 8vh;
}

.view-ban-table-cell {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 8vh;
  border-right: 1px solid #cecece;
  border-top: 1px solid #cecece;
}

.view-ban-table-cell-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 8vh;
  border-top: 1px solid #cecece;
}

.view-ban-table-cell-2 {
  grid-template-rows: 8vh;
  border-top: 1px solid #cecece;
}

.view-ban-table-text {
  font-family: var(--def-font);
  font-size: 2vh;
  color: #171725;
  margin: auto 0 auto 2vh;
  font-weight: 500;
}

.view-ban-table-text-2 {
  font-family: var(--def-font);
  font-size: 2vh;
  color: #171725;
  margin: auto 0 auto 2vh;
  font-weight: 300;
}

.view-ban-table-text-3 {
  font-family: var(--def-font);
  font-size: 2vh;
  color: #171725;
  margin: 2vh;
  font-weight: 300;
}

.remark-table-cell {
  font-family: var(--def-font);
  color: #171725;
  font-size: 2vh;
}

.create-ban-bottom-area {
  height: 8vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f5f5f5;
  justify-content: space-between;
}

.error-create-ban {
  font-family: var(--def-font);
  font-size: 2vh;
  margin-left: 2vh;
  color: red;
}

.feedback-response-text {
  height: 10vh;
  width: 100%;
  padding: 2vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: var(--def-font);
  font-size: 2vh;
}

.banPopup .popup .create_2 {
  min-width: 400px;
}

.startContainer {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 1vh 1.5vw;
}

.midContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 75vw;
}

.endContainer {
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 1vh 1.5vw;
}

.ban-table-row:nth-child(even) {
  background-color: #f4fbff;
}
