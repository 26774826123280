@import url('../../../../global.css');

.dragcomp-i1 {
  height: 2rem;
  width: 2rem;
}

.dragcomp-t {
  font-family: var(--def-font);
  font-size: var(--font-size);
  margin: 0 0 0 0.5rem;
}

.dragcomp-i2 {
  height: 2rem;
  width: 2rem;
  margin: 0 0.5rem 0 0.1rem;
}

.DragDropFunc-draggingRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid grey;
}
