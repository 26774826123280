@import url('../../../../global.css');

.excel-prev-1-cont {
  height: 90vh;
  width: 80vw;
  display: flex;
  flex-direction: column;
  position: relative;
}

.excel-prev-1-title {
  background-color: var(--primary-color);
  height: 8vh;
  display: flex;
  flex-direction: row;
}

.excel-prev-1-title-text {
  color: white;
  font-family: var(--def-font);
  margin: auto 0 auto 2vw;
  font-size: 2.5vh;
}

.excel-prev-1-btn-group {
  background-color: #f5f5f5;
  height: 8vh;
  margin-top: auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
