@import url('../../../global.css');
.inputfield-label {
  font-family: var(--def-font);
  font-size: 2vh;
}
.calender-icon {
  display: flex;
  height: 2.4vh;
  width: 2.4vh;
}

.date-container-error-date {
  border: 2px solid red;
}

.date-container {
  width: 15vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #cecece;
  border-radius: 0.5vh;
  height: 5vh;
  min-width: 12vw;
  margin: 0 10px 0px 0px;
}

.calender-input {
  border: none;
  outline: none;
  border-radius: 1vh;
  font-family: var(--def-font);
  margin: 0;
  font-size: 1.7vh;
  color: #929292;
  margin-left: 8px;
}
