@import "../../../assets/scss/mixins.scss";
@import "../../../assets/scss/variable.scss";
.staticConditionText {
    font-size: 1em;
    @include breakpoint(lgdesktop) {
       
        .col {
            @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
                white-space: nowrap;
            }
        }
    }
}
