@import '../../../assets/scss/variable.scss';
@import '../../../assets/scss/mixins.scss';
@import url('../../../global.css');

.logout-wrapper {
  height: 80vh;
  width: 50vw;
  .modal-body {
    padding: 0 93px 00px 93px;
    @include breakpoint(lgdesktop) {
      padding: 0 50px 0px 50px;
    }
    label {
      font-size: 22px;
      line-height: 29px;
      font-family: $roboto;
      font-weight: 500;
      color: #202062;
      width: 100%;
      text-align: left;
      margin-bottom: 14px;
      @include breakpoint(lgdesktop) {
        font-size: 1em;
        line-height: 20px;
        margin-bottom: 10px;
      }
    }
    input {
      border: 1px solid #707070;
      border-radius: 10px;
      height: 60px;
      box-shadow: none;
      @include breakpoint(lgdesktop) {
        height: 40px;
      }
    }
    button.login {
      background: #f37c20;
      border: none;
      font-size: 26px;
      line-height: 39px;
      font-family: $roboto;
      font-weight: 500;
      color: #fff;
      width: 100%;
      height: 70px;
      @include breakpoint(lgdesktop) {
        font-size: 20px;
        line-height: 20px;
        height: 40px;
        margin-bottom: 20px;
      }
    }
  }
  .nonecolor-Alertmodal {
    background: rgba(0, 0, 0, 0.49019607843137253);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999;

    .popup2 {
      width: 640px !important;
      height: 470 px !important;
      background: #fff;
      box-shadow: $dark-btm-shadow;
      border: 1px solid #707070;
      border-radius: 10px;
      position: absolute;
      top: 15%;
      left: 35%;
      z-index: 9999;
      overflow: hidden;
      @include breakpoint(lgdesktop) {
        width: 450px !important;
      }
      .create_2 {
        background: #fffdfd;
        height: 100%;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        align-items: center;
        h1 {
          margin-bottom: 40px;
          font-size: 44px;
          line-height: 58px;
          font-family: var(--def-font);
          font-weight: 500;
          color: #202062;
          margin-top: 80px;

          @include breakpoint(lgdesktop) {
            font-size: 30px;
            margin-top: 40px;
            margin-bottom: 20px;
          }
        }
        p {
          font-size: 22px;
          line-height: 29px;
          font-family: $roboto;
          font-weight: 500;
          color: #202062;
          margin-bottom: 40px;
          @include breakpoint(lgdesktop) {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 25px;
          }
        }

        .logout-btn img {
          @include breakpoint(lgdesktop) {
            height: 42px;
          }

          &a:focus,
          a:hover,
          a img {
            text-decoration: none;
            border: 0;
            outline: none;
          }
        }
      }
    }
  }
  .error-tag {
    color: red !important;
    max-width: 345px;
    font-size: 10px;
  }
  .close {
    position: absolute;
    top: 8px;
    right: 14px;
    height: 71px;
    width: 71px;
    @include breakpoint(lgdesktop) {
      top: 5px;
      right: 0px;
    }
    span {
      font-size: 60px;
      font-weight: 300;
      color: #f37c20;
      background: #fff9f4;
      height: 71px;
      width: 71px;
      border-radius: 50%;
      display: block;
      @include breakpoint(lgdesktop) {
        height: 50px;
        width: 50px;
        font-size: 43px;
      }
    }
  }
}
