@import url('../../../global.css');

.riskConfigBody {
	min-height: 40vh;
	max-height: 85vh;
	width: 100%;
	display: flex;
	flex-direction: row;
	/* grid-template-columns: 2.05fr 7fr; */
	overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.riskConfigBody::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.riskConfigBody {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.riskConfigTable {
	max-height: 365px;
}

.buttonSection {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0.5rem 1rem 0.5rem 1rem;
}

.inputField {
	min-width: 5vw;
	border-radius: 0.2rem;
	border: #8a8a8a solid 0.1vw;
	padding: 0.1rem 0.2rem 0.1rem 0.2rem;
	width: 3rem;
	font-family: var(--def-font);
	font-size: var(--font-size);
	text-align: center;
}

.inputField:focus {
	color: orange !important;
	border: solid orange 2px !important;
	outline: none;
	box-shadow: none !important;
}

.inputFieldValue {
	min-width: 11vw;
	border-radius: 0.2rem;
	border: #8a8a8a solid 0.1vw;
	padding: 0.1rem 0.2rem 0.1rem 0.2rem;
	width: 5rem;
	font-family: var(--def-font);
	font-size: var(--font-size);
	text-align: right;
}

.cell-container {
	border: #010621 solid 0.1vw;
}

.riskUtilTr td {
	text-align: left;
	padding-left: 0.75rem !important;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.riskConfigDashboard {
	margin-top: 3rem;
}

.searchArea {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding: 0 0 0 0.8rem;
}

.treeSection {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.risk-config-button-grp {
	height: 8vh;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.risk-hier-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.submit-container {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.toggleDivHFTOn {
	justify-content: flex-start !important;
	padding-left: 4vh;
}

.header-btn {
	width: 100%;
}

.header-btns {
	width: 100%;
	display: flex;
	flex-direction: row;
}

.btn {
	text-align: center;
}

.noteText {
	display: flex;
	flex-direction: row;
	justify-content: left;
	margin-left: 1vw;
	padding: 0vw;
}

.risk-configV2-container {
	background-color: #f0f3f2;
	height: 100%;
	width: 100%;
	position: relative;
	display: grid;
	grid-template-columns: 20% 80%;
	grid-template-rows: 8% 92%;
}

.header-btn-sub {
	display: flex;
	flex-direction: row;
	position: relative;
	height: 100%;
	width: 100%;
}
