.matching-results-container {
  font-family: var(--def-font);
  display: flex;
  width: 100%;
  height: 95%;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
}

.drop-copy-trades {
  height: 95%;
  margin: auto 0;
  display: flex;
  width: 48%;
  border-radius: 1vh;
  flex-direction: column;
  border: 1px solid #cecece;
}

.matching-results-container .database-trades {
  height: 95%;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  width: 48%;
  border-radius: 1vh;
  border: 1px solid #cecece;
}
.matching-results-container .heading-container {
  display: flex;
  height: 5%;
  font-size: 1.8vh;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.matching-results-container .main-heading {
  margin: 0;

  margin-left: 1vw;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  font-weight: 600;
}
.heading-container .heading-info {
  margin: 0;

  margin-right: 1vw;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
}
.matching-results-container .misc-line {
  width: 100%;
  margin: 0vh 0 1vh 0;
  border-bottom: 1px solid #cecece;
}

.missing-trades-container {
  display: flex;
  font-family: var(--def-font);
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.missing-trades-container .database-trades {
  height: 100%;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  border-radius: 1vh;
  /* border: 1px solid #cecece; */
}
.missing-trades-container .heading-container {
  display: flex;
  font-size: 1.8vh;
  height: 5%;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.missing-trades-container .main-heading {
  margin: 0;
  margin-left: 1vw;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  font-weight: 600;
}
.heading-container .heading-info {
  margin: 0;
  margin-right: 1vw;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
}
.missing-trades-container .misc-line {
  width: 100%;
  margin: 0vh 0 1vh 0;
  border-bottom: 1px solid #cecece;
}

.drop-copy-content {
  height: 90%;
  width: 100%;
  overflow-x: scroll;

  /* overflow: hidden; */
}
.matching-results-container .database-content {
  height: 90%;
  width: 100%;
  overflow-x: scroll;

  /* overflow: hidden; */
}
.missing-trades-container .database-content {
  height: 90%;
  max-width: 100%;
  margin: 0 auto;
  overflow-x: scroll;

  /* overflow: hidden; */
}
.matching-results-container .drop-copy-content .content-table {
  overflow: hidden;
  height: 99%;
  min-width: max-content;
}
.matching-results-container .database-content .content-table {
  overflow: hidden;
  height: 99%;
  width: max-content;
}
.missing-trades-container .database-content .content-table {
  overflow: hidden;
  height: 99%;
  width: max-content;
  /* margin-left: 1vw; */
}

.missing-trades-container .selection-container {
  width: 100%;
}
.missing-trades-container .selection-container p {
  margin: 0;
  margin-left: 1vw;
}
.missing-trades-container .selection-container span {
  margin: 0.1vh 0.2vw 0.1vh 0.2vw !important;
}
.missing-trades-container .selection-container img {
  margin: 0;
  filter: invert(36%) sepia(74%) saturate(4903%) hue-rotate(190deg)
    brightness(94%) contrast(101%);
  aspect-ratio: 1;
  height: 1.8vh;
}
.missing-trades-container .selection-body {
  display: none;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1vh;

  justify-content: space-between;
}
.selection-body .selection-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.missing-trades-container .show-buttons {
  display: flex;
}

.missing-trades-container .selection-right span {
  color: red;
}

.database-content .content-table .no-padding {
  padding: 0 !important;
}

.database-content .content-table th {
  z-index: 2;
}

.ops-tradematch-tabs .successful div div form div p {
  color: green;
}
.ops-tradematch-tabs .failed div div form div p {
  margin-top: 2vh;
  color: red;
}

.ops-tradematch-tabs .popupPadding .popup {
  min-height: fit-content;
  min-width: fit-content;
  max-height: 28vh;
  max-width: 22vw;
}
.ops-tradematch-tabs .popupPadding .popup .create_2 {
  margin-top: 2vh;
}
.ops-tradematch-tabs .popupPadding .popup .create_2 form .buttons {
  margin: auto;
  width: 80% !important;
}
.ops-tradematch-tabs .popupPadding .popup .create_2 form .buttons .popUpOK {
  min-width: 90% !important;
  margin: auto;
  /* padding: 20px; */
}

.content-table .planeDropDown {
  background-color: #ff6666;
}

.ops-tradematch-tabs .center-loader {
  width: 120px !important;
  margin: auto;
}
