@import url('../../../global.css');
@import url('../../../assets/css/genTableCss.css');

.ops-main-container {
  height: 88vh;
  width: 96vw;
  background-color: white;
  margin: auto;
  border-radius: 1vh;
  border: 1px solid #cecece;
  display: flex;
  flex-direction: column;
}

.ops-heartbeat-container {
  height: 83vh;
  width: 96vw;
  /* border: 1px solid #aeaeae; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.ops-heartbeat-location {
  height: 78vh;
  width: 23vw;
  border: 1px solid #cecece;
  border-radius: 1vh;
  overflow-y: scroll;
}
.ops-heartbeat-location-tables {
  height: 78vh;
  width: 67vw;
  border: 1px solid #cecece;
  border-radius: 1vh;
  overflow: hidden;
}
.ops-td-hd {
  height: 7vh;
  font-family: var(--def-font);
  font-size: var(--font-size);
  font-weight: 500;
  background-color: #f4f4f4;
  /* white-space: nowrap; */
  text-align: center;
  border: 0.5px solid #cecece !important;
  /* padding-left: 1vw; */
}

.ops-td-text {
  font-family: var(--def-font);
  font-size: var(--font-size);
  color: #171725;
  text-align: center;
  white-space: nowrap;
  padding: 0 1vw 0 1vw;
  border: 0.5px solid #cecece !important;
}
.application-schedule-ops {
  display: flex;
  height: 73vh;
  width: 67vw;
  /* background-color: aqua; */
}

.app-sch-td {
  border: 1px solid #cecece !important;
  height: 6vh;
  font-family: var(--def-font);
  font-size: var(--font-size);
  text-align: left;
  padding-left: 1vw;
  padding-right: 1vw;
  white-space: nowrap;
}

.ops-head-ta-th-td {
  height: 6vh;
  font-family: var(--def-font);
  font-size: var(--font-size);
  /* border: 1px solid #cecece !important; */
  background-color: #f4f4f4;
  white-space: nowrap;
  text-align: left;
  padding-left: 1vw;
}

.app-sch-tr:nth-child(even) {
  background-color: #f8fdff;
}

.application-ops {
  display: flex;
  flex-direction: column;
  height: 73vh;
  width: 67vw;
}

.ops-usermaintanance {
  height: 88vh;
  width: 96vw;
}

.ops-usermain-s1 {
  height: 8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
}

/*Data Validation Styles*/

.ops-datavalidation-container {
  font-family: var(--def-font);
  height: 83vh;
  width: 96vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.ops-datavalidation-location {
  height: 78vh;
  width: 31vw;
  border: 1px solid #cecece;
  border-radius: 1vh;
  overflow: hidden;
  margin: 0 1vw 0 1vw;
}

.location-header {
  font-size: 0.8vw !important;
  border-bottom: 1px #cecece solid;
  padding-bottom: 5px;
  height: 6vh !important;
  display: flex;
  flex-direction: row;
  align-items: center !important;
  width: 98%;
  margin: auto;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 1vh;
}

.location-header-left {
  margin-left: 10px;
  font-weight: 700;
}

.location-header p {
  text-align: left;
  margin: 0;
}

.location-header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.validation-dropdown {
  overflow-y: scroll;
  height: 71vh;
}

.ops-datavalidation-info {
  display: flex;
  height: 78vh;
  margin: 0 1vw 0 1vw;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.ops-datavalidation-node-info {
  border: 1px solid #cecece;
  border-radius: 1vh;
  width: 67vw;
  overflow: hidden;
  height: 33%;
}

.ops-datavalidation-node-results {
  height: 60%;
  width: 67vw;
  overflow-y: scroll;
}

.validator-info,
.no-info {
  margin-left: 1vw;
  margin-top: 1vh;
}

.validator-info li {
  margin-bottom: 0.5vh;
}

.ops-datavalidation-info .no-info {
  margin-left: 1vw;
  margin-top: 1vh;
}

/*Trade Match Styles*/
.ops-tradematch-container {
  font-family: var(--def-font);
  height: 83vh;
  width: 96vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #cecece;
  justify-content: space-evenly;
  background-color: #f4f4f4;
}

.ops-tradematch-buttons {
  height: fit-content;
  width: 94vw;
  margin: 0 auto;
  display: flex;
}
.ops-tradematch-tabs {
  height: 70vh;
  width: 94vw;
  background-color: white;
  margin: 0 auto;
  border-radius: 1vh;
  border: 1px solid #cecece;
  display: flex;
  flex-direction: column;
}
.ops-tradematch-tabs .tabs-container {
  height: 4vh;
}
.ops-tradematch-tabs {
  height: 74vh;
  width: 100%;
}
