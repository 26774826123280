@import '../../../../assets/scss/variable.scss';
@import '../../../../assets/scss/mixins.scss';
.top_header {
  padding-top: 10px;
  background: ghostwhite;
  z-index: 99;
  .headerBox {
    height: 66px;
    background: #1c76b3;
    box-shadow: 0px 3px 6px #00000066;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
  }
  .heading {
    h1 {
      text-align: left;
      letter-spacing: 0px;
      color: $white;
      font-size: 1.25em;
      margin-bottom: 0;
    }
  }
  .preview {
    line-height: 19px;
    font-size: 1.05em;
    letter-spacing: 0px;
    color: $white;
    display: flex;
    @include breakpoint(lgdesktop) {
      font-size: 1em;
    }
    .icon {
      margin-right: 10px;
    }
    p {
      margin-bottom: 0;
      cursor: pointer;
    }
  }
}
