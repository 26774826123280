@import url('../../../global.css');
.view-data-container {

}

.view-data-title {
  background-color: #f4f4f4;
  height: 5vh;
  display: flex;
  border: 1px solid #cecece;
  border-radius: 0.5vh 0.5vh 0 0;
  border-bottom: none;
}

.view-data-title-text {
  color: #171725;
  font-family: var(--def-font);
  font-size: 1.8vh;
  margin: auto 0 auto 1.2vh;
  font-weight: 600;
}

.view-data-table-table {
  border: 1px solid #cecece;
  border-collapse: collapse;
  width: 100%;
  padding-right: 1vw;
}

.view-data-table-td {
  border: 1px solid #cecece;
  border-collapse: collapse;
  border-bottom: none;
  text-align: left;
}
