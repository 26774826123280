@import url('../../../../global.css');

.viewOpsUser-container {
  height: 80vh;
  width: 60vw;
}

.viewOpsUser-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-color);
  height: 8vh;
}

.viewOpsUser-title-text {
  font-family: var(--def-font);
  color: white;
  font-size: calc(var(--font-size) * 1.4);
  margin: 0 0 0 1vw;
}

.middle-container {
  height: 64vh;
}

.footer-container {
  height: 8vh;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
}

.view-table-thead {
  background-color: #f4f4f4;
}

.view-table-table {
  width: 58vw;
  margin: 1vh auto 1vh auto;
}

.view-table-td {
  border: 1px solid #cecece;
}

.um-view-c1-container {
  display: flex;
  flex-direction: row;
  height: 4vh;
  align-items: center;
  flex-direction: row;
}

.um-view-text-head {
  font-family: var(--def-font);
  font-size: var(--font-size);
  margin: auto 1vw auto 1vw;
  font-weight: bold;
}

.um-view-text-body {
  font-family: var(--def-font);
  font-size: var(--font-size);
}

.view-table-title-tableheader {
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 1.2);
  padding: 1vh 1vw 1vh 1vw;
}

.viewOpsUser-middle-container {
  overflow-y: scroll;
  height: 64vh;
}
