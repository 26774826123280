@import url('../../../../global.css');

.master-temp-main-cont {
  height: 80vh;
  width: 50vw;
}

.master-temp-main-head {
  height: 8vh;
  width: 50vw;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: row;
}

.master-temp-main-head-text {
  font-family: var(--def-font);
  color: white;
  margin: auto auto auto 1vw;
  font-size: calc(var(--font-size) * 1.3);
}

.master-temp-main-foot {
  height: 8vh;
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  background-color: #f5f5f5;
}

.master-temp-main-body {
  height: 64vh;
  width: 50vw;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 12vh 28vh 10vh;
}
