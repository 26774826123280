.button-div {
  text-align: center;
  margin-left: 0.75vw;
}

.popup-div {
  position: absolute;
  top: 5vh;
  /* bottom: 10vh; */
  right: 0vw;
  z-index: 999 !important;
  background-color: white;
  /* display: inline-block; */
  width: 9vw;
  height: 27vh;
  /* margin: 0 0.5rem 0 0.5rem; */
  border: solid #bec0bf 1px !important;
  border-radius: 5px;
}

.title-div {
  width: 100%;
  height: 9.75vh;
  color: black;
  /* background-color: #067bd8; */
  display: flex;
  align-items: center;
  justify-content: left;
  font: normal normal 13px/21px Roboto;
  font-weight: 450;
  border-bottom: solid #bec0bf 1px !important;
}

.title-left-div {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: top;
  justify-content: left;
  font: normal normal 13px/17px Roboto;
  font-weight: 300;
  padding-left: 0.2vw;
  color: black;
  margin-top: 1.5vh;
}

.title-right-div {
  width: 50%;
  height: 100%;
  /* display: flex; */
  /* align-items: center; */
  /* text-align: right; */
  justify-content: right;
  font: normal normal 13px/17px Roboto;
  font-weight: 300;
  padding-right: 0.2vw;
  color: black;
}

.input {
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  width: 4vw;
  height: 4vh;
}

.lower-div {
  /* display: flex; */
  display: inline-block;
  align-items: center;
  justify-content: left;
  font: normal normal 13px/17px Roboto;
  font-weight: 300;
  padding-left: 0.25vw;
  color: black;
  overflow-y: auto;
}

.risk-object-text-div {
  margin-top: 1vh;
  margin-bottom: 0.75vh;
}

.risk-object-div {
  margin-top: 0.5vh;
  margin-left: 0.25vw;
}

.checkbox {
  height: 20px;
  width: 20px;
}
