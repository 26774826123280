@import '../../../../assets/scss/mixins.scss';
@import '../../../../assets/scss/variable.scss';
@import url('../../../../global.css');

.step_4 {
  .head-cont {
    background-color: #f4f4f4;
    border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
    display: flex;
    align-items: center;
    padding-left: 3vh;
  }
  .heading {
    height: fit-content;
    font-weight: bold;
    margin: 1vh;
    font-family: 'Roboto', sans-serif;
    font-size: 2.4vh;
    font-weight: bold;
    flex-grow: 1;
  }
  .icon {
    margin: 1vh 1vh 1vh 0;
    height: 3.5vh;
    width: 3.5vh;
  }
  .is-written-equation {
    height: 3vh;
    display: flex;
    align-items: center;
    margin-right: 3vw;
    margin-top: auto;
  }
  label {
    font-family: $roboto;
    font-size: 2.1vh;
    font-weight: bold;
  }
  input {
    margin-top: 4.5px;
    height: 1rem !important;
    width: 1rem !important;
    margin-left: -1.5rem !important;
  }
}

.select-ex-algo {
  background-color: white;
  padding: 1rem;

  .ex-algo-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .multiDropdown {
      padding: 0 14px 0 0;
      .finalValueShow {
        margin: 0 14px !important;
        text-align: center;
      }
    }
  }
  .toggle {
    height: 100%;
    width: 100%;
    // border: 1px solid green;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    position: relative;

    .switch {
      display: flex;

      .custom-control-label {
        width: 0 !important;
      }

      .switch__label {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #fff;
        border: 2px solid #498fff;
        border-radius: 15px;
        transition: background-color 0.25s ease;
        cursor: pointer;

        &::after {
          content: '';
          display: block;
          box-sizing: border-box;
          position: absolute;
          top: -3px;
          left: -3px;
          width: 22px;
          height: 22px;
          background: #dcdcdd;
          border-radius: 15px;
          transition: left 0.25s ease, background-color 0.25s ease;
        }
      }
    }
    .toggle-dropdown {
      margin-left: 4px;
    }
  }
  .leg_1 {
    background: $white;
    border: 0.6000000238418579px solid #97999f9f;
    border-radius: 4px;
    padding: 2vh;
    position: relative;

    .leg-head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .leg-id {
        font-family: var(--def-font);
        font-size: calc(var(--font-size) * 1.5);
        line-height: 1rem;
        letter-spacing: 0px;
        font-weight: 600;
      }
      .button-cont {
        display: flex;
        height: 4.5vh;
      }
    }
    .hide {
      display: none;
    }
    .closeEnty.mkt {
      box-shadow: none !important;
      background-color: white !important;
      img {
        height: 16px;
        width: 16px;
      }
    }
    .closeEnty {
      cursor: pointer;
      font-size: 2.5rem;
      background: #f7f7f7;
      box-shadow: 0px 0px 8px 2px #00000029;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 15px;
      padding: 0 7px;
      height: 40px;
      width: 40px;
      position: relative !important;
      top: 0 !important;
      right: 0 !important;
      @include breakpoint(lgdesktop) {
        height: 35px;
        width: 35px;
      }
      img {
        @include breakpoint(lgdesktop) {
          height: 20px;
          width: 20px;
        }
      }
    }
    .mark {
      position: absolute;
      top: -24px;
      left: 0;
      background-color: transparent !important;
      .leg {
        background: #ff5050 !important;
        border-radius: 10px;
        padding: 7px;
        color: #fff;
        font: normal normal 13px/19px Roboto;
        letter-spacing: 0px;
        p {
          margin-bottom: 0;
        }
      }
      .arrow-down {
        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 11px solid #ff5050;
        margin-left: 20px;
      }
    }
    .close {
      position: absolute;
      right: -7px;
      top: -13px;
      width: 30px;
      height: 30px;
      background: #f7f7f7;
      box-shadow: $dark-btm-shadow;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 1;
    }
    .main {
      display: flex;
      align-items: center;
    }
    .inputs {
      margin: 0 0 1rem 0;

      .multiDropdown {
        .finalValueShow {
          margin: 0 14px;
        }
      }

      select {
        background: #ffffff;
        box-shadow: $dark-btm-shadow;
        border-radius: 10px;
        border: none;
        overflow: hidden !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inherit;
      }
      .form-control {
        font-family: var(--def-font);
        font-size: var(--font-size);
        line-height: 1rem;
        letter-spacing: 0px;
        font-weight: 400;
        width: fit-content;
        height: fit-content;
        background: #ffffff;
        box-shadow: $dark-btm-shadow;
        border-radius: 10px;
        display: inline;
        border: none;
        text-align: left;
      }
      .second {
        .group {
          label {
            margin-left: 30px;
            margin-right: 30px;
            font-size: 2.4vh;
            line-height: 19px;
            letter-spacing: 0px;
            color: #000000;
          }
        }
        .circle {
          width: 30px;
          height: 30px;
          background: #f7f7f7;
          box-shadow: $dark-btm-shadow;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px;
          cursor: pointer;
          img {
            height: 13px;
            width: 13px;
          }
        }
      }
    }
    .add {
      text-align: left;
      line-height: 19px;
      font-size: 2.4vh;
      letter-spacing: 0px;
      color: #7992ff;
      display: inline-block;
      text-align: left;
      width: 100%;
      @include breakpoint(lgdesktop) {
        button {
          font-size: var(--font-size);
          font-family: var(--def-font);
        }

        button:focus {
          border-radius: 20px;
        }
      }
      .disbaleClick {
        background-color: white;
        p {
          color: #9fadd6 !important;
        }
        img {
          filter: invert(69%) sepia(13%) saturate(648%) hue-rotate(187deg)
            brightness(96%) contrast(96%);
        }
      }
      .contentPadding {
        border-radius: 20px;
      }
      .centerContent:hover {
        background-color: #eaf6ff;
        p {
          color: #0078d7 !important;
        }
        img {
          filter: invert(35%) sepia(79%) saturate(1741%) hue-rotate(186deg)
            brightness(85%) contrast(103%);
        }
      }
      p {
        margin-bottom: 0;
      }
    }
    .head {
      display: flex;
      align-items: center;
      height: 21px;
      padding-top: 16px;
      .position {
        margin-bottom: 0;
        margin-right: 1.5vh;
        font-weight: 500;
        font-size: 2.1vh;
        font-family: $roboto;
      }
    }
    .condition-head {
      margin-bottom: 0;
      margin-right: 1rem;
      font-weight: 500;
      font-size: var(--font-size);
      font-family: var(--def-font);
    }
    .buy {
      display: flex;
      align-items: center;
      box-shadow: $dark-btm-shadow;
      border: 1px solid #53bf3a;
      border-radius: 4px;
      background: #53bf3a05;
      padding: 5px 0;
      margin: 1rem 0;

      .toggle {
        min-height: auto;
      }
      .position-container {
        padding: 0;
      }
      .button {
        background: #ffffff;
        box-shadow: 0px 3px 6px #00166d29;
        margin-left: 15px;
        margin-right: 40px;
        height: 50px;
        overflow: hidden;
        border-radius: 2px;
        @include breakpoint(lgdesktop) {
          height: 35px;
          display: flex;
          align-items: center;
        }
        .active {
          background: #53bf3a;
          color: #fff;
          font-size: 2.4vh;
          @include breakpoint(lgdesktop) {
            font-size: 1.9vh !important;
          }
        }
        span {
          line-height: 47px;
          padding: 18px 10px;
          font-size: 1.9vh;
          @include breakpoint(lgdesktop) {
            font-size: 1.9vh;
            padding: 0px 6px;
            display: flex;
          }
        }
      }
      .select {
        select {
          background: #ffffff;
          box-shadow: $dark-btm-shadow;
          border-radius: 10px;
          border: none;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden !important;
          display: inherit;
        }
      }
    }
    .sell {
      border: 1px solid #ff5050;
      background: #ff505005;
      .button {
        .active {
          background: #ff5050 !important;
          color: #fff;
          font-size: 2.4vh;
        }
        span {
          line-height: 47px;
          padding: 18px 10px;
          @include breakpoint(lgdesktop) {
            font-size: 1.9vh;
            padding: 0px 6px;
            display: flex;
          }
        }
      }
    }
    .toggle {
      display: flex;
      position: relative;
      align-items: center;

      .multiDropdown {
        padding: 0px 0 0 15px;
      }

      p {
        text-align: left;
        font-family: var(--def-font);
        font-size: var(--font-size);
        letter-spacing: 0px;
        color: #000000;
      }
    }
  }
}

.leg_1 .first,
.second,
.inputs {
  display: flex;
  align-items: center;
}

.buy .switch__label2 {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border: 2px solid #498fff;
  border-radius: 15px;
  transition: background-color 0.25s ease;
  cursor: pointer;
}

.buy .switch__label2::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: -3px;
  left: -3px;
  width: 22px;
  height: 22px;
  background: #dcdcdd;
  border-radius: 15px;
  transition: left 0.25s ease, background-color 0.25s ease;
}

.buy .switch__checkbox2:checked + .switch__label2 {
  background-color: #498fff;
}

.buy .switch__checkbox2:checked + .switch__label2::after {
  left: 20px;
  background-color: #dcdcdd;
}

.buy .switch__label3 {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border: 2px solid #498fff;
  border-radius: 15px;
  transition: background-color 0.25s ease;
  cursor: pointer;
}

.buy .switch__label3::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: -3px;
  left: -3px;
  width: 22px;
  height: 22px;
  background: #dcdcdd;
  border-radius: 15px;
  transition: left 0.25s ease, background-color 0.25s ease;
}

.buy .switch__checkbox3:checked + .switch__label3 {
  background-color: #498fff;
}

.buy .switch__checkbox3:checked + .switch__label3::after {
  left: 20px;
  background-color: #dcdcdd;
}

.ml-10 {
  margin-left: 5%;
}

.add_leg {
  background-color: white;
  padding: 4vh 0;
  .centerContent:focus {
    border-radius: 40px;
  }
}

.tooltip > div.arrow::before {
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 11px solid #ff5050;
}

.cursor {
  cursor: pointer;
}

.closeEnty:not(:disabled):not(.disabled):focus,
.closeEnty:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}

.closeEnty:hover {
  color: #000;
  text-decoration: none;
}

.arrDown {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 9px solid #ff5050;
  margin-left: 25px;
  margin-bottom: 0px;
  &::after {
    margin-bottom: -20px;
  }
}

.tooltip-div {
  height: 30px;
  width: 60px;
  background-color: #ff5050;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 30px;
  border-radius: 5px;
  text-align: center;
}

.tooltip-wrapper {
  margin-left: -20px;
}

.entryCondition {
  .selectedAnswer {
    p {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .condition {
    padding: 0;
    box-shadow: none;
    span {
      display: flex !important;
      align-items: center !important;
      text-transform: capitalize !important;
      font-family: $roboto !important;
      justify-content: space-evenly !important;
      label {
        white-space: nowrap;
        display: none;
      }
      .MuiGrid-container {
        width: 25% !important;
        .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
          box-shadow: 0px 0px 3px #00000029;
          border: 0.6000000238418579px solid #bab9b9;
          border-radius: 4px !important;
          div.MuiInput-formControl {
            border: none !important;
            box-shadow: none !important;
          }
        }
        .MuiFormControl-marginNormal {
          margin-top: 0;
          margin-bottom: 0;
          div {
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          }
        }
      }
    }
  }
}

.tooltiptext {
  width: 60px;
  background-color: #ff5050;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  bottom: 125%;
  left: 50%;
  margin-left: -57px;
  margin-bottom: 7px;
}

#demo {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 9px solid #ff5050;
  margin-left: -30px;
  margin-top: -7px;
}

.step4-overlay {
  margin-left: 160px !important;
  margin-bottom: 17px !important;
  .arrow::before {
    top: 5px !important;
    margin-left: -155px !important;
  }
}

.step-4-info {
  img {
    margin-left: 15px;
  }
}

.tooltiptext.hide {
  display: none !important;
}

#demo.hide {
  display: none !important;
}

.select-ex-algo .toggle {
  min-height: 64px;
}

.multiDropdown {
  position: relative;
}

.mainWrapperDropdown li ul:empty {
  display: none;
}

.addBlueBtn,
.addCloseBtn {
  height: 38px !important;
  cursor: pointer;
}
.actionBtn .addBlueBtn:nth-of-type(1) {
  position: relative;
  top: 5px;
}
.actionBtn .addBlueBtn:nth-of-type(2) {
  width: 23px !important;
  padding: 5px;
  margin: 0;
}

.condition .square_off .head p {
  margin-top: -4px;
}

.condition .square_off {
  min-height: 53px;
}
.error-input.toggle-dropdown {
  input {
    border: 1px solid red !important;
  }
}

.body-container-sbecbd {
  margin: 2rem 0 0 0;
}
