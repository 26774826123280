@import '../../../assets/scss/mixins.scss';
@import '../../../assets/scss/variable.scss';

.add-alert-wrapper {
  margin-bottom: 0;
  margin-top: 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;

  margin-right: 0.875rem;

  width: 3.44rem;
  height: 2.75rem;

  .add-rule-wrapper {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    height: 100%;
    width: 100%;

    .text-left {
      width: 100%;
      // margin-right: 1vw !important;
      height: 100%;
      .toogledropdown .addCheckBox form .customCheckbox .form-check{
        #formBasicCheckbox{
          width: 3vh !important;
          height: 3vh !important;
        }
        .form-check-label{
          margin-left: .6vh;
        }
      }
    }

  }

  .add-rule-btn {
    height: 100% !important;
    background: $white;
    border: 1px solid #2f78d7;
    border-radius: 5px;
    padding: 0.5rem 0.9rem 0.5rem 0.9rem;
    font: normal normal 700 20px/27px Segoe UI;
    color: #0078d7;
    @include breakpoint(lgdesktop) {
      height: 100% !important;
      font-size: $twelve;
      width: 100%;
    }
    span {
      font-size: 34px;
      margin-right: 7px;
      @include breakpoint(lgdesktop) {
        font-size: 28px;
      }
    }
    p {
      margin-bottom: -6px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  .dropdown-rule {
    z-index: 9999;
    margin-top: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    position: absolute;
    background: $white !important;
    border: 1px solid #cecece;
    width: 18rem;
    height: 25rem;
    border-radius: 5px;
    padding: 3px 0px;

    // @include breakpoint(lgdesktop) {
    //   width: ;
    // }

    .filter-wrapper {
      box-shadow: $dark-btm-shadow;
      padding: 0;
      // height: 0px;
      // margin-bottom: 40px;
      color: #817f7f;
      line-height: 12px;
      font: normal normal normal 16px/20px Segoe UI;
      @include breakpoint(lgdesktop) {
        margin-bottom: 25px;
        margin-top: 15px;
        font: normal normal normal 12px/20px Segoe UI;
        height: 0px;
        align-items: center;
        display: flex;
      }

      input {
        width: 100%;
        border: none;
      }
    }

    .searchBox {
      margin: 0.5rem 0.5rem;
      width: 16.25rem;
      height: 1.8rem !important;
      border: 0.3px solid #97999f;
      border-radius: 5px;
    }

    .containerer {
      display: block;
      position: relative;
      padding-left: 30px;
      margin-bottom: 10px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      user-select: none;
      p {
        margin-bottom: 0;
        color: $black;
        font: normal normal normal 16px/20px Segoe UI;
        letter-spacing: 0px;
        @include breakpoint(lgdesktop) {
          font: normal normal normal 13px/16px Segoe UI;
        }
      }
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        border: 2px solid #000000cc;
      }
      &.checkmark:after {
        left: 5px;
        top: 2px;
        width: 7px;
        height: 10px;
        border: solid #707070;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}

.addCheckBox {
  height: 85%;
  overflow-y: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-bottom: 1px solid #cecece;
}

.alert-notify {
  .ruleAction {
    display: flex;
  }

  .applyButton {
    margin-top: 0.6rem;
    margin-left: auto;
    margin-right: 0.5rem;
    height: 3rem;
  }
}
