@import url('../../../../../global.css');

.SubtitleInfo-container {
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.SubtitleInfo-label {
  font-family: var(--def-font);
  font-size: var(--font-size);
  line-height: 1rem;
  letter-spacing: 0px;
  font-weight: 400;
  margin: 0.5rem 1rem 0.5rem 0rem;
}

.SubtitleInfo-icon {
  height: 1rem;
  width: 1rem;
}
