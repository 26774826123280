@import '../../../assets/scss/mixins.scss';
@import '../../../assets/scss/variable.scss';
@import url('../../../global.css');

.position-book {
  background-color: white;
  border: 1px solid #cecece;
  border-top: 0px;
  .position-book-search-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    margin: 7px, 0px;
    .position-book-left {
      height: 44px;
      width: 92.25px;
    }
    .position-book-search {
      display: flex;
      flex-direction: row;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #dadada;
      border-radius: 4px;
      height: 30px;
      padding: 2px;
      width: 340px;
      margin: 5px 0;

      // Input Placeholder Color Start
      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #d1d0d0;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #d1d0d0;
      }
      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #d1d0d0;
      }
      // Input Placeholder Color End

      .input-group .form-control {
        border: none !important;
        box-shadow: none;
        border-radius: 0px;
        height: 20px;
        width: fit-content;
        color: #d1d0d0;
      }
      img {
        margin-left: 5px;
      }
    }
    .total{
      color: #1976d2 !important;
      background-color: rgba(25, 118, 210, 0.08);
    }
    .position-book-refresh {
      border: none !important;
      //margin-right: 10px;
      //margin-top: 5px;
      margin: 10px;
      min-width: 100px;
      height: 4vh !important;
      img {
        width: 2.5vh;
        height: 2.5vh;
        margin-right: 5px;
      }
      button {
        border: none !important;
        padding: 0px 10px 0px 5px;
        border-radius: 4px;
        outline-color: rgb(121, 196, 247) !important;
        background-color: rgb(121, 196, 247);
        color: white;
        font-size: 14px;
        font-weight: 500;
        height: 4vh;
        &:hover {
          background-color: #1553fd;
          font-weight: 600;
        }
        &:focus {
          background-color: #1553fd;
          font-weight: 600;
        }
        &:active {
          background-color: #6b91f8;
          font-weight: 600;
        }
      }
    }
  }

  // Scroll Bar Hide
  // .position-book-table::-webkit-scrollbar {
  //   display: none !important;
  // }
  // .position-book-table{
  //   -ms-overflow-style: none;  /* IE and Edge */
  //   scrollbar-width: none;  /* Firefox */
  // }

  .position-book-table {
    max-height: 500px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    overflow-x: scroll;

    /* Set header to stick to the top of the container. */
    thead tr th {
      position: sticky;
      z-index: 0;
      top: 0;
      border-radius: 0 !important;
    }
    tfoot th {
      text-align: right !important;
    }
    tfoot tr {
      height: 37px;
      background: #f4f4f4 0% 0% no-repeat padding-box;
      border-radius: none !important;

      th {
        position: sticky;
        z-index: 0;
        bottom: 0;
        border-radius: 0 !important;
        border: none !important;
        vertical-align: middle;
        padding: 2px 0.75rem;
        text-transform: none;
        background-color: #f4f4f4 !important;
        min-height: 40px;
        height: 40px;
        font-weight: 600 !important;
      }

      .position-book-row-green {
        color: rgb(89, 189, 8) !important;
      }

      .position-book-row-red {
        color: rgb(252, 48, 48) !important;
      }
    }
    tr {
      border-radius: 0 !important;

      .position-book-row-green {
        color: rgb(89, 189, 8) !important;
      }

      .position-book-row-red {
        color: rgb(252, 48, 48) !important;
      }
    }
    /* If we use border, we must use table-collapse to avoid a slight movement of the header row */
    table {
      border-collapse: collapse;
    }
    /* Because we must set sticky on th, we have to apply background styles here rather than on thead */
    
    .aggregated-table {
      position: sticky;
      z-index: 0;
      bottom: 0;
      thead {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        outline: 0;
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;
      }
      tr {
        background: #f4f4f4 0% 0% no-repeat padding-box;
        border-radius: none !important;
        height: 37px;
        color: black !important;
      }
      th {
        border: none !important;
        vertical-align: middle;
        padding: 2px 0.75rem;
        text-transform: none;
        text-align: right !important;
        background-color: #f4f4f4 !important;
        min-height: 40px;
        height: 40px;
        font-weight: 600;
      }
    }

   

    .netPositionFocusedRow {
      background-color: orange !important;
      position: absolute;
      bottom: 0;
      font-weight: 600;
      color: black !important;
      position: sticky;
      z-index: 0;
      bottom: 0;
    }

    .netPositionRow {
      height: 35px !important;
      td {
        font: var(--unnamed-font-style-normal) normal
          var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) / 11px
          Roboto;
        fletter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-000000);
        text-align: left;
        font: normal normal normal 1.5vh/1.5vh Roboto;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        padding: 5px 10px;
        border: 1px solid #dadada;
      }
    }

    thead {
      .netposition_exchange,
      .netposition_userid,
      .netposition_segment,
      .netposition_update_time,
      .netposition_account_id,
      .netposition_account_name,
      .netposition_instr_type,
      .netposition_market_type,
      .netposition_symbol,
      .netposition_expiry,
      .netposition_strike_price,
      .netposition_option_type,
      .netposition_net_position,
      .netposition_mtm,
      .netposition_net_value,
      .netposition_buy_qty,
      .netposition_buy_value,
      .netposition_buy_avg_price,
      .netposition_sell_qty,
      .netposition_sell_value,
      .netposition_sell_avg_price,
      .netposition_ltp {
        padding-top: 10px;
        vertical-align: top;
      }
    }

    .netposition_exchange {
      min-width: 60px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_userid {
      min-width: 70px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_segment {
      min-width: 70px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_update_time {
      min-width: 100px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_account_id {
      min-width: 90px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_account_name {
      min-width: 100px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_instr_type {
      min-width: 100px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_market_type {
      min-width: 50px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_symbol {
      min-width: 120px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_expiry {
      min-width: 80px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_strike_price {
      min-width: 90px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_option_type {
      min-width: 70px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_net_position {
      min-width: 70px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_mtm {
      min-width: 70px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_net_value {
      min-width: 70px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_buy_qty {
      min-width: 100px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_buy_value {
      min-width: 100px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_buy_avg_price {
      min-width: 100px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_sell_qty {
      min-width: 100px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_sell_value {
      min-width: 100px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_sell_avg_price {
      min-width: 100px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }

    .netposition_ltp {
      min-width: 120px;
      font-family: var(--def-font);
      white-space: nowrap;
      vertical-align: middle !important;
      padding-top: 0 !important;
    }
  }
}

.number-aligned {
  text-align: right !important;
  padding-right: 0.5vw !important;
  width: 10vw !important;
  font-family: var(--def-font);
  white-space: nowrap;
  vertical-align: middle !important;
  padding-top: 0 !important;
  font-size: calc(var(--font-size) * 1.2) !important;
}
