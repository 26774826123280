@import url('../../../global.css');

.bellicon {
  height: 3vh;
  width: 3vh;
}

.numberofAlerts {
  position: absolute;
  top: -0.8vh;
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 0.8);
  right: -0.8vh;
  background-color: #ea4242;
  color: white;
  border-radius: 2vh;
  height: 3vh;
  width: 3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
}

.bellIcon-container {
  position: relative;
  margin-left: auto;
  margin-right: 1vw;
  cursor: pointer;
}
