@import url('../../../../../global.css');

.Switch-SB {
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 8px #00166d29;
  padding: 0.1rem;
}

.Switch-SB-s {
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 0.3rem 0.2rem;
  border-radius: var(--border-radius);

  &:focus {
    border: var(--focusBorder);
  }
}

.Switch-SB-s1-label {
  font-family: var(--def-font);
  font-size: var(--font-size);
  line-height: 1rem;
  letter-spacing: 0px;
  font-weight: 400;
  margin: 0.2rem 0.1rem;
}
