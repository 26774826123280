@import '../../../../../assets/scss/variable.scss';
@import '../../../../../assets/scss/mixins.scss';

.view-family-modal {
  .map-Dealer:nth-child(odd){
    background-color: #EDF3FF;
    display: block;
    width: 250px;
  }
  .modal-content {
    overflow-y: auto !important;
    @include breakpoint(lgdesktop) {
      max-height: 620px;
      height: auto;
    }
  }
  .popup .family-modal-form {
    .close-btn {
      margin-top: 105px;
      border: 1px solid #2f78d7;
      color: #2f78d7;
      background: transparent;
      &:hover{ color: #fff;
        background: #2f78d7;}
      @include breakpoint(lgdesktop) {
        margin-top: 40px;
        width: 100px;
        height: 42px;
        font: normal normal bold 12px/31px Roboto;
      }
    }
    label {
      
      font: normal normal bold 14px/21px Roboto !important;
      color: #000000;
    opacity: 1;
    }
    .entities {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      opacity: 1;
      padding: 25px 25px 25px 25px;
      position: relative;
      margin-top: 40px;
      .main {
        display: flex;
        align-items: center;
        .form-field{
          text-align: left;
          font: normal normal bold 16px/21px Segoe UI;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          display: block;
          @include breakpoint(lgdesktop) {
            font: normal normal bold 12px/21px Segoe UI;
          }
        }
        .form-fields {
          text-align: left;
          font: normal normal normal 16px/21px Segoe UI;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          display: block;
          @include breakpoint(lgdesktop) {
            font: normal normal normal 12px/21px Segoe UI;
          }
        }
        .entityTooltip {
          .entityText {
            border-radius: 10px;
            background: #0078d7 0% 0% no-repeat padding-box;
            font: normal normal bold 16px/19px Roboto;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
            width: 82px;
            text-align: center;
            padding: 5px 0;
            position: absolute;
            bottom: 100%;
            @include breakpoint(lgdesktop) {
              font: normal normal bold 12px/16px Roboto;
              width: 65px;
            }
            &.entityText:after {
              content: '';
              position: absolute;
              width: 0;
              height: 0;
              border-left: 3px solid transparent;
              border-right: 3px solid transparent;
              border-top: 9px solid #0078d7;
              margin-left: -30px;
              margin-top: -7px;
              top: 100%;
              left: 0;
              right: 0;
              margin: 0 auto;
            }
          }
        }
      }
      .close {
        position: absolute;
        right: -7px;
        top: -13px;
        width: 30px;
        height: 30px;
        background: #f7f7f7;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 1;
        float: right;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        @include breakpoint(lgdesktop) {
          width: 25px;
          height: 25px;
          img {
            max-width: 50%;
          }
        }
      }
      .heading-title{
        font-weight: 600 !important;
      }
    }
    .add-entity {
      text-align: left;
      font: normal normal bold 16px/31px Roboto;
      letter-spacing: 0px;
      color: #2f78d7;
      opacity: 1;
      cursor: pointer;
      @include breakpoint(lgdesktop) {
        font: normal normal bold 12px/16px Roboto;
      }
    }
  }
}
