@import url('../../../../global.css');

.PillButton-container {
	background-color: var(--primary-color);
	height: 1.5rem;
	width: 1.5rem;
	border-radius: 3vh;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: all 0.3s;
	user-select: none;
}
.PillButton-container:hover {
	opacity: 0.7;
}

.PillButton-container:focus {
	border: 2px solid var(--focus);
}

.PillButton-dis {
	background-color: white;
	cursor: not-allowed;
}

.PillButton-icon {
	height: 0.75rem;
	width: 0.75rem;
}
