@import url('../../../global.css');

.QuickWatch-container {
  background-color: var(--white);
  border-radius: var(--border-radius);
  margin: 0.5rem 0.5rem;
  overflow: auto;
  border: var(--inDdInfieldBorder);
  min-height: 70vh;
}
