:root {
  --def-font: 'Poppins', sans-serif;
  --anim-duration: 0.3s;
}

.lp-header {
  height: 9vh;
  width: 100vw;
  border-radius: 0 0 1vh 1vh;
  box-shadow: 0px 0px 6px #00000029;
  background-color: white;
  display: flex;
  align-items: center;
}

@media (min-width: 480px) {
  .lp-header {
    height: 11vh;
    width: 100vw;
    border-radius: 0 0 1vh 1vh;
    box-shadow: 0px 0px 6px #00000029;
    background-color: white;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 6px #00000029;
  }
}

.sideButtons {
  display: flex;
  flex-direction: column;
  margin: 5vw;
}

.side-item {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  padding: 5vw;
  border-bottom: 1px solid rgb(64, 162, 229, 0.3);
}

.navButtons {
  display: none;
}

@media (min-width: 480px) {
  .navButtons {
    display: flex;
    flex-grow: 6;
    flex-direction: row;
  }
  .nav-item {
    cursor: pointer;
    font-family: var(--def-font);
    font-size: 0.8rem;
    margin-right: 2vw;
  }
}

.sidemenu-logo {
  flex-grow: 1;
  height: 4.5vh;
  margin: auto auto auto auto;
  margin-left: 5px;
}

@media (min-width: 480px) {
  .sidemenu-logo {
    flex-grow: 3;
    height: 6vh;
    margin: auto auto auto 1vw;
    display: none;
  }
}

.application-logo {
  flex-grow: 3;
  height: 5vh;
  margin: auto auto auto auto;
  margin-right: 100px;
}

.application-logo-drawer {
  width: 30vw;
}

@media (min-width: 480px) {
  .application-logo {
    flex-grow: 3;
    height: 6vh;
    margin: auto auto auto 1vw;
  }
}

.lp-scroll-area {
  height: 89vh;
  width: 100vw;
  background-color: white;
}

.bgImage-lp-s1 {
  position: absolute;
  top: 0;
  height: 70vh;
  width: 100vw;
}

.lp-s1 {
  background-color: #f7fcff;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.lp-sec1-title {
  font-family: var(--def-font);
  margin-top: 10vh;
  color: #0078d7;
  font-size: 10vw;
  font-weight: 800;
  z-index: 1;
  text-align: center;
}

.orangeText {
  color: #f37c21;
}

.lp-sec1-subtitle {
  font-family: var(--def-font);
  color: #0078d7;
  font-size: 2vh;
  font-weight: 500;
  z-index: 1;
  margin-top: 3.5vh;
  text-align: center;
}

.animButton-back {
  cursor: pointer;
  height: fit-content;
  width: fit-content;
  padding: 0.5vh;
  background-color: #f37c21;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4vh;
  z-index: 1;
  margin-top: 3.5vh;
}

.animButton-back2 {
  cursor: pointer;
  height: fit-content;
  width: fit-content;
  padding: 0.5vh 2vh;
  background-color: #0078d7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 3vh;
  z-index: 1;
  margin-top: 3.5vh;
}

.animButton-back:hover > .arrow-circle {
  visibility: visible;
  opacity: 1;
  transition: all var(--anim-duration);
  margin-left: 1vh;
}

.arrow-icon {
  height: 4vh;
  width: 4vh;
  transform: rotate(180deg);
  margin: 1vh;
}

.arrow-circle {
  background-color: white;
  border-radius: 5vh;

  /* visibility: collapse;
  opacity: 0;
  transition: all var(--anim-duration); */
}

.animBText {
  color: white;
  font-family: var(--def-font);
  font-size: 2vh;
  margin: 1vh 1.5vh;
}

@media (min-width: 600px) {
  .lp-sec1-title {
    font-family: var(--def-font);
    margin-top: 10vh;
    color: #0078d7;
    font-size: 4rem;
    font-weight: 800;
    z-index: 1;
  }
}
