@import '../../../../../assets/scss/mixins.scss';
.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
  width: 100%;
  height: 34px;
  // background: #ffffff;
  border-radius: 10px;
  display: inline;
  // border: none;
  font-size: 0.85em;
}

.multiDropdown .mainWrapperDropdown {
  margin-left: 12px;
}

.condition {
  .circle {
    .closeEnty {
      cursor: pointer;
      font-size: 2.5rem;
      background: #f7f7f7;
      box-shadow: 0px 0px 8px 2px #00000029;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 15px;
      padding: 0 7px;
      height: 40px;
      width: 40px;
      position: relative !important;
      top: 0 !important;
      right: 0 !important;
      @include breakpoint(lgdesktop) {
        height: 32px;
        width: 32px;
      }
      img {
        @include breakpoint(lgdesktop) {
          height: 14px;
          width: 14px;
        }
      }
    }
  }
}

.ops {
  width: 100px;
  margin-right: 35px;
}

.first {
  width: 200px;
}
