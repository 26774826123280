@import '../../assets/scss/mixins.scss';
@import '../../assets/scss/variable.scss';
@import url('../../global.css');

.alert-notify {
  .over-table {
    width: 100% !important;
    .message {
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      max-width: 25vw;
      text-align: left;
      word-wrap: break-word;
      @include breakpoint(lgdesktop) {
        max-width: 165px;
      }
      @include breakpoint(mldesktop) {
        max-width: 115px;
      }
    }
    .col.table_head {
      span {
        @include breakpoint(lgdesktop) {
          font-size: 0.875em;
        }
      }
    }
    .col {
      text-align: center;
      color: #070707;
      div {
        @include breakpoint(lgdesktop) {
          font-size: 0.8em;
        }
      }
    }
  }
  .flextab {
    border: 0;
    box-shadow: 0px 3px 6px #00000029;
    padding: 0 20px;
    position: fixed;
    height: 55px;
    width: 100%;
    .nav-link {
      border-bottom: 3px solid transparent;
      display: flex;
      padding: 12px 20px;
      color: rgb(150, 150, 150);
      font-size: 1em;
      font-weight: 500;
      display: flex;
      align-items: center;
      @include breakpoint(lgdesktop) {
        font-size: 0.875em;
      }
      span {
        display: block;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        background: #d1daff;
        color: #0022a8;
        text-align: center;
        line-height: 28px;
        margin-right: 10px;
        font-size: 12px;
      }
      &.active {
        border-color: #ff5f15;
        color: #000000;
        span {
          color: #ff5f15;
          background-color: #ffdbce;
        }
      }
      &.pending {
        color: #ff5f15;
        span {
          background: #ffdbce;
          color: #ff5f15;
        }
        &.active {
          border-color: #ff5f15;
        }
      }
    }
  }

  .alert-nav-header {
    .nav-link {
      &:focus-within {
        border-color: #ff5f15;
      }
    }
  }

  .tab-pane {
    padding: 30px;
  }

  .riskTableHead {
    z-index: 999;
    width: 100%;
    display: flex;
    padding: 1.69rem 0.82rem 0.69rem 2.69rem;
    position: fixed;
    top: 55px;
    background: #fff;
  }

  .addrulebtn {
    background: none;
    border: 0;
  }

  .groupDiv {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 62rem;
    border-radius: 5px;
    padding: 15px;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    height: 2.75rem;
    background: #fafafa 0% 0% no-repeat padding-box;
    border: 0.3px solid #707070;
    border-color: #707070;

    button {
      background: #eae8e8;
      box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #c3c3c3;
      border-radius: 10px;
      color: #707070;
      font-size: 14px;
      padding: 7px 40px;
    }
    .fa {
      margin: 0 15px;
      font-size: 20px;
      color: #067bd8;
    }
    .btnchip {
      position: relative;
      border: 1px solid #2f78d7 !important;
      background: #ffffff 0% 0% no-repeat padding-box !important;
      font: normal normal normal 14px/19px Roboto;
      color: #067bd8;
      border-radius: 5px !important;

      .remove {
        position: absolute;
        top: -4px;
        right: -1px;
        width: 1vh;
        height: 1vh;
        border-radius: 12px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s ease-in-out;
      }
      &:hover .remove {
        opacity: 1;
        visibility: visible;
        transition: all 0.2s ease-in-out;
      }
    }
  }
  .ruleAction {
    padding: 0 1.25rem 0.82rem 1.25rem;
    flex: 0 1 auto;

    .markread {
      background: #ffffff 0% 0% no-repeat padding-box;

      box-shadow: 1px 2px 3px #0672c757;
      border: 2px solid #2f78d7;
      border-radius: 5px;
      color: #0078d7;
      font-size: 1em;
      font-weight: 700;
      padding: 5px 15px;
      width: 9rem;
      height: 2.75rem;
      @include breakpoint(lgdesktop) {
        font-size: 0.875em;
      }
      // &:hover {
      //   background: #0022a8;
      //   color: #fff;
      // }
    }

    .trash {
      width: 2.75rem;
      height: 2.75rem;
      box-shadow: 0px 1px 3px #00000029;
      background: #f7f7f7 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 3px #00000029;

      border: 0;
      border-radius: 50%;
      margin-left: 1.2rem;
    }
  }

  .alertTable {
    // padding: 15px 0;
    width: 100%;
    overflow-y: auto !important;
    .btn-primary {
      @include breakpoint(lgdesktop) {
        font-size: 0.875em;
      }
    }
    thead {
      position: sticky;
      top: 0;
      background-color: #f4f4f4;
      height: 3.125rem;
      color: #0078d7;
      font: normal normal 14px/31px Roboto;
    }
    .table thead th:nth-child(1) {
      border-top-left-radius: 0 !important;
      color: #0078d7;
    }
    .table thead th:last-child {
      border-top-right-radius: 0 !important;
      color: #0078d7;
    }
    .thead-row {
      position: sticky;
      top: 0;
      background-color: #f4f4f4 !important;
      font: normal normal 500 14px/31px Roboto;
      z-index: 9999;
      height: 3.13rem;
    }
    .table_head {
      background-color: #f4f4f4 !important;
      color: #0078d7;
      span {
        background-color: #f4f4f4 !important;
        color: #0078d7;
        font-family: var(--def-font);
      }
    }
  }
  .table {
    width: 100%;
    border: 0;
    border-collapse: collapse;
    thead th {
      color: #0078d7;
      text-align: center;
    }
    tbody td {
      // border: 0 !important;
      // border-top: 5px solid #fff !important;
      color: #000;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      vertical-align: middle;
      text-align: center;
      strong {
        font-weight: 600;
      }
      &.center {
        text-align: center;
      }
      #expandPoint {
        color: #0022a8;
        margin-top: 4px;
        cursor: pointer;
      }
      #collapsePoint {
        cursor: pointer;
      }
    }
    .style1 {
      background: #0078d7;
      box-shadow: 0px 3px 6px #00000066;
      border-radius: 5px;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      padding: 5px 15px;
      border: 0;
      min-width: 160px;
      &:hover {
        background: #0022a8;
        color: #fff;
      }
    }
    .style2 {
      background: #ffffff;
      box-shadow: 0px 3px 6px #00000066;
      border-radius: 5px;
      color: #000;
      font-size: 16px;
      font-weight: 700;
      padding: 5px 15px;
      border: 0;
      min-width: 160px;
      &:hover {
        background: #000;
        color: #fff;
      }
    }
    tr.shown {
      border-bottom: 2px solid #fff;
    }
  }
  .alertlist table thead tr th {
    vertical-align: middle;
  }

  .alertlist table thead tr th:nth-child(1) {
    text-align: left !important;
    padding-left: 3.5rem;
  }

  .alertlist table tbody tr td:nth-child(1) {
    text-align: left !important;
    padding-left: 3.5rem;
  }

  .alertlist table tbody tr:nth-child(odd) {
    background-color: #f4fbff;
  }

  .fullDescription {
    width: 100%;
    padding-top: 30px;
    label {
      font-size: 16px;
      color: #0022a8;
      font-weight: 700;
      display: block;
      padding: 0 0 10px;
      text-align: left;
      @include breakpoint(lgdesktop) {
        font-size: 0.875em;
      }
    }
    textarea {
      width: 100%;
      padding: 15px;
      font-size: 15px;
      color: #000;
      border: 1px solid #1c76b3;
      resize: none;
      height: 90px;
    }
  }
  .risk-table {
    padding: 0;
    position: fixed;
    top: 130px;
    width: 100%;
    .table {
      width: 100%;
      font-size: 14px;
      color: #040402;
      background: #fff;
      margin-top: 0.9rem;
    }
    .cp_tree-table .cp_tree-table_header {
      color: #0022a8;
      height: 29px;
      background: #fff !important;
      border-top: none !important;
      font-weight: 700;
      padding: 2px 0.75rem;
      font-size: large;
      border-bottom: 1px solid rgba(55, 53, 47, 0.09);
    }
    .cp_tree-table_header .cp_tree-table_header-cell:first-child {
      max-width: 350px !important;
    }
    .cp_tree-table .cp_tree-table_cell,
    .cp_tree-table .cp_tree-table_header-cell {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
    }
    .cp_tree-table_header span {
      font: normal normal 700 16px/21px Segoe UI;
      color: #0022a8;
      width: 100%;
      padding-bottom: 10px;
      @include breakpoint(lgdesktop) {
        // font: normal normal 700 10px/18px Segoe UI;
      }
    }
    .cp_tree-table_mover .cp_tree-table_row:first-child {
      background: #f1f0f0;
    }
    .cp_tree-table_row {
      height: 45px !important;
    }
    element.style {
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      width: 100%;
      flex-grow: 1;
      flex-basis: 0px;
    }
    .cp_tree-table_row .cp_tree-table_cell:first-child {
      text-align: left;
      max-width: 350px;
    }
    .cp_tree-table .cp_tree-table_cell,
    .cp_tree-table .cp_tree-table_header-cell {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
    }
    .cp_tree-table_row .cp_tree-table_cell {
      text-align: center;
    }
    .cp_tree-table_cell .with-children {
      width: 100%;
      height: 45px !important;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
    }
    .col-blue {
      width: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
    }
    .cp_tree-table .cp_tree-table_cell .toggle-button {
      position: relative;
      display: inline-block;
      border: none;
      height: 14px;
      vertical-align: middle;
      padding: 0 25px 0 5px;
      margin: 0;
      cursor: pointer;
      background-color: transparent;
      outline: none;
    }
    .cp_tree-table .cp_tree-table_cell .toggle-button:after {
      content: '';
      display: inline-block;
      margin-top: -11px;
      width: 9px;
      height: 9px;
      border-right: 0.1em solid rgba(0, 0, 0, 0.45098039215686275);
      border-top: 0.1em solid rgba(0, 0, 0, 0.45098039215686275);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      padding: 0;
      vertical-align: middle;
    }
    .cp_tree-table .cp_tree-table_cell > .with-children > span {
      font-weight: 600;
    }
    .cp_tree-table .cp_tree-table_cell .toggle-button + span {
      vertical-align: middle;
    }
    .cp_tree-table .cp_tree-table_cell > .with-children > span {
      font-weight: 400 !important;
      text-transform: capitalize;
    }
    .cp_tree-table_row .cp_tree-table_cell span {
      width: 100%;
    }
    .cp_tree-table_cell span {
      color: #000;
      font: normal normal normal 16px/21px Segoe UI;
      @include breakpoint(lgdesktop) {
        font-size: 10px;
        line-height: 21px;
      }
    }
    .defaultwidth {
      width: 200px !important;
      display: inline-block;
    }
    .cp_tree-table .cp_tree-table_cell input {
      margin-right: 14px;
    }
    .with-children img,
    .without-children img {
      position: absolute;
      right: 35%;
      @include breakpoint(lgdesktop) {
        position: absolute;
        right: 25%;
        top: 37%;
        height: 12px;
      }
    }
    .table-bordered th {
      color: #0022a8;
      text-align: center;
    }
    .table-bordered td,
    .table-bordered th {
      border: none !important;
    }
    .table-bordered th {
      @include breakpoint(lgdesktop) {
        font-size: 10px;
      }
    }
    tbody tr > td > span {
      margin-left: 0 !important;
    }
    tbody tr > td > span > span.iconPadding {
      padding-left: 0;
    }
    .table-bordered tr td {
      background: #edf3ff;
      text-align: center;
      padding: 0 !important;
      margin: 0;
      height: 56px;
      @include breakpoint(lgdesktop) {
        font-size: 12px;
        height: 50px;
      }

      .text-left {
        span {
          display: flex;
          align-items: center;
        }
      }
      div.child-box {
        background-color: $white !important;
        padding: 18px;
        height: 56px;
        @include breakpoint(lgdesktop) {
          font-size: 12px;
          height: 50px;
        }
      }
    }
    .table-bordered tr .text-left {
      span {
        display: flex;
        align-items: center;
        svg {
          margin-left: 5px;
          margin-top: 0 !important;
        }
      }
    }
    .table-bordered {
      border: none !important;
    }
    .monitor-tree-table {
      width: 100%;
      overflow-x: auto;
      th {
        vertical-align: top;
      }
      td {
        white-space: nowrap;
      }
      @include breakpoint(mddesktop) {
        width: 100%;
        overflow-x: auto;
      }
    }
    .monitor-tree-table td {
      padding: 0 !important;
      margin: 0;
      position: relative;
    }
    .monitor-tree-table td div {
      padding: 1rem;
      font-weight: 600 !important;
      text-transform: capitalize;
      @include breakpoint(lgdesktop) {
        font-size: 10px;
        padding-bottom: 20px;
      }
    }
    .monitor-tree-table td div.index-column {
      padding: 1rem;
      font-weight: 400 !important;
      text-transform: capitalize;
      @include breakpoint(lgdesktop) {
        padding: 1.13rem;
      }
      @include breakpoint(mddesktop) {
        padding: -0.3rem;
      }
    }
    .monitor-tree-table td:nth-child(1) div.team {
      padding-left: 30px !important;
    }
    .monitor-tree-table td div.team {
      background-color: rgba(0, 120, 215, 0.4);
      height: 50px;
    }
    .monitor-tree-table td div.team.index-column {
      background-color: rgba(0, 120, 215, 0.4);
      padding-left: 50px;
    }
    .monitor-tree-table td div.customer {
      background: $white;
      padding-left: 30px;
    }
    .monitor-tree-table td:nth-child(1) div.admin {
      padding-left: 30px !important;
    }
    .monitor-tree-table td div img {
      height: 11px;
      width: 11px;
      margin-left: 13px;
    }
    .monitor-tree-table td span.iconPadding {
      padding: 0;
    }
    .monitor-tree-table td {
      span {
        margin-left: 0 !important;
        font-weight: 400 !important;
        text-transform: capitalize;
      }
    }
    .monitor-tree-table td span svg {
      position: absolute;
      top: 35%;
      left: 5px;
    }
    input[type='checkbox'],
    input[type='radio'] {
      box-sizing: border-box;
      padding: 64px;
      margin: 7px;
    }
    .monitor-tree-table .risk-table {
      overflow-x: auto;
      min-height: 35vh;
    }
    .risk-Dashboard .svg-inline--fa.fa-angle-right.fa-w-8.fa-fw {
      cursor: pointer;
      height: 25px;
      width: 25px;
      top: 25%;
      left: 2%;
      z-index: 99;
    }

    .risk-Dashboard .svg-inline--fa.fa-sort-down.fa-w-10.fa-fw {
      cursor: pointer;
      height: 25px;
      width: 25px;
      top: 25%;
      left: 2%;
      z-index: 99;
    }
    .risk-Dashboard .svg-inline--fa.fa-angle-right.fa-w-8.fa-fw path {
      display: none;
    }
    .risk-Dashboard .svg-inline--fa.fa-sort-down.fa-w-10.fa-fw path {
      display: none;
    }
    .risk-Dashboard .treeIcon {
      font-size: 14px;
      position: absolute;
      top: 35%;
      left: 5px;
    }
  }
  .tooptip-custom {
    background: $white;
    box-shadow: 0px 0px 10px 2px #00000029;
    position: absolute;
    width: 100%;
    height: auto;
    max-width: 500px;
    white-space: normal;
    z-index: 999;
    text-align: left;
    padding: 5px 10px;
    border-radius: 2px;
    word-wrap: break-word;
  }
}

.date-time,
.errorDateTime {
  min-width: 100px;
  max-width: 100px;
  margin-left: 5px;
  margin-right: 5px;
  max-height: 48px;
  overflow: hidden;
  white-space: initial;
  overflow-x: scroll;
  overflow-y: scroll;
}

.errorCount {
  min-width: 80px;
  max-width: 80px;
  margin-left: 5px;
  margin-right: 5px;
  max-height: 48px;
  overflow: hidden;
  white-space: initial;
  overflow-x: scroll;
  overflow-y: scroll;
}

.errorCodeId {
  min-width: 80px;
  max-width: 80px;
  margin-left: 5px;
  margin-right: 5px;
  max-height: 48px;
  overflow: hidden;
  white-space: initial;
  overflow-x: scroll;
  overflow-y: scroll;
}

.applicationId {
  min-width: 65px;
  max-width: 65px;
  margin-left: 5px;
  margin-right: 5px;
  max-height: 48px;
  overflow: hidden;
  white-space: initial;
  overflow-x: scroll;
  overflow-y: scroll;
}

.logSource {
  min-width: 100px;
  max-width: 100px;
  margin-left: 5px;
  margin-right: 5px;
  max-height: 48px;
  overflow: hidden;
  white-space: initial;
  overflow-x: scroll;
  overflow-y: scroll;
}

.ip {
  min-width: 140px;
  max-width: 140px;
  margin-left: 5px;
  margin-right: 5px;
  max-height: 48px;
  overflow: hidden;
  white-space: initial;
  overflow-x: scroll;
  overflow-y: scroll;
}

.message {
  min-width: 25vw;
  max-width: 25vw;
  margin-left: 5px;
  margin-right: 5px;
  // max-height: 48px;
  overflow: hidden;
  white-space: initial;
  overflow-x: scroll;
  overflow-y: scroll;
}

.ruleId {
  min-width: 100px;
  max-width: 100px;
  margin-left: 5px;
  margin-right: 5px;
  max-height: 48px;
  overflow: hidden;
  white-space: initial;
  overflow-x: scroll;
  overflow-y: scroll;
}

.entityId {
  min-width: 100px;
  max-width: 100px;
  margin-left: 5px;
  margin-right: 5px;
  max-height: 48px;
  overflow: hidden;
  white-space: initial;
  overflow-x: scroll;
  overflow-y: scroll;
}

.familyId {
  min-width: 100px;
  max-width: 100px;
  margin-left: 5px;
  margin-right: 5px;
  max-height: 48px;
  overflow: hidden;
  white-space: initial;
  overflow-x: scroll;
  overflow-y: scroll;
}

.date-time::-webkit-scrollbar,
.errorDateTime::-webkit-scrollbar,
.errorCount::-webkit-scrollbar,
.errorCodeId::-webkit-scrollbar,
.applicationId::-webkit-scrollbar,
.logSource::-webkit-scrollbar,
.ip::-webkit-scrollbar,
.message::-webkit-scrollbar,
.ruleId::-webkit-scrollbar,
.entityId::-webkit-scrollbar,
.familyId::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.date-time,
.errorDateTime,
.errorCount,
.errorCodeId,
.applicationId,
.logSource,
.ip,
.message,
.ruleId,
.entityId,
.familyId {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
