@import url('../../../global.css');
.feedback-popup-main-container {
	width: 35vw;
	display: flex;
	flex-direction: column;
}

.feedback-logo {
	height: 8vh;
	width: 10vw;
	margin: 1vh auto 2vh auto;
}

.feedback-popupmessage {
	max-height: 20vh;
	overflow-y: auto;
	font-size: 2vh;
	font-family: var(--def-font);
	max-width: 30vw;
	margin: 1vh auto 2vh auto;
}

.feedback-popup-bottom {
	height: 8vh;
	width: 35vw;
	background-color: #efefef;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.feedback-popup-bottom-New {
	height: 8vh;
	width: 90%;
	background-color: #ffffff;
	display: flex;
	flex-direction: row;
	align-items: right;
	justify-content: right;
	position: absolute;
    bottom: 5px;
}
