@import '../../../../assets/scss/variable.scss';
@import '../../../../assets/scss/mixins.scss';
@import url('../../../../global.css');

.strategyView {
  background-color: var(--white);
  border-radius: 11px;
  margin: 1rem;

  .strategyViewtable {
    thead tr {
      height: 50px !important;
      @include breakpoint(lgdesktop) {
        height: 30px !important;
      }
    }
    th {
      font: normal normal bold 1em/14px Segoe UI !important;
      @include breakpoint(lgdesktop) {
        font: normal normal bold 0.85em/14px Segoe UI !important;
      }
    }
    thead th:nth-child(1) {
      border-top-left-radius: 0px !important;
    }
    thead th:last-child {
      border-top-right-radius: 0px !important;
    }
    tbody tr:nth-of-type(even) {
      background-color: #edf3ff !important;
    }
    td {
      font-weight: 700 !important;
    }
    tbody tr td span.elipsis {
      white-space: nowrap;
      width: 110px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      margin: 0 auto;
    }
  }
  .pagination {
    position: relative;
    height: 60px;
    .table-pagination {
      display: none;
    }
    .enteries {
      position: absolute;
      top: 25px;
      left: 0;
    }
  }
}

.strategyViewV2_th {
  font-family: var(--def-font);
  font-weight: 400;
  font-size: calc(var(--font-size) * 1.1);
}

.strategyViewV2_td {
  font-family: var(--def-font);
  font-weight: 400;
  font-size: var(--font-size);
}

.strategyViewTd {
  display: flex;
  flex-direction: row;
}

.dropdownStratView-active {
  height: 2vh !important;
  width: 2vh !important;
  margin: 0 1vw 0 auto !important;
  cursor: pointer;
  transform: rotate(180deg);
}

.dropdownStratView-inactive {
  height: 2vh !important;
  width: 2vh !important;
  margin: 0 1vw 0 auto !important;
  cursor: pointer;
}

.table-header {
  margin: 0 1vw 0 0.1vw !important;
}

.strategyView-s1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0;
}
