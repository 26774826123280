@import url('../../../../global.css');

.manage-role-button-container {
  border: 1px solid #0078d7;
  border-radius: 0.5vh;
  height: fit-content;
  width: 15vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  box-shadow: 0px 0px 6px #00000029;
  padding: var(--gen-pad);
  cursor: pointer;
}

.manage-role-text {
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 1);
  margin: 0.5vh 2vh 0.5vh 2vh !important;
  color: #0078d7;
  margin: 0 0 0 1vw;
}

.manage-role-icon {
  height: 2vh;
  width: 2vh;
  margin: 0 1vw 0 0;
}

.manage-role-container {
  position: relative;
}

.manage-role-dd-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 20vh;
  width: 18vw;
  background-color: white;
  z-index: 9;
  border: 1px solid #cecece;
  border-radius: 0.5vh;
  display: flex;
  flex-direction: column;
}

.manage-role-dd-s1 {
  height: 5vh;
  display: grid;
  grid-template-columns: 60% 40%;
}

.manage-role-add-cont {
  border: 1px solid #a7c5db67;
  border-radius: 0.5vh;
  margin: 0.5vh 0.4vw 0.5vh 0.4vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  box-shadow: 3px 4px 6px #acc9e545;
}

.manage-role-add-cont:hover {
  box-shadow: inset 1px 3px 4px #90bfed46;
}

.manage-role-add-text {
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 0.8);
}

.manage-role-add-icon {
  height: 1.5vh;
  width: 1.5vh;
}

.manage-role-dd-select-area {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 15vh;
}

.dd-manage-role-sb {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5vh;
  width: 100%;
}

.dd-manage-role-sb-hover {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5vh;
  width: 100%;
  background-color: #f8f8f8;
}

.ddmenuOption-title {
  font-family: var(--def-font);
  font-size: var(--font-size);
  color: #000000;
  margin: 1vh auto 1vh 1vw;
}

.dd-menu-option-icon-hover-1 {
  height: 2.5vh;
  width: 2.5vh;
  margin: auto 1vw auto auto;
  cursor: pointer;
}

.dd-menu-option-icon-hover {
  height: 2.5vh;
  width: 2.5vh;
  margin: auto 1vw auto 0;
  cursor: pointer;
}

.image-blue-filter {
  filter: invert(36%) sepia(74%) saturate(4903%) hue-rotate(190deg)
    brightness(94%) contrast(101%);
}
