@import './variable.scss';

//breakpoint
@mixin breakpoint($point) {
  @if $point==xlgdesktop {
    @media (max-width: 1700px) {
      @content;
    }
  } @else if $point==lgdesktop {
    @media (max-width: 1600px) {
      @content;
    }
  } @else if $point==mldesktop {
    @media (max-width: 1500px) {
      @content;
    }
  } @else if $point==mddesktop {
    @media (max-width: 1368px) {
      @content;
    }
  } @else if $point==desktop {
    @media (max-width: 1280px) {
      @content;
    }
  } @else if $point==smdesktop {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $point==tablet {
    @media (max-width: 991px) {
      @content;
    }
  }
}
//breakpoint

//global css for elements
@mixin tablebtns() {
  font: normal normal bold 16px/21px Segoe UI;
  letter-spacing: 0px;
  color: $white;
  padding: 0 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  border: none;
  height: 33px;
  background: $mediumblue;
  padding: 0px 20px;
  width: 110px;
  margin-right: 15px;
}
