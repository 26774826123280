@import url('../../../../global.css');

.create-role-container {
  height: 80vh;
  width: 50vw;
}

.create-role-head {
  background-color: var(--primary-color);
  height: 6vh;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.create-role-title {
  color: white;
  font-size: calc(var(--font-size) * 1.2);
  margin: 0 0 0 1vw;
}

.create-role-delete {
  background-color: white;
  height: 5vh;
  width: 5vh;
  border-radius: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 1vw 0 auto;
  cursor: pointer;
}

.create-role-body {
  height: 64vh;
  overflow-y: scroll;
  /* display: grid;
  grid-template-columns: 1fr 2fr; */
}

.create-role-body .input-container {
  margin-top: 2vh !important;
  margin-bottom: 3vh !important;
  height: 8vh;
  display: flex;
  width: 48vw !important;
  margin: auto;
  align-items: center;
  justify-content: space-between;
}

.create-role-body .remark-container {
  margin-top: 2vh !important;
  margin-bottom: 2vh !important;
  width: 48vw;
  margin: auto;
}

.create-role-foot {
  margin-top: 2vh;
  height: 8vh;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.create-role-error-message {
  color: red;
  font-size: var(--font-size);
  margin-left: 1vw;
}
