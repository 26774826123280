@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.login-main-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  @include breakpoint(lgdesktop) {
    img {
      height: 310px;
    }
  }
  .sidebutton {
    height: 68px;
    width: 250px;
    display: inline;
    background: #f37c20;
    color: $white;
    border-radius: 5px;
    border: none;
    font-family: 'Roboto', sans-serif;
    font-size: $twenty;
    line-height: 20px;
    position: absolute;
    right: 40px;
    top: 40px;
    @include breakpoint(lgdesktop) {
      height: 40px;
      width: 110px;
      font-size: $fourteen;
    }
  }
}
