@import '../../../assets/scss/variable.scss';
@import '../../../assets/scss/mixins.scss';
.logout-wrapper {
  .nonecolor-Alertmodal {
    background: rgba(0, 0, 0, 0.49019607843137253);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999;

    .popup {
      width: 640px !important;
      height: 431px !important;
      background: #fff;
      box-shadow: $dark-btm-shadow;
      border: 1px solid #707070;
      border-radius: 10px;
      position: absolute;
      top: 15%;
      left: 32%;
      z-index: 9999;
      margin-top: 5%;
      overflow: hidden;
      @include breakpoint(lgdesktop) {
        width: 450px !important;
        height: 310px !important;
      }
      .create_2 {
        background: #fffdfd;
        height: 100%;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        align-items: center;
        h1 {
          margin-bottom: 40px;
          font-size: 44px;
          line-height: 58px;
          font-family: $roboto;
          font-weight: 500;
          color: #202062;
          @include breakpoint(lgdesktop) {
            font-size: 30px;
            margin-bottom: 20px;
          }
        }
        p {
          font-size: 22px;
          line-height: 29px;
          font-family: $roboto;
          font-weight: 500;
          color: #202062;
          margin-bottom: 40px;
          @include breakpoint(lgdesktop) {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 25px;
          }
        }

        .logout-btn img {
          @include breakpoint(lgdesktop) {
            height: 42px;
          }

          &a:focus,
          a:hover,
          a img {
            text-decoration: none;
            border: 0;
            outline: none;
          }
        }
      }
    }
  }
}
