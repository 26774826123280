@import url('../../../global.css');

.GenericSearchBox-input {
	width: 10rem;
	margin-left: 0.5vw;
	border: none;
	margin: 0.2rem 0.4rem;
}

.GenericSearchBox-input::placeholder {
	font-family: var(--def-font);
	font-size: var(--font-size);
	line-height: 1rem;
	letter-spacing: 0px;
	font-weight: 400;
}

.GenericSearchBox-container {
	height: fit-content;
	width: fit-content;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	border-radius: var(--border-radius);
	background-color: var(--white);
	border: var(--inDdInfieldBorder);
}
.GenericSearchBox-icon {
	height: 1.4rem;
	width: 1.4rem;
	margin: 0.2rem;
}

.generic-searchbox-cont {
	border: 1px solid #00000029;
	border-radius: 0.5vh;
	display: flex;
	flex-direction: row;
	align-items: center;
	overflow: hidden;
}

.generic-searchbox-cont-focus {
	border: 1px solid #000;
	border-radius: 0.5vh;
	display: flex;
	flex-direction: row;
	align-items: center;
	overflow: hidden;
}

.generic-searchbox-img {
	height: 2.6vh;
	width: 2.6vh;
	margin: 0 0.5vw 0 0.5vw;
}

.generic-searchbox-input {
	width: 100%;
	font-family: var(--def-font);
	border: none;
	margin: 0.2vh 0 0.2vh 0;
}
