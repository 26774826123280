@import url('../../global.css');

.LicenseKey-container-1 {
	height: 40vh;
	width: 40vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.LicenseKey-text1 {
	font-family: var(--def-font);
	font-size: var(--font-size);
	color: red;
	margin: 1vh 0 2vh 0;
}

.LicenseKey-text2 {
	font-family: var(--def-font);
	font-size: var(--font-size);
	color: #7d82a5;
	margin: 0 0 1vh 0;
}

.LicenseKey-button-fill {
	background-color: #f37c20;
	border-radius: 8px;
	padding: 1vh 3vw;
	color: white;
	cursor: pointer;
	transition: all 0.3s;
}
.LicenseKey-button-fill:hover {
	box-shadow: 0px 0px 8px #00000033;
	transition: all 0.3s;
}

.LicenseKey-button-hollow {
	color: #f37c20;
	border: 1px solid #f37c20;
	border-radius: 8px;
	padding: 1vh 3vw;
	cursor: pointer;
}

.LicenseKey-button-hollow:hover {
	box-shadow: 0px 0px 8px #00000033;
	transition: all 0.3s;
}

.LicenseKey-button-container1 {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	justify-content: space-evenly;
	margin-top: 2vh;
}

.LicenseKey-container-2 {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 40vh;
	width: 35vw;
	background-color: white;
}

.LicenseKey-inputField-container {
	display: flex;
	flex-direction: column;
	margin: 4vh 0 4vh 0;
}

.LicenseKey-label {
	font-family: var(--def-font);
	font-size: var(--font-size);
	color: #34395f;
}

.LicenseKey-input {
	border: 0.5px solid #34395f;
	border-radius: 4px;
	height: 5vh;
	width: 25vw;
	padding-left: 1vw;
	font-size: var(--font-size);
}

.LicenseKey-error {
	font-family: var(--def-font);
	font-size: var(--font-size);
	color: red;
	margin: 0 0 0 1vw;
}

.LicenseKey-errorIcon {
	height: 2vh;
	width: 2vh;
}

.LicenseKey-errorContainer {
	margin: 1vh 0 0 0;
	display: flex;
	flex-direction: row;
	align-items: center;
}
