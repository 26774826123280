.family-name-log-live {
  margin-top: 15px;
  max-width: 500px;
  border-width: 2px;
  border-style: solid;
  border-color: #cecece;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  width: 480px;
  overflow: hidden;

  .family-name-log-live-header {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    min-height: 40px;
    max-height: 40px;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    font-weight: 600;

    .family-name-log-live-header-text {
      padding-left: 10px;
    }
  }

  .family-name-log-live-list {
    display: flex;
    flex-direction: column;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: center;
    max-width: 480px;
    width: 480px;

    .family-name-log-live-list-member-row{
      display: flex;
      flex-direction: row;
      flex-flow: row;
      align-items: center;
      justify-content: center;
      border-top-width: 2px;
      border-top-style: solid;
      border-color: #CECECE;

      .family-name-log-live-list-member {
        max-width: 120px;
        min-height: 40px;
        max-height: 40px;
        width: 120px;
        overflow: scroll;
        border-right-width: 2px;
        border-right-style: solid;
        border-color: #CECECE;
        display: flex;
        flex-direction: column;
        flex-flow: column;
        justify-content: center;
        align-items: center;
  
        // Hide Scroll bar
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        
        .family-name-log-live-list-member-text {
        }
      }
  
      .family-name-log-live-list-member-last {
        border-right-width: 0px;
        width: 118px;
      }
  
      // Hide Scroll bar
      .family-name-log-live-list-member::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
