@import '../../../../../assets/scss/variable.scss';
@import '../../../../../assets/scss/mixins.scss';
.ReviewStrategy {
  padding: 0 17px !important;
  overflow-y: scroll !important;
  .closeBtn {
    height: 22px;
    cursor: pointer;
  }
  .modal-body {
    padding-bottom: 120px !important;
    .top_header {
      width: calc(100% - 4rem) !important;
    }
  }
  .modal-lg,
  .modal-xl {
    max-width: 1800px !important;
    @include breakpoint(lgdesktop) {
      max-width: 1440 !important;
      width: 100% !important;
    }
  }
  .modal-content {
    width: 100% !important;
    @include breakpoint(lgdesktop) {
      transform: translate(0, 0);
      position: relative;
    }
    .close {
      img {
        height: 15px;
        width: 15px;
        margin-top: 5px;
      }
    }

    .modal-body {
      .strategy {
        margin-top: 0 !important;
        .childcomponent {
          height: 570px;
          top: 75px !important;
          overflow-y: auto;
          overflow-x: hidden;
          @include breakpoint(lgdesktop) {
            height: 420px;
          }
          @include breakpoint(mddesktop) {
            height: 380px;
          }
        }
      }
      .input-group {
        .form-control {
          margin: 15px;
        }
      }

      .input {
        height: 37px !important;
      }

      textarea {
        margin-top: 15px;
      }

      .show-select {
        display: block;
      }
      .submitsbuttons {
        margin-bottom: 65px;
      }
      .top_header {
        position: absolute !important;
        background: none;
        padding-top: 0 !important;
      }
      .step_1 input {
        height: 50px !important;
        font-size: 1em !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
      .step_2 select {
        width: 90px;
        height: 40px;
        background: #ffffff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        display: inline;
        border: none;
        font-size: 0.85em;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
      }
      //step4
      .select-ex-algo .leg_1 .inputs .form-control {
        width: 100px;
        height: 35px;
        background: #ffffff !important;
        box-shadow: 0px 3px 6px #00000029 !important;
        border-radius: 10px;
        display: inline;
        border: none !important;
        text-align: center;
      }
      .select-ex-algo .leg_1 .buy .select select {
        width: 135px;
        height: 34px;
        background: #ffffff !important;
        box-shadow: 0px 3px 6px #00000029 !important;
        border-radius: 10px;
        border: none;
        font-size: 1em !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden !important;
        display: inherit;
      }
      .select-ex-algo .leg_1 .inputs select {
        width: 104px;
        height: 34px;
        background: #ffffff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        border: none;
        font-size: 1em !important;
        overflow: hidden !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inherit;
      }
      //step 5
      .condition .inputs .first select {
        width: 104px;
        height: 35px;
        background: #ffffff !important;
        box-shadow: 0px 3px 6px #00000029 !important;
        border-radius: 10px;
        display: block;
        border: none !important;
        font-size: 1em;
      }

      .input-group > .custom-file,
      .input-group > .custom-select,
      .input-group > .form-control,
      .input-group > .form-control-plaintext {
        height: 34px !important;
        background: #ffffff !important;
        box-shadow: $dark-btm-shadow !important;
        border-radius: 10px !important;
        display: inline !important;
        border: none;
        font-size: 0.85em !important;
        width: 100px;
      }
    }
    .step_3 {
      .table {
        tbody {
          td {
            input {
              height: 40px !important;
            }
          }
        }
      }
      .MuiAutocomplete-hasPopupIcon {
        .MuiAutocomplete-inputRoot {
          input {
            height: 28px !important;
          }
        }
      }
    }
  }
  input#time-picker {
    border: 0 !important;
    box-shadow: none !important;
    font-size: 0.9em !important;
    text-align: left !important;
    height: 1.35em !important;
    line-height: 24px !important;
    max-height: 32px !important;
    font-family: 'Roboto', sans-serif !important;
  }
  .modal-content .modal-body label {
    font-size: 0.8em !important;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 400;
  }
  .modal-content .modal-body .condition .inputs .first select,
  .exitCondition .inputs .ops .form-control,
  .step_3 .table tbody td select,
  .modal-content .modal-body .condition .inputs .first select,
  .modal-content .modal-body select,
  .modal-content .modal-body .select-ex-algo .leg_1 .inputs .form-control,
  .select-ex-algo .leg_1 .inputs .form-control,
  .modal-content .modal-body .select-ex-algo .leg_1 .inputs select {
    font-size: 1em !important;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 400;
  }
  .modal-content .modal-body .input-group .form-control,
  .modal-content .modal-body textarea {
    font-size: 1em !important;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 400;
    text-align: left !important;
  }
  .multiDropdown .formWrapper {
    padding: 10px 20px;
    max-height: 320px;
    overflow-y: scroll;
    .form-control {
      text-align: left !important;
      text-align-last: center !important;
    }
  }

  .square_off {
    .multiDropdown {
      .formWrapper {
        bottom: 100%;
      }
    }
  }
  .sepratewithcross {
    .mainWrapperDropdown {
      ul {
        li {
          padding: 10px !important;
        }
      }
    }
  }
  .ex-algo-wrapper {
    .mainWrapperDropdown {
      ul {
        li {
          padding: 10px !important;
        }
      }
    }
  }
  .exitCondition {
    .inputs {
      input {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }
  .mainWrapperDropdown {
    margin-bottom: 5px !important;
  }
  .MARKET_TRIGGERED-2 {
    .mainWrapperDropdown {
      top: auto;
      bottom: 100% !important;
    }
    .multiDropdown {
      .formWrapper {
        top: auto;
        bottom: 100% !important;
        margin: 5px 0 !important;
      }
    }
  }
  .MARKET_TRIGGERED-3 {
    .mainWrapperDropdown {
      top: auto;
      bottom: 100% !important;
    }
    .multiDropdown {
      .formWrapper {
        top: auto;
        bottom: 100% !important;
        margin: 5px 0 !important;
      }
    }
  }
  .MuiFormControl-marginNormal {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .submitsbuttons .button_group {
    padding-top: 50px;
  }

  .modal-content .modal-body li {
    font-size: 1em;
    line-height: 0.8em;
    span {
      cursor: pointer;
      text-align: left;
      text-transform: capitalize;
      background: #007bff40;
      color: #000000;
      font-family: 'Roboto', sans-serif;
      font-size: 1em;
      line-height: 0.8em;
      margin: 2px 0;
      width: 100%;
      display: block;
      padding: 13px 14px;
      position: relative;
    }
    ul {
      li {
        cursor: pointer;
        border-radius: 5px;
        background: #ffffff;
        color: #000000;
        font-family: 'Roboto', sans-serif;
        font-size: 1em;
        line-height: 1em;
        padding: 14px 17px !important;
        text-align: left;
        margin: 2px 0;
      }
    }
  }
  .modal-content .modal-body input.finalValueShow {
    margin: 0 14px !important;
    padding-bottom: 11px;
  }
  .sepratewithcross {
    .multiDropdown {
      input.finalValueShow {
        margin: 5px 14px !important;
      }
    }
    .condition {
      span {
        display: flex;
        align-items: center;
        text-transform: capitalize;
        font-family: 'Roboto', sans-serif;
        justify-content: space-evenly;
        .input-group {
          .form-control {
            margin: 0 !important;
            width: 100% !important;
          }
        }
      }
    }
  }
  .error-input .form-control {
    border: 1px solid red !important;
  }
  .save-modal {
    .popup {
      top: auto;
      bottom: 15%;
      .form-label {
        font-size: 1em !important;
        text-align: center;
      }
    }
  }
  .approveReject-pointer {
    pointer-events: none;
  }
  .MuiSnackbar-root .MuiSnackbar-anchorOriginTopRight {
    z-index: 9999;
  }
}
.reviewPreivew {
  background: #00000078;
}
