@import '../../../global.css';

.StrategyAnalyzerSearch-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: fit-content;
	width: fit-content;
	border: var(--inDdInfieldBorder);
	border-radius: 4px;
}

.StrategyAnalyzerSearch-searchDD {
	border: none;
	border-right: var(--inDdInfieldBorder);
}

.StrategyAnalyzerSearch-inputBox {
	border: none;
	padding: 0 0.5rem;
	font-family: var(--def-font);
	font-size: var(--font-size);
	line-height: 1rem;
	letter-spacing: 0px;
	font-weight: 400;
	width: 20rem;
}

.StrategyAnalyzerSearch-inputBox::placeholder {
	font-family: var(--def-font);
	font-size: var(--font-size);
	line-height: 1rem;
	letter-spacing: 0px;
	font-weight: 400;
}

.StrategyAnalyzerSearch-searchContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	height: 1.9rem;
	width: fit-content;
	border-left: var(--inDdInfieldBorder);
	cursor: pointer;
}

.StrategyAnalyzerSearch-searchContainer:focus {
	border: var(--focusBorder);
}

.StrategyAnalyzerSearch-searchContainer:active {
	background-color: var(--hoverSelectBackgroundColor);
	box-shadow: none !important;
	transition: all 0.2s;
}
.StrategyAnalyzerSearch-searchContainer:hover {
	box-shadow: 0.2rem 0.2rem 0.2rem #00000022;
	transition: all 0.2s;
}

.StrategyAnalyzerSearch-search-icon {
	height: 1.2rem;
	width: 1.2rem;
	margin: 0 1rem;
}
