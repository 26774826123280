@import url('../../../global.css');
.chart-container {
    width: fit-content;
  border: 2px solid #e2e2e2;
  border-radius: 1vh;
  overflow: hidden;
}

.chart-type-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chart-title-1 {
  font-family: var(--def-font);
  color: var(--dark-black);
  margin: 1vh;
  font-weight: 500;
  font-size: 2.2vh;
}

.chart-title-pos {
  font-family: var(--def-font);
  color: green;
  font-size: 2vh;
  font-weight: 600;
  margin: 0 0 2vh 1vh;
}

.chart-title-nev {
  font-family: var(--def-font);
  color: red;
  font-size: 2vh;
  font-weight: 600;
  margin: 0 0 2vh 1vh;
}

.chart-type-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f4f4f4;
  margin-bottom: 2vh;
}

.chart-title-2 {
  font-family: var(--def-font);
  color: var(--dark-black);
  margin: 1vh;
  font-weight: 500;
  font-size: 2.2vh;
}
