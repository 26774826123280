@import '../../../assets/scss/variable.scss';

.risk-monitering {
  box-shadow: $dark-btm-shadow;
  // overflow-y: scroll;
}

.switch {
  display: flex;
  justify-content: flex-end;
}
