@import url('../../../../global.css');

.loginfo-container {
  position: relative;
  width: fit-content;
}

.logout-dropdown-container {
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
}

.logout-dropdown-icon2 {
  height: 2vh;
  width: 2vh;
}

.logout-dropdown-text {
  font-family: var(--def-font);
  margin: 0 0.5vw 0 auto;
}

.logout-dropdown-icon2 {
  margin: 0 1vw 0 1vw;
}

.logout-dd-options {
  width: fit-content;
  height: fit-content;
  position: absolute;
  background-color: white;
  top: 6.4vh;
  right: 0;
  z-index: 999;
  border: 1px solid #d7d7d7;
  /* border: 1px solid #cecece; */
}

.quickShortcutsPopCont {
	height: 70vh;
	width: 40vw;
	background-color: white;
	border-radius: 1em;
}

.quickShortcutsPopCont-titleBar {
	background-color: var(--primary-color);
	height: 6vh;
	display: flex;
	flex-direction: row;
}

.quickShortcutsPopCont-title {
	color: white;
	font-size: calc(var(--font-size) * 1.1);
	margin: auto 0 auto 1em;
  font-weight: 600;
}

.quickShortcutsPopCont-body{
  max-height: 60vh; /* Example maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 1rem; 
}

.strategy-shortcut-entry {
  margin-bottom: 10px;
}

.strategy-name {
  display: inline-block;
  width: 24vw; /* Adjust width as needed */
}

.shortcut-dropdown {
  margin-left: 10px;
  width: 12vw; /* Adjust width as needed */
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.strategy-shortcut-header {
  display: flex;
  padding-bottom: 10px;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.header-strategy-name {
  flex: 2;
  font-weight: bold;
}

.header-shortcut-key {
  flex: 1;
  font-weight: bold;
  text-align: center;
}

.feedback-popup-bottom-Shortcuts {
	height: 8vh;
	width: 100%;
	background-color: #d7d7d7;
	display: flex;
	flex-direction: row;
	align-items: right;
	justify-content: right;
	position: absolute;
  bottom: 2px;
  padding: 2px 5px 0px 0px;
}


.logout-dd-option-icon {
  height: 2.1vh;
  width: 2.1vh;
  margin: 0 0.5vw 0 0.5vw;
}

.logout-dd-option {
  height: 5vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-top: 1px solid #cecece;
  border-right: 1px solid #cecece;
  border-left: 1px solid #cecece;
  cursor: pointer;
}

.logout-dd-option-last {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #cecece;
  border-right: 1px solid #cecece;
  border-left: 1px solid #cecece;
  cursor: pointer;
}

.logout-dd-option:hover {
  background-color: #f0f3f2;
}

.logout-dd-option-text {
  font-family: var(--def-font);
  font-size: 1.8vh;
}

.logout-dropdown-icon {
  height: 4vh;
  width: 4vh;
}

.user-name-initials {
  height: 5vh;
  width: 5vh;
  font-size: calc(var(--font-size) * 1.8);
  font-family: var(--def-font);
  background-color: #90bfed46;
  border-radius: 6vh;
  color: #0078d7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drowDownOption-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2.4rem;
  width: 15rem;
  border-top: 1px solid #f0f0f0;
  cursor: pointer;
  transition: all 0.1s;
}
.drowDownOption-container:hover {
  background-color: #f7f7f7;
  transition: all 0.1s;
}

.drowDownOption-s1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 20%;
}

.drowDownOption-s2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  height: 100%;
  width: 60%;
}

.drowDownOption-s3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 20%;
}

.drowDownOption-leftIcon {
  height: 2vh;
  width: 2vh;
  margin: auto;
}

.drowDownOption-label {
  font-family: var(--def-font);
  font-size: var(--font-size);
}
