@import url('../../../global.css');
.Switch-container-active {
  height: 1.4rem;
  width: 3rem;
  border-radius: 1.4rem;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  transition: all 0.5s;
}

.switch-ball {
  height: 1rem;
  width: 1rem;
  background-color: white;
  border-radius: 1.2rem;
}

.switch-text-active {
  font-family: var(--def-font);
  color: white;
  color: white;
  font-size: 0.7rem;
}

.switch-text-inactive {
  font-family: var(--def-font);
  color: var(--dark-black);
  font-size: 0.7rem;
}

.Switch-container-inactive {
  transition: all 0.5s;
  height: 1.4rem;
  width: 3rem;
  border-radius: 1.4rem;
  background-color: #80808055;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}
