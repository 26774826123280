@import url('../../../../global.css');

.create-fam-cont {
  height: 79vh;
  width: 85vw;
  overflow-y: hidden;
}

.create-fam-title {
  height: 7vh;
  width: 100%;
  background-color: #067bd8;
  display: flex;
}

.create-fam-title-text {
  font-size: 2.2vh;
  color: white;
  font-family: var(--def-font);
  margin: auto auto auto 2vw;
}

.create-fam-s1-cont {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 2vh 12vw 2vh 2vw;
}

.create-fam-s2-cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 2vh 2vw 2vh 2vw;
}

.create-fam-s3-cont {
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: flex-start;
  justify-content: left;
  margin: 2vh 2vw 2vh 2vw;
}

.create-fam-entity-container, .create-fam-entity-subentity-container {
  border: 1px solid #cecece;
  margin: 2vh 2vw 2vh 2vw;
  border-radius: 0.5vh;
  /* overflow: hidden; */
}

.entity-container-header {
  background-color: #f4f4f4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5vh 1.5vh;
  overflow: hidden;
  border-bottom: 1px solid #cecece;
  border-radius: 0.8vh 0.8vh 0vh 0vh;
}

.entity-title {
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 1);
  color: #0078d7;
  font-weight: 500;
}

.entity-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 1vh 0 1vh 0;
}

.entity-button-group {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 8vh;
}

.entity-body {
  height: 64vh;
  overflow-y: scroll;
}

.error-message-fam {
  font-family: var(--def-font);
  margin: auto 0 auto 1vw;
  font-size: 2vh;
  color: #ea4242;
}

.entity-cont {
  display: grid;
  grid-template-columns: 1.1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 12vh;
  align-items: start;
  justify-content: start;
  padding-bottom: 1vh;
  border-bottom: 1px solid #cecece;
}

.family-entity-controls {
  display: flex;
  flex-direction: row;
  flex-flow: row;
  align-items: center;
  justify-content: right;
}

.sub-entity-data {
  display: grid;
  grid-template-columns: 1.1fr 1fr 1fr 1fr 1fr 1fr 0.3fr;
  grid-template-rows: 12vh;
  min-height: 30vh;
}

.sub-entity-data-account {
  display: grid;
  grid-template-columns: 1fr 1.1fr 1fr 1fr 1fr 1.5fr 0.3fr;
  grid-template-rows: 12vh;
  min-height: 30vh;
}

.family-entity-subentity-delete-grid {

}

.delete-family-entity-subentity-icon-cont {
  min-width: 4vh;
  height: 4vh;
  width: 4vh;
  background-color: #0078d7;
  border-radius: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5.7vh;
  margin-left: 1.5vh;
  cursor: pointer;
}
/* Sub Entity layout grid css end */

.family-add-subentity {
  margin: 2vh 0vw 2vh 1.3vw;
  padding: 2px 10px 2px 10px;
  height: 4.5vh;
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-flow: row;
  align-items: center;
  justify-content: left;
  font-family: var(--def-font);
  font-size: 1vh;
  border: 1.8px solid #0078d7 ;
  border-radius: 3vh;
}

.family-add-subentity span {
  font-size: 1.7vh;
  font-weight: bold;
  color: #0078d7;
}

.delete-family-entity-subentity{
  height: 4vh;
  width: 4vh;
  background-color: #0078d7;
  border-radius: 3vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 7vh auto auto auto; */
  cursor: pointer;
}

.add-family-entity-subentity {
  height: 2vh;
  width: 3vh;
  background-color: #ffffff;
  border-radius: 3vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 7vh auto auto auto; */
  cursor: pointer;
}

.delete-family-entity-icon {
  height: 1.7vh;
  width: 1.7vh;
}

.delete-family-entity-icon-1 {
  height: 2vh;
  width: 2vh;
}

.add-family-entity-icon {
  height: 2vh;
  width: 2vh;
  margin-right: 10px;
  color: #0078d7;
}
