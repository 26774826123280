@import url('../../../global.css');

.tabNavInner-back {
	height: 2rem;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	border-bottom: 1px solid #cecece;
}

.tabNavInner-title {
	font-size: var(--font-size);
	width: fit-content;
	font-family: var(--def-font);
	margin: 1vh 2vw 0 1.2vw;
	height: fit-content;
	font-weight: 500;
	color: #97999f;
	cursor: pointer;
	transition: all 0.2s;
}

.tabNavInner-title:focus {
	border-bottom: 1px solid #f37c21;
}

.tabNavInner-titleactive {
	font-size: var(--font-size);
	width: fit-content;
	font-family: var(--def-font);
	margin: 1vh 2vw 0 1.2vw;
	height: fit-content;
	font-weight: 500;
	color: #000;
	border-bottom: 0.3vh solid #f37c21;
	cursor: pointer;
	transition: all 0.2s;
}
