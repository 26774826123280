@import url('../../../../global.css');

.PageGroup-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
}

.PageGroup-cur {
  background-color: #f0f9ff;
  border: 1px solid #0078d7;
  color: #0078d7;
  height: 3vh;
  min-width: 3vh;
  font-size: 2vh;
  border-radius: 0.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5vh;
  margin: 0.5vh 0.5vw;
  cursor: pointer;
}

.PageGroup-nor {
  background-color: #ffffff;
  border: 1px solid #979995;
  color: #171725;
  height: 3vh;
  min-width: 3vh;
  font-size: 2vh;
  border-radius: 0.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5vh;
  margin: 0.5vh 0.5vw;
  cursor: pointer;
}
