@import '../../../../assets/scss/mixins.scss';
@import '../../../../assets/scss/variable.scss';

.add-risk-wrapper {
  margin-bottom: 29px;
  margin-top: 22px;
  .add-rule-btn {
    display: flex;
    align-items: center;
    height: 50px !important;
    padding: 0 17px 5px;
    width: 154px;
    background: $white;
    border: 1px solid #707070;
    border-radius: 11px;
    font: normal normal 700 20px/27px Segoe UI;
    color: #0022a8;
    @include breakpoint(lgdesktop) {
      height: 35px !important;
      font-size: $twelve;
      width: 117px;
      margin-top: 1%;
    }
    span {
      font-size: 34px;
      margin-right: 7px;
      @include breakpoint(lgdesktop) {
        font-size: 28px;
      }
    }
    p {
      margin-bottom: -6px;
    }
  }
  .dropdown-rule {
    z-index: 9999;
    margin-top: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    position: absolute;
    background: $white !important;
    border: 1px solid #339;
    width: 289px;
    border-radius: 20px;
    padding: 9px 23px;
    @include breakpoint(lgdesktop) {
      width: 230px;
    }
    .filter-wrapper {
      box-shadow: $dark-btm-shadow;
      border: 1px solid #bababa;
      padding: 8px 10px 8px 10px;
      height: 37px;
      margin-bottom: 30px;
      color: #817f7f;
      line-height: 12px;
      font: normal normal normal 16px/20px Segoe UI;
      @include breakpoint(lgdesktop) {
        margin-bottom: 10px;
        font: normal normal normal 12px/20px Segoe UI;
        height: 30px;
        align-items: center;
        display: flex;
      }
      input {
        width: 100%;
        border: none;
      }
    }
    .containerer {
      display: block;
      position: relative;
      padding-left: 30px;
      margin-bottom: 10px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      user-select: none;
      p {
        margin-bottom: 0;
        color: $black;
        font: normal normal normal 16px/20px Segoe UI;
        letter-spacing: 0px;
        @include breakpoint(lgdesktop) {
          font: normal normal normal 13px/16px Segoe UI;
        }
      }
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        border: 2px solid #000000cc;
      }
      &.checkmark:after {
        left: 5px;
        top: 2px;
        width: 7px;
        height: 10px;
        border: solid #707070;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}

.addCheckBox {
  height: 114px;
  overflow-y: auto;
}
