@import url('../../../global.css');
.riskconfig-container-main {
	height: 88vh;
	width: 96vw;
	margin: auto;
}
.riskConfigBody {
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: 3fr 9fr;
	overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.riskConfigBody::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.riskConfigBody {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.riskConfigTable {
	max-height: 380px;
}

.buttonSection {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0.5rem 1rem 0.5rem 1rem;
}

.inputField {
	min-width: 8vw;
	border-radius: 0.2rem;
	border: #010621 solid 0.1vw;
	padding: 0.1rem 0.2rem 0.1rem 0.2rem;
	width: 5rem;
	font-family: var(--def-font);
	font-size: var(--font-size);
}

.inputField:focus {
	color: orange !important;
	border: solid orange 2px !important;
	outline: none;
	box-shadow: none !important;
}

.riskConfigDashboard {
	margin-top: 3rem;
}

.searchArea {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding: 0 0 0 0.8rem;
}

.treeSection {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
