.table_head {
  font-weight: bold;
}

.dummy-space {
  height: 24px;
  width: 24px;
}

.row.no-gutters.parent {
  background-color: #ffffff;
  padding-top: 10px;
  padding-left: 2.75rem;
  padding-right: 2.75rem;
  justify-content: space-between;
}

.row.no-gutters.children {
  background-color: #ffffff;
  padding-top: 10px;
  padding-left: 2.75rem;
  padding-right: 2.75rem;
  justify-content: space-between;
}

.row.no-gutters.parent:nth-child(even) {
  background-color: #f4fbff;
}

.row.no-gutters.parent:nth-child(odd) {
  background-color: #ffffff;
}

.parent {
  background: #edf3ff;
}

.thead-row {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.thead-row:nth-child(even) {
  background-color: #f4fbff;
}

.row-right {
  display: flex;
  justify-content: space-around;
  flex-grow: 1;
}

.not-read {
  font-weight: bold !important;
}
