@import '../../../assets/scss/mixins.scss';
@import '../../../assets/scss/variable.scss';

.colormodal-global {
  .popup {
    // width: 778px; // SHUBHAM_POPUP_WIDTH 
    background: #fff;
    border-radius: 10px 10px 10px 10px !important;
    border-color: grey;
    border-style: solid;
    -webkit-box-shadow: 0 0 7px 2px $dark-btm-shadow !important;
    -moz-box-shadow: 0 0 7px 2px $dark-btm-shadow !important;
    box-shadow: 0 0 7px 2px $dark-btm-shadow !important;
    position: fixed;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    margin-top: 65px;

    @include breakpoint(lgdesktop) {
      margin-top: 50px;
      // width: 600px; // SHUBHAM_POPUP_WIDTH 
      background: #fff;
      border-radius: 10px 10px 10px 10px !important;

      &.all-popups {
        @include breakpoint(lgdesktop) {
          // max-height: 127px;
        }
      }

      &.success {
        @include breakpoint(lgdesktop) {
          min-width: 300px !important; // SHUBHAM_POPUP_WIDTH 
          max-width: 600px !important;
        }

        h2 {
          font-size: 22px;
          line-height: 29px;
          font-family: $roboto;
          font-weight: 500;
          color: #202062;

          @include breakpoint(lgdesktop) {
            font-size: 16px !important;
          }
        }

        .all-buttons {
          @include breakpoint(lgdesktop) {
          }
        }
      }
    }
    .create_2 {
      background: #fff;
      // height: 200px;
      border-radius: 10px 10px 10px 10px;
      border-width: 0px 5px 5px 5px !important;
      border-color: grey !important;
      // box-shadow: $dark-btm-shadow;
      h1 {
        margin-bottom: 0;
        background: transparent
          linear-gradient(180deg, #ff7474 0%, #6d0000 100%) 0% 0% no-repeat
          padding-box;
        box-shadow: $dark-btm-shadow;
        border-radius: 10px 10px 0px 0px;
        font-size: 25px;
        line-height: 33px;
        font-family: $roboto;
        font-weight: 500;
        color: #f3f5f9;
        padding: 22px 0 25px 30px;
        text-align: left;

        @include breakpoint(lgdesktop) {
          padding: 10px 0 10px 30px;
          font-size: 18px;
          line-height: 24px;
        }
      }
    }

    form {
      margin: 0px 100px 33px;
      border-radius: 10px 10px 0 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include breakpoint(lgdesktop) {
        margin: 25px 50px 25px 50px;
        border-radius: 10px 10px 0px 0px;
        margin: 25px 50px;
      }

      label {
        font: normal normal 18px/20px Segoe UI !important;
        width: 100% !important;
        font-family: 'Conv_rockb';
        margin-bottom: 0;
        font-size: 16px !important;
        margin-right: 28px !important;
        text-transform: capitalize;

        @include breakpoint(lgdesktop) {
          width: 335px !important;
          margin-right: -8px !important;
        }
      }

      &.create-modal {
        @include breakpoint(lgdesktop) {
          margin-top: 15px !important;
          margin-bottom: 15px !important;
        }

        .group {
          padding: 0px 4px 11px !important;
          text-align: left;
          display: flex;

          @include breakpoint(lgdesktop) {
            padding: 0px 4px 0px;
          }

          &.flx-base li {
            @include breakpoint(lgdesktop) {
              font-size: 10px !important;
              line-height: 30px;
            }
          }

          label {
            font: normal normal bold 16px/20px Segoe UI;
            width: 185px;
            font-family: 'Conv_rockb' !important;
            margin-bottom: 0;
            font-size: 16px !important;
            line-height: 31px !important;
            width: 165px !important;
            margin-right: 28px !important;

            @include breakpoint(lgdesktop) {
              font-size: 10px !important;
              width: 110px !important;
              line-height: 28px !important;
            }
          }

          textarea {
            background: #fff 0 0 no-repeat padding-box;
            box-shadow: $dark-btm-shadow;
            border: 1px solid #c3c3c3;
            padding: 10px;
            resize: none;
            border-radius: 10px;

            @include breakpoint(lgdesktop) {
              font-size: 10px;
            }
          }

          .errorwrapper {
            display: flex;
            flex-direction: column;
            input::-ms-reveal,
            input::-ms-clear {
              display: none;
            }

            input {
              border: 1px solid #c3c3c3;
              border-radius: 10px;
              background: #fff;
              font: normal normal normal 16px/21px Segoe UI;
              text-align: center;
              box-shadow: 0px 3px 6px #00000029 !important;

              @include breakpoint(lgdesktop) {
                font: normal normal normal 10px/18px Segoe UI;
              }
            }

            .error-tag {
              color: red !important;
              max-width: 345px;
              font-size: 10px;
            }

            .ca {
              margin-left: 7px;
              margin-bottom: 10px;
              display: inline-block;
              font-size: 12px !important;

              @include breakpoint(lgdesktop) {
                height: 16px;
                margin-bottom: 0;
                font-size: 10px !important;
              }
            }
          }

          .select {
            display: contents;

            label {
              font: normal normal 700 16px/20px Segoe UI;
              width: 185px;
              font-family: 'Conv_rockb' !important;
              margin-bottom: 0;
              margin-right: 28px !important;

              @include breakpoint(lgdesktop) {
                font-size: 10px !important;
                width: 110px !important;
                line-height: 28px !important;
              }
            }
          }

          select {
            width: 174px;
            height: 32px;
            background: rgba(0, 173, 51, 0.2) 0 0 no-repeat padding-box;
            text-align: center;
            text-align-last: center;
            border: none;
            background: #fff 0 0 no-repeat padding-box !important;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1607843137254902) !important;
            border: 1px solid #c3c3c3 !important;

            @include breakpoint(lgdesktop) {
              font-size: 10px !important;
              height: 27px;
            }
          }

          .dropdown-check-list {
            display: inline-block;
            margin-right: 10px;

            .anchor {
              position: relative;
              cursor: pointer;
              display: inline-block;
              padding: 5px 24px 5px 10px;
              width: 174px;
              height: 32px;
              text-align: center;
              text-align-last: center;
              background: #fff 0 0 no-repeat padding-box;
              box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1607843137254902) !important;
              border: 1px solid #bababa;

              @include breakpoint(lgdesktop) {
                font-size: 10px;
                height: 27px;
              }

              &.anchor:after {
                position: absolute;
                content: '';
                border-left: 2px solid #000;
                border-top: 2px solid #000;
                padding: 2px;
                right: 10px;
                top: 40%;
                -webkit-transform: rotate(-135deg);
                transform: rotate(-135deg);
              }
            }

            ul.items {
              display: block !important;
              border-radius: 0 !important;
              box-shadow: 0 3px 6px;
              padding: 2px 5px;
              display: none;
              margin: 0;
              border: 1px solid #ccc !important;
              border-top: none;
              position: absolute;
              width: 174px;
              background: #fff;
              z-index: 2;
              max-height: 195px !important;
              overflow-y: auto;

              @include breakpoint(lgdesktop) {
                height: 40px !important;
                align-items: center;
                display: flex;
                font: normal normal 700 16px/20px Segoe UI;
              }

              li {
                font: normal normal normal 16px/31px Segoe UI;
                letter-spacing: 0;
                color: #000;
                padding: 0 !important;
                list-style: none;
                cursor: pointer;

                @include breakpoint(lgdesktop) {
                  padding: 10px 22px;
                  font-size: 12px;
                }
                input::-ms-reveal,
                input::-ms-clear {
                  display: none;
                }

                input {
                  box-shadow: none !important;
                  text-align: center;
                  background: #fff;
                  border: 1px solid #c3c3c3;
                  border-radius: 10px;
                  color: black;

                  @include breakpoint(lgdesktop) {
                    font: normal normal normal 10px/18px Segoe UI;
                  }
                }

                span {
                  margin-left: 5px !important;
                  @include breakpoint(lgdesktop) {
                    margin-left: 40px;
                  }
                }
              }
            }
          }

          .data-colum {
            width: 190px;
            display: inline-block;

            @include breakpoint(lgdesktop) {
              width: 168px;
            }

            ul {
              height: 50px !important;
              margin-bottom: 0 !important;
              background: transparent !important;
              border: 1px solid #c3c3c3 !important;
              padding: 3px 5px;
              -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
              overflow-y: auto !important;
              border-radius: 0 !important;
              color: #fff;
              list-style: none;
              overflow: hidden;

              @include breakpoint(lgdesktop) {
                height: 40px !important;
                align-items: center;
                display: flex;
                font: normal normal 700 16px/20px Segoe UI;
              }

              li {
                font: normal normal normal 16px/31px Segoe UI;
                padding-right: 2px !important;
                padding-left: 2px !important;
                color: #000;
                cursor: pointer;

                @include breakpoint(lgdesktop) {
                  padding-top: 0 !important;
                  padding-bottom: 0 !important;
                  font-size: 10px !important;
                }
              }
            }
          }
        }
      }

      .error-tag {
        color: red !important;
        max-width: 345px;
        font-size: 10px;
      }

      .ca {
        margin-left: 7px;
        margin-bottom: 10px;
        display: inline-block;
        font-size: 12px !important;

        @include breakpoint(lgdesktop) {
          height: 16px;
          margin-bottom: 0;
          font-size: 10px !important;
        }
      }
      .sidealign-ca {
        @include breakpoint(lgdesktop) {
          margin-left: -70px;
        }
      }
    }
  }
  .button-req{
    text-transform: capitalize;
  }
}
