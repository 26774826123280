@import url('../../../global.css');

.TableGen2-table-dark {
	border-collapse: collapse;
}

.TableGen2-thead-tr-dark {
	height: 6vh;
	top: 0;
	position: sticky;
	background-color: #2e354b;
}

.TableGen2-container-dark {
	background-color: #2e354b;
	display: flex;
	flex-direction: column;
}

.TableGen2-tableSection-dark {
	height: 35vh;
	overflow: auto;
}

.TableGen2-thead-th-dark {
	color: var(--white);
	padding: 0 1vw 0 1vw;
	font-family: var(--def-font);
	border: 1px solid #cecece;
	font-size: var(--font-size);
	font-weight: 400;
}

.TableGen2-tbody-tr-td-dark {
	height: 5vh;
	font-family: var(--def-font);
	color: var(--white);
	padding: 0 1vw 0 1vw;
	border: 1px solid #cecece;
	font-size: var(--font-size);
}

.paginationGen2-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 6vh;
	width: fit-content;
	margin: auto;
	position: sticky;
	bottom: 1vh;
}

.paginationGen2-page-selected {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: white;
	border-radius: 3vh;
	height: 3vh;
	width: 3vh;
	font-size: var(--font-size);
	margin: 0 0.2vw 0 0.2vw;
}

.paginationGen2-page-notselected {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #707070;
	border-radius: 3vh;
	height: 3vh;
	width: 3vh;
	font-size: var(--font-size);
	margin: 0 0.2vw 0 0.2vw;
	cursor: pointer;
}
