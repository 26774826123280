@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';
@import url('../../global.css');

.upload-Modal {
	.modal-dialog.customWidth {
		max-width: 1300px;
		@include breakpoint(lgdesktop) {
			max-width: 1000px;
		}
	}
	.modal-dialog.customWidth .closeWrapper {
		right: 11px;
	}
	.modal-dialog.customWidth .modal-content {
		@include breakpoint(lgdesktop) {
			transform: translate(3%, 0);
		}
	}
	.modal-dialog.customWidth .excelContent {
		max-width: 400px;
	}
	.modal-dialog {
		max-width: 500px;
		.modal-content {
			border-radius: 30px !important;
			max-width: 100% !important;
			width: 100% !important;
			background: #fff;
			display: flex;
			padding-top: 65px;
			flex-direction: row;
			@include breakpoint(lgdesktop) {
				transform: translate(12%, 0);
			}
		}
	}
	.modalHeader {
		.closeWrapper {
			text-align: right;
			margin: 25px 25px 6px 0;
			width: 100%;
			position: absolute;
			top: -10px;
			right: -10px;
			img {
				@include breakpoint(lgdesktop) {
					height: 50px;
				}
			}
		}
		h1 {
			font: normal normal 600 36px/20px Poppins;
			text-align: center;
			margin-bottom: 0;
			margin-top: 20px;
			@include breakpoint(lgdesktop) {
				font: normal normal 600 24px/20px Poppins;
			}
		}
	}
	.modal-body {
		padding: 2rem 70px !important;
		@include breakpoint(lgdesktop) {
			padding: 2rem 40px !important;
		}
		label.btn {
			background: $orange;
			font: normal normal 500 20px/20px Poppins !important;
			color: $white;
			padding: 20px 0;
			width: 100% !important;
			margin-right: 0px !important;
			border: none;
			min-height: auto;
			border-radius: 15px;
			@include breakpoint(lgdesktop) {
				font: normal normal 500 14px/16px Poppins !important;
			}
			img {
				height: 52px;
				width: 62px;
				margin-right: 29px;
				@include breakpoint(lgdesktop) {
					height: 32px;
					width: 42px;
				}
			}
		}
		.dropfile {
			margin-top: 0 !important;
			border: 2px dashed $orange !important;
			background: $white !important;
			color: $black !important;
			pointer-events: none;
			padding: 18px 0 !important;
		}

		.or {
			font: normal normal 700 20px/20px Poppins;
			margin: 26px 0;
			text-align: center;
			@include breakpoint(lgdesktop) {
				margin: 15px 0;
			}
		}
		.downloadTemplate {
			font: normal normal 400 16px/20px Poppins;
			color: $black;
			text-align: center;
			margin-top: 60px;
			margin-bottom: 0;
			@include breakpoint(lgdesktop) {
				font: normal normal 400 12px/20px Poppins;
				margin-top: 30px;
			}
			a {
				color: $orange;
				font: normal normal 700 16px/20px Poppins;
				border-bottom: 1px solid $orange;
				text-decoration: underline;
				@include breakpoint(lgdesktop) {
					text-decoration: none;
					font: normal normal 700 12px/20px Poppins;
				}
			}
		}
	}
	.dzu-dropzone {
		border: none;
		overflow: hidden;
		min-height: 0;
		.dzu-previewContainer {
			order: 4;
			padding: 0px 0%;
			border-bottom: none;
			margin-top: 10px;
		}
		.dzu-previewButton {
			display: none;
		}
	}
	.submit-excel {
		width: 25%;
		border: none;
		border-radius: 5px;
		background-color: #0424c5d2;
		color: $white;
		margin-top: 10px;
		height: 30px;
		order: 5;
	}
	.progress {
		height: 10px;
		border: 10px;
		width: 88%;
		margin-right: 5px;
		margin-top: 4px;
	}
	.bg-warning {
		background-color: #f37c20 !important;
	}
	.custom-progress-green {
		.progress-bar {
			background-color: #3c9900 !important;
		}
	}
	.custom-progress-fail {
		.progress-bar {
			background-color: #f32020 !important;
		}
	}
	.custom-progress-grey {
		.progress-bar {
			background-color: grey !important;
		}
	}
	.dzu-previewStatusContainer {
		display: none;
	}
	.fixed-excel-layout {
		display: flex;
		flex-direction: column;
		h6 {
			order: 4;
			font: normal normal 500 16px/20px Poppins !important;
			padding: 50px 0 0px 10px;
			margin-bottom: 0;
			@include breakpoint(lgdesktop) {
				font: normal normal 500 12px/20px Poppins !important;
				padding: 30px 0 0px 10px;
			}
		}
		.mt-4 {
			margin-top: 0 !important;
		}
	}
	.fixed-excel-layout .custom-preview {
		order: 4;
		padding: 10px;
		display: flex;
		justify-content: space-between;
		@include breakpoint(lgdesktop) {
			padding: 4px 10px;
		}

		span {
			font: normal normal 500 16px/20px Poppins;
			@include breakpoint(lgdesktop) {
				font: normal normal 500 12px/20px Poppins;
			}
		}
	}

	.custom-progress-div {
		display: flex;
		align-items: center;
		img {
			height: 20px;
		}
	}
}

.excelError {
	width: 100%;
	border-left: 1px solid #707070;
	height: fit-content;
	h3 {
		font-family: var(--def-font);
		letter-spacing: 0px;
		color: #f02121;
		padding-left: 25px;
		padding-top: 17px;
		@include breakpoint(lgdesktop) {
			font-family: var(--def-font);
		}
	}
	.listing-error {
		padding: 25px 0 40px 25px;
		span {
			font-family: var(--def-font);
			letter-spacing: 0px;
			color: #000000;
			display: block;
			margin: 5px 0;
			@include breakpoint(lgdesktop) {
				font-family: var(--def-font);
			}
		}
	}
}

.excelContent {
	max-width: 100%;
	width: 100%;
}
