@import '../../../assets/scss/mixins.scss';
@import '../../../assets/scss/variable.scss';
@import url('../../../global.css');

.genric-table {
  .over-table {
    margin-bottom: 20px;
    @include breakpoint(lgdesktop) {
      // overflow-y: hidden;
    }
    table {
      @include breakpoint(lgdesktop) {
        margin-bottom: 0;
      }
      thead {
        tr {
          background: #f4f4f4 0% 0% no-repeat padding-box;
          border-radius: 10px !important;
          height: 60px;
          @include breakpoint(lgdesktop) {
            height: 37px;
            color: black !important;
          }
          th {
            font-family: var(--def-font);
            font-weight: 400;
            letter-spacing: 0px;
            color: black;
            text-align: left;
            border: none;
            vertical-align: middle;
            padding: 2px 0.5rem;
            text-transform: none;
            border: 1px solid #cecece;
            img {
              @include breakpoint(lgdesktop) {
                height: 37px;
                width: 37px !important;
              }
            }
          }
        }
        .risk-row {
          background: transparent;
          th {
            color: #0022a8;
          }
        }
      }
      tbody {
        position: relative;
        height: 60px;
        tr {
          td {
            // font-family: var(--def-font);
            // letter-spacing: 0px;
            // color: $black;
            // text-align: left;
            // vertical-align: middle;
            // padding: 10px 0px;
            // border-bottom: 1px solid var(--black);
            // @include breakpoint(lgdesktop) {
            //   padding: 0;
            //   font-family: var(--def-font);
            // }
            &.elipsis {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              .action {
                color: $green !important;
                text-transform: capitalize;
              }
              .block {
                color: $red !important;
                text-transform: capitalize;
              }
              .error-tag {
                color: $red !important;
                font-size: $sixteen !important;
                text-transform: capitalize;
                @include breakpoint(lgdesktop) {
                  font-size: $ten !important;
                }
              }
            }

            .buttons {
              button {
                font: normal normal bold 16px/21px Segoe UI;
                letter-spacing: 0px;
                color: $white;
                border: none;
                background: #0078d7;
                margin-right: 15px;
                @include breakpoint(lgdesktop) {
                  width: 72px;
                  font: normal normal 700 10px/21px Segoe UI !important;
                  @include breakpoint(desktop) {
                    font: normal normal 700 8px/21px Segoe UI !important;
                    width: 63px;
                  }
                }

                &:focus {
                  border: 2px solid orange;
                  background: orange !important;
                  color: #ffffff;
                }
                &:active {
                  border: none;
                  box-shadow: none;
                  outline: none;
                }
              }
            }
          }
        }
        tr.customrow {
          input {
            box-shadow: $dark-btm-shadow;
            border: 1px solid #c3c3c3;
            text-align: center;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff !important;
}
.table-striped tbody tr:nth-of-type(even) {
  // background-color: #f8fdff !important;
}
.pagination {
  margin-bottom: 0px;
  padding-bottom: 15px !important;
  @include breakpoint(lgdesktop) {
    padding-bottom: 5px !important;
  }
  .enteries {
    position: absolute;
    left: 2%;
  }

  p {
    font: normal normal normal 20px/9px Segoe UI;
    color: $black;
    margin-bottom: 0;
    @include breakpoint(lgdesktop) {
      font: normal normal normal 12px/9px Segoe UI;
    }
  }
  ul.pagination {
    background: none !important;
    border: none !important;
    border-radius: 0 !important;

    li {
      padding-right: 0 !important;
      padding-left: 0 !important;
      margin: 0 2px;
      a {
        background: #c6c6c6 !important;
        color: #fff !important;
        font-size: $twelve;
        line-height: $sixteen;
        font-family: $roboto;
        font-weight: 500;
        padding: 5px 9px;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        @include breakpoint(lgdesktop) {
          font-size: $eight;
          padding: 3px 6px;
        }
        &.active {
          background: #413f46 !important;
        }
      }
    }
  }
  .MuiPagination-ul {
    button {
      margin: 0 2px;
      background: #c6c6c6 !important;
      color: #fff !important;
      font-size: 12px;
      line-height: 16px;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      height: 24px;
      min-width: 24px;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      @include breakpoint(lgdesktop) {
        font-size: 9px;
      }
    }
    button.Mui-selected {
      background: #413f46 !important;
    }
    li:first-child button:hover {
      background: #413f46 !important;
    }
    li:last-child button:hover {
      background: #413f46 !important;
    }
  }
}
.norecord {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 5px;
}
.paginate {
  justify-content: center;
  @include breakpoint(lgdesktop) {
    height: 29px;
  }
  @include breakpoint(tablet) {
    justify-content: flex-end;
  }
}
