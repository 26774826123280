@import url('../../../global.css');
.infraPage-main-cont {
  background-color: #0078d7;
  height: 40vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.infraPage-title {
  color: white;
  font-family: var(--def-font);
  margin: auto;
  font-size: 4vw;
  font-weight: 700;
}

.infraPage-contArea {
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.infraPage-leftSec {
  height: 100%;
  width: 50%;
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  position: relative;
}

.infraPage-rightSec {
  height: 100%;
  width: 50%;
  /* border: 1px solid blue; */
}

.leftSecInfra-cicon {
  height: 20%;
  width: 40%;
}

.leftCenterLogo {
  height: 40vw;
  width: 40vw;
  padding: 10%;
  border: 2px dashed white;
  position: relative;
  border-radius: 40vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.infra-img-1 {
  position: absolute;
}

.infraPage-text-title {
  font-size: 4vw;
  font-family: var(--def-font);
  color: white;
  text-align: left;
  font-weight: 500;
}

.infraPage-text-body {
  font-size: 4vw;
  font-family: var(--def-font);
  color: white;
  text-align: left;
  font-weight: 300;
}
