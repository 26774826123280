@import url('../../../global.css');

:root {
  --gen-pad: 0.5vh 0.5vw 0.5vh 0.5vh;
}

#icon {
  height: 1.8vh;
  width: 1.8vh;
  margin: auto 0 auto auto;
}

.content {
  margin: 0;
  font-size: 1vw;
  margin-left: 0.2rem;
  font-family: 'Roboto', sans-serif;
}

.lightText {
  margin: 0;
  font-size: var(--font-size);
  margin-left: 0.2vw;
  font-family: var(--def-font);
  color: var(--black);
}

.centerContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.spec:hover {
  box-shadow: 2px 2px 10px #00000050;
  cursor: pointer;
}

.ddButton {
  border: 0.1rem solid #00000025;
  cursor: pointer;
}

.buttonContainer {
  width: fit-content;
  position: relative;
  height: var(--inDdInfieldSize);
}

.buttonContainer .centerContent:focus {
  color: #000;
  border: solid orange 1px;
  font-weight: 500;
  outline: none;
  box-shadow: none !important;
}

.dropdownMenu {
  width: fit-content;
  height: fit-content;
  max-height: 16rem;
  overflow: auto;
  position: absolute;
  left: 0;
  z-index: 2;
  background-color: white;
  /* box-shadow: 2px 2px 10px #00000022; */
  border-radius: 4px;
  border: var(--inDdInfieldBorder);
  -ms-overflow-style: none; /* IE and Edge */
}

.dropdownMenu2 {
  min-width: 15vw;
  max-width: fit-content;
  min-height: fit-content;
  max-height: 40vh;
  overflow-y: scroll;
  overflow-x: visible;
  scrollbar-width: thin;
  text-align: left;
  position: absolute;
  left: 0;
  z-index: 2;
  background-color: white;
  box-shadow: 2px 2px 10px #00000022;
  border-radius: 0.5rem;
  border: #6e6e76 solid 0.1vh;
  -ms-overflow-style: none; /* IE and Edge */
}
.dropdownMenu2::-webkit-scrollbar {
  /* display: none; */
}

.dropdownMenu3 {
  min-width: 15vw;
  max-width: fit-content;
  min-height: fit-content;
  max-height: 40vh;
  overflow-y: scroll;
  overflow-x: visible;
  scrollbar-width: thin;
  text-align: left;
  position: absolute;
  right: 0;
  z-index: 2;
  background-color: white;
  box-shadow: 2px 2px 10px #00000022;
  border-radius: 0.5rem;
  border: #6e6e76 solid 0.1vh;
  -ms-overflow-style: none; /* IE and Edge */
}
.dropdownMenu3::-webkit-scrollbar {
  /* display: none; */
}

.optionName {
  font-family: 'Roboto', sans-serif;
  width: fit-content;
  height: 2rem;
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0.4rem;
}

.optionName:hover {
  background-color: #00000025;
}

.contentPadding {
  padding: 0.4vw 0.8vw 0.4vw 0.8vw;
  border-radius: 0.5vh;
  cursor: pointer;
}

.optionContainer {
  width: fit-content;
}

.switchContainer {
  padding: 0;
  margin: 0;
}

.multiSelect {
  background: #ffffff;
  border: var(--inDdInfieldBorder);
}

.planeDropDown {
  min-width: 10vw;
  height: var(--inDdInfieldSize);
  background-color: #ffffff;
  white-space: nowrap;
  border: 1px solid #d5d5d5;
}

.planeTextOpt {
  cursor: pointer;
  text-align: left;
  margin: 0.5rem 1rem 0.5rem 1rem;
}

.subButton {
  background-color: white;
}

.GenericButton-disable {
  pointer-events: none;
  background-color: #80808033;
  background: #80808033;
  cursor: not-allowed;
}

.GenButton-cont-emt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  white-space: nowrap;
  height: fit-content;
  width: max-content;
  border: 1px solid var(--primary-color);
  border-radius: 0.5vh;
  padding: var(--gen-pad);
  cursor: pointer;
  
  transition: all 0.5s;
}

.GenButton-cont-emt:focus {
  border: solid orange 1px;
}

.GenButton-cont-emt-disabled {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: fit-content;
  width: max-content;
  border: 1px solid var(--primary-color);
  border-radius: 0.5vh;
  padding: 0.5vh 1vw 0.5vh 1vw;
  cursor: none;
  opacity: 0.5;
  transition: all 0.5s;
}

.GenButton-cont-emt:hover {
  transition: all 0.5s;
  box-shadow: 1vh 1vh 1vh #00000011;
  background-color: #eaf3ff;
  box-shadow: 0.5vh 0.5vh 1vh #00000011;
}

.GenButton-cont-icon {
}

.GenButton-text-emt {
  color: var(--primary-color);
  font-family: var(--def-font);
  margin: 0.3rem 0.5rem;
  font-size: var(--font-size);
}

.GenButton-icon-fill {
  height: var(--font-size);
  width: var(--font-size);
  margin: 0 0 0 0.5vw;
}

.GenButton-cont-fill {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  height: fit-content;
  width: max-content;
  border-radius: 0.5vh;
  padding: 0.5vh 1vw 0.5vh 1vw;
  cursor: pointer;
  box-shadow: 0px 0px 2vh #00000011;
}

.GenButton-cont-fill:focus {
  background-color: orange;
}

.GenButton-cont-fill:hover {
  background-color: #016abe;
}

.GenButton-cont-fill-disabled {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  height: fit-content;
  width: max-content;
  border-radius: 0.5vh;
  padding: 0.5vh 1vw 0.5vh 1vw;
  cursor: none;
  box-shadow: 0px 0px 2vh #00000011;
  opacity: 0.5;
}

.GenButton-text-fill {
  color: white;
  font-family: var(--def-font);
  margin: 0.3rem 0.5rem;
  font-size: var(--font-size);
}

.planeTextOpt {
  font-family: var(--def-font);
  color: var(--black);
  font-size: 2vh;
  /* border: 1px solid #000; */
  margin: 0;
  padding: 0.5vh 0 0.5vh 0.5vw;
}

.planeTextOpt:hover {
  background-color: #efefef88;
}

.border-black {
  border-color: #cecece !important;
}

.height-correct {
  height: 4.5vh !important;
  padding: 0 !important;
}
.height-correct .centerContent:focus {
  height: 4.5vh !important;
}
.height-correct .contentPadding {
  padding: 0.5vh 0.5vh 0.5vh 0.5vh !important;
}

.GenericButton-selection {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.GenericButton-error {
  border: 1px solid red;
}
