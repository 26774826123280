@import '../../../../assets/scss/variable.scss';
@import '../../../../assets/scss/mixins.scss';
.step_5 {
  background-color: #f4f4f4;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  display: flex;
  align-items: center;
  padding-left: 3vh;
  .heading {
    height: fit-content;
    font-weight: bold;
    margin: 1vh;
    font-family: 'Roboto', sans-serif;
    font-size: 2.4vh;
    font-weight: bold;
  }
  .icon {
    margin: 1vh 1vh 1vh 0;
    height: 3.5vh;
    width: 3.5vh;
  }
  .info {
    margin-left: 0.6vh;
    margin-right: 4vh;
  }
}

.condition {
  background: $white;
  padding: 3vh;
  .MuiInput-underline {
    padding-right: 10px !important;
    button {
      padding: 5px;
      img {
        margin-right: 0 !important;
      }
    }
  }

  .circle {
    margin-left: 10px;
  }
  .main {
    display: flex;
    align-items: center;
    .heading {
      margin-bottom: 0;
      margin-right: 1.5vh;
      font-weight: 500;
      font-size: 2.1vh;
      font-family: $roboto;
    }
  }
  .inputs {
    margin: 20px 0;
    .multiDropdown {
      .finalValueShow {
        margin: 0 14px;
      }
    }
    .first {
      select {
        width: 104px;
        height: fit-content;
        background: $white;
        box-shadow: 0px 0px 3px #00000029;
        border: 0.6000000238418579px solid #bab9b9;
        border-radius: 4px !important;
        display: block;
        font-size: 2.4vh;
      }
    }
    .second {
      margin-left: 40px;
      .group {
        label {
          font-size: 2.4vh;
          margin-right: 20px;
        }
        input {
          width: 104px;
          height: 34px;
          background: $white;
          box-shadow: $dark-btm-shadow;
          border-radius: 10px;
          display: inline;
          border: none;
          text-align: center;
        }
      }
    }
  }
  .multiply {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    select {
      width: 104px;
      height: 34px;
      background: $white;
      box-shadow: $dark-btm-shadow;
      border-radius: 10px;
      display: inline;
      border: none;
      font-size: 2vh;
    }
    input {
      width: 104px;
      height: 34px;
      background: $white;
      box-shadow: $dark-btm-shadow;
      border-radius: 10px;
      display: inline;
      border: none;
      text-align: center;
    }
    label {
      margin-right: 25px;
      font-size: 2.4vh;
    }
    .dropdown {
      margin-right: 10px;
    }
    .percent {
      margin-left: 10px;
    }
    .wrapper {
      select {
        box-shadow: none;
        width: 50px;
        font-size: 2vh;
      }
    }
  }
  .add {
    .centerContent {
      border-radius: 20px;
    }
    .centerContent:hover {
      background-color: #eaf6ff;
      p {
        color: #0078d7 !important;
      }
      img {
        filter: invert(35%) sepia(79%) saturate(1741%) hue-rotate(186deg)
          brightness(85%) contrast(103%);
      }
    }
    .disbaleClick {
      background-color: white;
      p {
        color: #9fadd6 !important;
      }
      img {
        filter: invert(69%) sepia(13%) saturate(648%) hue-rotate(187deg)
          brightness(96%) contrast(96%);
      }
    }
  }

  .square_off {
    display: flex;
    border: 1px solid #5bc0be;
    border-radius: 10px;
    padding: 3px 15px;
    align-items: center;
    @include breakpoint(lgdesktop) {
      padding: 0 15px;
      .multiDropdown {
        padding: 7.5px 14px !important;
      }
    }
    .selectedAnswer p {
      margin-top: 0;
      margin-bottom: 0;
    }
    .head {
      p {
        font: normal normal 500 1.2.4vh/1.2.4vh Segoe UI;
        margin-bottom: -2px;
        color: $black;
        @include breakpoint(lgdesktop) {
          font-size: 2.1vh;
        }
      }
    }
    select {
      height: 34px;
      background: $white;
      box-shadow: $dark-btm-shadow;
      border-radius: 10px;
      display: inline;
      border: none;
      font-size: 2vh;
    }
    .multiDropdown {
      @include breakpoint(lgdesktop) {
        .mainWrapperDropdown {
          top: 50px;
        }
      }
    }
  }
  .action {
    text-align: left;
    font-size: 2.2vh;
    line-height: 19px;
    letter-spacing: 0px;
    color: #63a8ed;
    margin-bottom: -6px;
  }
}

.action {
  text-align: left;
  font-size: 2.2vh;
  line-height: 19px;
  letter-spacing: 0px;
  color: #63a8ed;
  p {
    margin-bottom: 1.9vh;
  }
}

.strategy .condition .multiply .wrapper_2,
.wrapper_3,
.wrapper_4,
.wrapper_5 {
  display: flex;
  align-items: center;
}

.strategy .condition .multiply .wrapper_2,
.wrapper_4 {
  margin: 0px 30px;
}

.inputs {
  .first {
    select {
      margin-right: 50px;
      width: 100% !important;
    }
  }
  .condition {
    height: 100%;
    padding: 0;
    box-shadow: none;

    span {
      display: flex;
      align-items: center;
      text-transform: capitalize;
      font-family: $roboto;
      justify-content: space-evenly;
      label {
        white-space: nowrap;
        display: none;
      }
      .MuiGrid-container {
        width: 35%;
        .MuiFormControl-marginNormal {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
  .MuiInput-underline {
    background: #fff 0 0 no-repeat padding-box !important;
    box-shadow: $dark-btm-shadow;
    border: 1px solid #c3c3c3 !important;
    padding: 0px 0px 0px 15px;
    border-radius: 10px;
    &:before {
      border-bottom: 0 !important;
    }
    .MuiInputBase-input {
      padding-left: 6px !important;
    }
    .MuiInputAdornment-positionEnd {
      margin-left: -5px !important;
    }
  }
}

.entry-conditon-wrapper {
  .circle {
    position: absolute;
  }
}

.ops {
  margin-right: 15px;
  width: 100px;
}

.sepratewithcross {
  position: relative;
  .circle {
    position: absolute;
    top: 9px;
    right: 0;
  }
}

.exitCondition {
  .selectedAnswer p {
    margin-top: 0;
    margin-bottom: 0;
    padding: 10px 0;
  }
  .inputs {
    margin: 15px 0;
    background-color: white;
    .ops {
      .form-control {
        width: 100px;
        height: 34px;
        background: #ffffff;
        box-shadow: $dark-btm-shadow;
        border-radius: 10px;
        display: inline;
        border: none;
        text-align: center;
      }
    }
  }
}

.step4-overlay {
  margin-left: 160px !important;
  margin-bottom: 17px !important;
  .arrow::before {
    top: 5px !important;
    margin-left: -155px !important;
  }
}

.step-4-info {
  img {
    margin-left: 15px;
  }
}
