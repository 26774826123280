@import url('../../../global.css');

.dynamic-field-editor-container {
  border: 1px solid #cecece;
  border-radius: 1vh;
}

.dynamic-field-editor-container-s1 {
  display: flex;
  flex-direction: row;
  height: 5vh;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid #cecece;
}

.dynamic-field-editor-title {
  font-family: var(--def-font);
  font-size: var(--font-size);
  font-weight: 500;
}

.dynamic-field-editor-container-s2 {
  display: flex;
  flex-direction: column;
  min-height: 40vh;
}
