@import url('../../../../global.css');

.remark-button-cont {
  /* width: fit-content; */
  height: 3vh;
  border: 1px solid #cecece;
  border-radius: 0.5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.remark-button-cont:hover {
  border: 1px solid #0078d7;
}

.remark-btn-title {
  font-size: var(--font-size);
  color: var(--black);
  font-family: var(--def-font);
  margin: 0 0 0 1vw;
  white-space: nowrap;
}

.remark-btn-icon {
  height: 1.2vh;
  width: 1.2vh;
  rotate: -90deg;
  margin-right: 0.5vw;
}
