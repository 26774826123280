.headContainer {
  height: 3rem;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 10fr;
  background: #ffffff;
  border-radius: 12px 12px 0px 0px;
  /* border: #cecece solid 0.1vh; */
  border-bottom: #cecece solid 0.2vh;
  margin: 0;
}
.pHeader {
  color: #171725;
  height: 100%;
  padding: 10px;
  font-weight: 600;
  margin: 0;
  font-size: large;
}
.sHeader {
  color: #171725;
  height: 100%;
  padding: 10px;
  font-weight: 500;
  margin: 0;
  font-size: large;
}
