.popup-back-closed {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.popup-back-open {
  height: 100vh;
  width: 100vw;
  visibility: visible;
  position: absolute;
  top: 0;
  background-color: #00000055;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-container-active {
  position: absolute;
  z-index: 51;
  top: 10vh;
  left: 10vw;
  right: 10vw;
  bottom: 10vh;
  background-color: white;
  min-height: 70vh;
  max-height: 80vh;
  width: fit-content;
  border-radius: 2vh;
}

.popup-container-inactive {
  visibility: hidden;
  height: 0;
  width: 0;
}
