$def-font: 'Roboto', sans-serif;
$black: #5b5b5f;
$white: #fff;
$dark-black: #171725;
$fl1: 1em;
$fl2: 1.2em;
$fl3: 1.5em;
$coPrimary: #0078d7;
$border-radius: 1vh;
$table-border: 1px solid #cecece !important;
$focus: #ffc107;
$inDdInfieldSize: 4.5vh;
$inDdInfieldBorder: 1px solid #cecece;
