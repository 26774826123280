@import url('../../../global.css');

.custom-marketwatch-container {
	height: fit-content;
	width: 100%;
    overflow: visible;
}

.custom-market-watch-topBar {
	height: 8vh;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.custom-market-watch-ddContainer {
	height: 5vh;
	width: 20vw;
	border: 1px solid #cecece;
	border-radius: 1vh;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: auto 1vw auto 1vw;
	cursor: pointer;
	position: relative;
}

.custom-market-watch-ddContainer:hover {
	border: 1px solid var(--dark-black);
}

.custom-market-watch-dd-title {
	font-family: var(--def-font);
	font-size: var(--font-size);
	color: var(--dark-black);
	margin-left: 1vw;
}

.custom-market-watch-dd-icon {
	height: 2vh;
	width: 2vh;
	margin: 0 1vw 0 auto;
}
