@import url('../../../global.css');

.TableSettingsV2-gear-conatiner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 4rem;
	cursor: pointer;
	user-select: none;
	margin: 0.5rem;
	transition: all 0.2s;
	border: 1px solid #cecece;
}

.TableSettingsV2-gear-conatiner:active {
	opacity: 0.6;
}

.TableSettingsV2-gear-conatiner:hover {
	box-shadow: 0.1rem 0.1rem 0.5rem #00000033;
}

.TableSettingsV2-gear-conatiner:focus {
	border: 1px solid var(--focus);
}

.TableSettingsV2-main-icon {
	height: 1.5rem;
	width: 1.5rem;
	margin: 0.5rem;
}

.TableSettingsV2-dropdown {
	width: fit-content;

	position: absolute;
	top: 3.2rem;
	right: 0;
	background-color: white;
	z-index: 999;
	border-radius: 4px;
	border: 1px solid #cecece;
	box-shadow: 0.1rem 0.1rem 0.3rem #00000033;
	overflow: auto;
}

.TableSettingsV2-conaitner {
	height: fit-content;
	width: fit-content;
	position: relative;
}

.TableSettingsV2-parent-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	min-width: 15rem;
	border-radius: 4px;
	border: var(--inDdInfieldBorder);
	margin: 0.2rem;
	cursor: pointer;
	transition: all 0.3s;
}

.TableSettingsV2-parent-container:active {
	opacity: 0.6;
}

.TableSettingsV2-parent-container:hover {
	border: var(--borderHover);
}

.TableSettingsV2-parent-icon {
	height: 2rem;
	width: 2rem;
	margin: 0.5rem 0 0.5rem 0.5rem;
}

.TableSettingsV2-parent-label {
	margin: 0.5rem 0.5rem;
}

.TableSettingsV2-child-contaner {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 13rem;
	border-radius: 4px;
	border: var(--inDdInfieldBorder);
	margin: 0.2rem 0.2rem 0.2rem auto;
	cursor: pointer;
	transition: all 0.3s;
}

.TableSettingsV2-child-label {
	margin: 0.5rem;
}

.TableSettingsV2-child-img {
	height: 1rem;
	width: 1rem;
	transform: rotate(-90deg);
	margin: 0 0.5rem 0 auto;
}

.ColumnReArrange-container {
	height: 25rem;
	width: 15rem;
	overflow-y: auto;
}

.ColumnReArrange-h1 {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 2rem;
}

.ColumnReArrange-icon {
	height: 1rem;
	width: 1rem;
	margin: 0 0 0 0.5rem;
	cursor: pointer;
}

.ColumnReArrange-title {
	margin: 0 1rem 0 1rem;
}

.ColumnReArrange-h2 {
	height: 20rem;
	overflow-y: auto;
}

.ColumnReArrange-h3 {
	height: 3rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
}
