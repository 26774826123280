@import url('../../../global.css');

.excel-prev-table {
	border-collapse: collapse;
	border: 1px solid #e2e2e2b5;
	overflow-x: scroll;
}

.excel-prev-table2 {
	width: 100%;
	border: 1px solid #e2e2e2b5;
}

.excel-prev-td {
	border: 1px solid #e2e2e2b5;
	border-collapse: collapse;
	margin: 0;
	position: relative;
}

.excel-prev-head-td {
	border: 1px solid #e2e2e2b5;
	border-collapse: collapse;
	margin: 0;
	position: relative;
	/* background-color: #fafeff; */
	background: #fafeff;
}

.excel-prev-th {
	font-family: var(--def-font);
	font-size: 2vh;
	font-weight: bold;
	color: #5b5b5f;

	margin: 1.5vh 0 1.5vh 1vw;
}

.excel-prev-th1 {
	height: 5vh;
	padding: 0.8vh 1vw 0.8vh 1vw;
	font-family: var(--def-font);
	border: 1px solid #e2e2e2b5;
	background-color: #f8f8f8;
}

.excel-prev-th2 {
	height: 5vh;
	min-width: 10vw;
	padding: 0.8vh 1vw 0.8vh 1vw;
	font-family: var(--def-font);
	border: 1px solid #e2e2e2b5;
	background-color: #f8f8f8;
}

.excel-prev-tabContainer {
	display: flex;
}

.excel-prev-td-text {
	font-family: var(--def-font);
	font-size: 2vh;
	margin: 0.8vh 1vw 0.8vh 1vw;
	white-space: nowrap;
}

.excel-prev-th1-text {
	font-family: var(--def-font);
	font-size: 2vh;
	/* margin: 0.8vh 1vw 0.8vh 1vw; */
	font-weight: 300;
	color: #32323f;

	white-space: nowrap;
}

.excel-prev-key-val-cont {
	margin: 1vh 1vw 1vh 1vw;
}

.excel-prev-t1-t2 {
	padding: 0.5vh;
	border-radius: 0.5vh;
	margin-left: 0.5vw;
	font-size: var(--font-size);
}

.excel-prev-remark-cont {
	width: 90%;
	display: flex;
	flex-direction: row;
	margin: 1vh 1vh;
}

.excel-prev-remark-title {
	font-family: var(--def-font);
	font-size: var(--font-size);
}

.excel-prev-remark-val {
	font-family: var(--def-font);
	margin-left: 1vw;
	font-size: var(--font-size);
}

.excel-prev-sno {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.sticky-col {
	position: -webkit-sticky;
	position: sticky;
}

.excel-prev-t1-t1 {
	white-space: nowrap;
	font-size: var(--font-size);
}

.empty-row-excel-prev {
	height: 4vh;
	border-bottom: 1px solid #e2e2e2b5;
}

.error-message-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.error-title {
	margin: 0.5rem 1rem;
}

.error-msgContainer {
	border: var(--inDdInfieldBorder);
	padding: 0.3rem 0.5rem;
	width: 100%;
}

.excelPreview-selection-container {
	height: 3rem;
	display: flex;
	flex-direction: row;
	align-items: center;
}
