@import url('../../../global.css');

.mulSeSelec-cont {
  height: 15rem;
  width: 100%;
  border-radius: 0.5vh;
  border: 1px solid #cecece;
  display: flex;
  flex-direction: column;
}

.SearchMultiSelect-searchArea {
  height: 30%;
  width: 100%;
  overflow: auto;
  display: flex;
}

.SearchMultiSelect-bodyarea {
  height: 70%;
  width: 100%;
  overflow: auto;
  display: flex;
}

.mul-select-search-cont {
  height: 80%;
  width: 96%;
  margin: auto;
  border-radius: var(--border-radius);
  border: 1px solid #00000029;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.mul-select-search-icon {
  height: 1.5rem;
  width: 1.5rem;
  margin: 0.5rem;
}

.mul-select-search-input {
  outline: none;
  border: none;
  font-family: var(--def-font);
  font-size: var(--font-size);
  width: 100%;
}

.mul-select-search-input::placeholder {
  font-family: var(--def-font);
  font-size: var(--font-size);
  line-height: 1rem;
  letter-spacing: 0px;
  font-weight: 400;
}

.mul-select-options-area {
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;
}

.mul-select-opt-option {
  padding: 0vh 2vh;
  cursor: pointer;
}

.active-mul-select {
  border: var(--borderHover);
}
