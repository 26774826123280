.sepratewithcross {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .actionBtn {
    display: flex;
    padding: 8px;
    align-items: center;
    .addCloseBtn {
      padding: 5px;
      height: 23px;
      width: 23px;
    }
  }
}

.addBlueBtn {
  height: 20px;
  width: 20px;
  margin: 6px;
}

.MuiInputBase-input {
  padding-left: 15px !important;
}
.entryCondition {
  background-color: white;
  .first {
    width: 200px;
  }

  .ops {
    width: 80px;
    margin-right: 35px;
  }
}

.inputs .col {
  font-size: 2.1vh;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.inputs .first select {
  margin-right: 10px !important;
}

.leg_1 .inputs select {
  margin-bottom: 0px;
  background: #fff;
  box-shadow: 0px 0px 3px #00000029 !important;
  border: 0.6000000238418579px solid #2e354b !important;
  border-radius: 4px !important;
  height: fit-content !important;
  color: #171725;
  opacity: 0.65;
}

.closeEnty {
  box-shadow: 0px 1px 3px #00000029 !important;
}
