@import url('../../global.css');

.gen-table-th {
  background-color: #f4f4f4;
  font-family: var(--def-font);
  font-size: calc(var(--font-size));
  height: 4vh;
  white-space: nowrap;
}

.gen-table-td {
  border: 1px solid #cecece;
  padding: 0 1vw 0 1vw;
}

.gen-table-tr:nth-child(even) {
  background-color: #f4fbff;
}
