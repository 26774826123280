.table-monitor {
  min-height: 40vh;
  max-height: 80vh;
  height: 90vh;
  width: 100%;
  display: grid;
  grid-template-columns: 2.5fr 9fr;
  overflow-y: scroll;
  overflow-x: scroll;
}

.modeIcon {
  height: 0.75rem;
  width: 0.75rem;
  margin-right: 0.35vw;
}

.header-container {
  display: flex;
  align-items: center;
  padding: 0.5vh 0vh;
}

/* Check if below impact some other table -- Should not influence as nested script*/
.table .thead .tr-header .th div {
  display: flex;
}

.table .thead .tr-header .th div input {
  margin: auto;
}

.table .thead .tr-header th {
  height: 100%;
}
/* Check if above impact some other table -- Should not influence as nested script*/

.number {
  text-align: center;
}

.search {
  flex-grow: 1;
}

.search .search-container-x input {
  width: 40vw;
}

.cell {
  padding: 2px 5px;
  border-top: 1px solid #a0a8ae;
  white-space: initial;
  min-height: 2rem;
  max-height: 2rem;
}

.cell-tree {
  padding-top: 8px;
  border-right: 1px solid #a0a8ae;
  /* border-left: 1px solid #a0a8ae; */
  border-top: 1px solid #a0a8ae;
  border-bottom: 1px solid #a0a8ae;
}

.cell-select {
  padding-top: 9px;
  border-top: 1px solid #a0a8ae;
  border-right: 1px solid #a0a8ae;
}

.redBk {
  background-color: #e44125;
  padding: 5px 5px;
}

.greenBk {
  background-color: #c1ffb2;
  padding: 5px 5px;
}

.whitrBk {
  background-color: white;
  padding: 5px 5px;
}

.mainPanelLogLive {
  height: 5rem;
  width: 50%;
  margin-left: 1rem;
  background-color: #ffffff;
  border-radius: 6px;
  display: grid;
  grid-template-columns: 50% 48% 2%;
}

.optionsLogLive {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  border: 1px solid #cecece;
  border-radius: 3px;
  padding: 0.25rem;
}

.btn {
  padding: 1vw;
}

.textCont2 {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black;
  margin: auto 1rem auto 1rem;
  font-size: 1.1rem;
  font-family: 'Roboto', sans-serif;
}

.liveButton {
  margin-left: 10px;
}

.page-div {
  min-height: 80vh;
  max-height: 100vh;
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.header-text {
  white-space: initial;
  min-height: 1.7rem;
  max-height: 1.7rem;
}

.header-select {
  min-height: 3rem;
  max-height: 3rem;
}

.ldu {
  font: normal normal normal 11px/14px Roboto;
  font-weight: regular;
}

.bTag {
  width: 9rem;
  display: inline-block;
}

.logLiveConformation {
  width: 20vw;
  display: flex;
  flex-direction: column;
}

.logLiveConformation-title {
  width: 100%;
  height: 7vh;
  color: white;
  background-color: #067bd8;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 13px/21px Roboto;
  font-weight: 450;
  padding-left: 0.75vw;
  margin-left: 1vw;
}

.logLiveConformation-bottom-container {
  height: 6vh;
  width: 100%;
  background-color: #efefef;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 10vw;
}

.percentage-text {
  font-size: 10px;
}

.search-container-x {
  display: flex;
  flex-direction: row;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  margin: auto;
  border: 1px solid #a0a8ae;
}

.nameIconCell {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.3vw;
}

.nameIconCellLeftAlign {
  display: flex;
  align-items: center;
  margin-left: 0.3vw;
}

.noteTextRM {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-left: 1vw;
}
