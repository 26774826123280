.table-monitor {
  min-height: 40vh;
  max-height: 80vh;
  height: 90vh;
  width: 100%;
  display: grid;
  grid-template-columns: 2.5fr 9fr;
  overflow-y: scroll;
  overflow-x: scroll;
}

.modeIcon {
  height: 0.75rem;
  width: 0.75rem;
}

.header-container-x {
  display: flex;
  align-items: center;
  padding: 0.5vh 0vh;
}

.number {
  text-align: center;
}

.search {
  flex-grow: 1;
}

.search .search-container-c input {
  width: 40vw;
}

.search-container-c {
  display: flex;
  flex-direction: row;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  margin: auto;
  border: 1px solid #a0a8ae;
}

.cell-x {
  /* padding: 5px 5px;
  border-bottom: 1px solid #a0a8ae;
  border-right: 1px solid #a0a8ae;
  min-height: 2rem;
  max-height: 2rem; */

  padding: 2px 5px;
  border-bottom: 1px solid #a0a8ae;
  border-right: 1px solid #a0a8ae;
  white-space: initial;
  min-height: 2rem;
  max-height: 2rem;
}

.redBk {
  background-color: #e44125;
  padding: 5px 5px;
}

.greenBk {
  background-color: #c1ffb2;
  padding: 5px 5px;
}

.whitrBk {
  background-color: white;
  padding: 5px 5px;
}

.mainPanelLogLive {
  height: 5rem;
  width: 50%;
  margin-left: 1rem;
  background-color: #ffffff;
  border-radius: 6px;
  display: grid;
  grid-template-columns: 50% 48% 2%;
}

.optionsLogLive {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  border: 1px solid #cecece;
  border-radius: 3px;
  padding: 0.25rem;
}

.btn {
  padding: 1vw;
}

.textCont2 {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black;
  margin: auto 1rem auto 1rem;
  font-size: 1.1rem;
  font-family: 'Roboto', sans-serif;
}

.liveButton {
  margin-left: 10px;
}

.page-div {
  min-height: 80vh;
  max-height: 100vh;
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.header-text_x {
  white-space: initial;
  min-height: 1rem;
  max-height: 1rem;
}

.header-select {
  min-height: 3rem;
  max-height: 3rem;
}

.ldu-x {
  font: normal normal normal 11px/14px Roboto;
  font-weight: regular;
}

.bTag-x {
  width: 8rem;
  display: inline-block;
}

.percentage-text-x {
  font-size: 10px;
}

.cell-tree-pre{
  padding-top: 8px;
  border-right: 1px solid #a0a8ae;
  /* border-left: 1px solid #a0a8ae; */
  /* border-top: 1px solid #a0a8ae; */
  border-bottom: 1px solid #a0a8ae;
}