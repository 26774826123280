@import '../../../assets/scss/variable.scss';
@import '../../../assets/scss/mixins.scss';
.multiDropdown {
  input.finalValueShow {
    background: $white;
    box-shadow: $dark-btm-shadow;
    font-family: $roboto;
    font-size: 1.2em;
    line-height: 0.8em;
    border: none;
    padding: 6px 15px;
    border-radius: 10px;
    width: 299px;
    margin: 14px;
    @include breakpoint(lgdesktop) {
      width: 245px;
    }
    font-weight: 400;
  }
  input.finalValueShow:focus {
    border: solid orange 2px !important;
    outline: none;
    box-shadow: none !important;
  }
  .formWrapper {
    box-shadow: $dark-btm-shadow;
    background: $white;
    border-radius: 10px;
    padding: 12px 30px;
    margin-top: 14px;
    margin-left: 15px;
    label {
      font-family: $roboto;
      font-size: 0.8em !important;
      color: $black;
      line-height: 1.5em !important;
      background: $white;
      text-align: left;
      margin-bottom: 5px;
      margin-top: 15px;
      font-weight: 500;
      display: block;
      width: 100% !important;
      text-transform: capitalize;
      &:first-child {
        margin-bottom: 15px;
      }
    }

    select {
      background: $white;
      box-shadow: $dark-btm-shadow;
      font-family: $roboto;
      font-size: 0.8em;
      color: $black;
      line-height: 0.8em;
      border: 1px solid #c3c3c3;
      border-radius: 10px;
      position: relative;
      height: 36px !important;
      width: 100% !important;
      margin-bottom: 10px;
      &:after {
        content: ' ';
        background: url('../../../assets/img/down-arrow-icon-small.png') 0 0
          no-repeat;
        position: absolute;
        background-position: 0 0;
        height: 12px;
        width: 15px;
        right: 15px;
      }
    }
  }
  input {
    box-shadow: $dark-btm-shadow;
    font-family: $roboto;
    font-size: 0.8em;
    line-height: 0.8em;
    height: 36px !important;
    border: 1px solid #c3c3c3;
    -webkit-appearance: none;
  }
  input:focus {
    border: solid orange 2px !important;
    outline: none;
    box-shadow: none !important;
  }
  select {
    background: $white;
    box-shadow: $dark-btm-shadow;
    font-family: $roboto;
    font-size: 0.8em;
    color: $black;
    line-height: 0.8em;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    position: relative;
    height: 36px !important;
    width: 100% !important;
    margin-bottom: 10px;
    &:after {
      content: ' ';
      background: url('../../../assets/img/down-arrow-icon-small.png') 0 0
        no-repeat;
      position: absolute;
      background-position: 0 0;
      height: 12px;
      width: 15px;
      right: 15px;
    }
  }
}
select:focus {
  border: solid orange 2px !important;
  outline: none;
  box-shadow: none !important;
}
.mainWrapperDropdown {
  position: absolute;
  background: $white;
  box-shadow: $dark-btm-shadow;
  border-radius: 10px;
  padding: 6px;
  z-index: 9;
  width: 299px;
  margin-left: 14px !important;
  margin-top: 10px;
  @include breakpoint(lgdesktop) {
    width: 230px;
  }
  top: 40px;
  li {
    @include breakpoint(lgdesktop) {
      padding-top: 0px;
    }
    span {
      cursor: pointer;
      text-align: left;
      text-transform: capitalize;
      background: #007bff40;
      color: $black;
      font-family: $roboto;
      font-size: 1em;
      line-height: 0.8em;
      margin: 2px 0;
      width: 100%;
      display: block;
      padding: 13px 14px;
      position: relative;
      &:after {
        content: ' ';
        background: url('../../../assets/img/down-arrow-icon-small.png') 0 0
          no-repeat;
        position: absolute;
        background-position: 0 0;
        height: 12px;
        width: 15px;
        right: 15px;
      }
    }
    ul {
      background: #e1e6ea;
      padding: 4px 13px;
      height: auto;
      max-height: 200px;
      overflow-y: auto;
      li {
        cursor: pointer;
        border-radius: 5px;
        background: $white;
        color: $black;
        font-family: $roboto;
        font-size: 1em;
        line-height: 1em;
        padding: 14px 17px;
        text-align: left;
        margin: 2px 0;
      }
    }
  }
}

.selectedAnswer {
  position: relative;
  min-width: 20px;
  padding: 8px 5px;
  font-family: $roboto;
  cursor: pointer;
  margin-left: 10px;
  p {
    margin-top: 18px;
  }
}

.selectedAnswer:hover {
  background-color: #007bff40;
  padding: 8px 5px;
  border-radius: 5px;
}

.selectedAnswer:hover .answerBtn {
  display: unset;
}

.answerBtn {
  top: -10px;
  right: -7px;
  width: 18px;
  border: 0;
  cursor: pointer;
  height: 18px;
  display: none;
  z-index: 99;
  position: absolute;
  background: #ff001f;
  border-radius: 50%;
}

.answerSvg {
  color: #fff;
  padding-top: 0px;
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 15px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
  margin-top: 1.4px;
  margin-left: 0.8px;
}

svg {
  vertical-align: top !important;
}

.min-max {
  display: flex;
  input {
    width: 50% !important;
    margin: 0 5px;
  }
}
.buttons-wrapper {
  display: flex;
  margin-top: 15px;
  button {
    width: 50%;
    margin: 5px;
  }
}

// as a modal
.formWrapper {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99;
  width: 234px;
}

input::placeholder {
  font-family: $roboto;
  font-weight: 400;
  font-size: 0.8em;
}

.operatorDropdown {
  background: #e1e6ea;
  padding: 4px 13px;
  height: auto;
  max-height: 190px;
  overflow-y: auto;
  li {
    cursor: pointer;
    border-radius: 5px;
    background: #ffffff;
    color: #000000;
    font-family: $roboto;
    font-size: 1em;
    line-height: 1em;
    padding: 14px 17px;
    text-align: center;
    margin: 2px 0;
  }
}
