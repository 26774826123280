.feedback-popup-container-ct {
  width: 45vw;
  display: flex;
  flex-direction: column;
}

.title-container-ct {
  width: 100%;
  height: 7vh;
  color: white;
  background-color: #067bd8;
  display: flex;
  align-items: center;
  justify-content: left;
  font: normal normal 13px/21px Roboto;
  font-weight: 450;
}

.title-container-left-ct {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 0.75vw;
}

.title-container-right-ct {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 0.75vw;
}

.switch-container-ct {
  width: 100%;
  height: 24vh;
}

.body-container-ct {
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: left;
  font: normal normal 12px/21px Roboto;
  font-weight: 400;
}

.body-container-left-ct {
  width: 40%;
  /* display: flex; */
  align-items: center;
  justify-content: left;
  padding-left: 0.75vw;
}

.body-container-right-ct {
  width: 60%;
  /* display: flex; */
  align-items: center;
  justify-content: left;
  padding-right: 0.75vw;
}

.template-name-input {
  flex-grow: 1;
  margin-top: 0.5vh;
}

.template-name-input input {
  width: 16vw;
  height: 5vh;
}

.description-input {
  flex-grow: 1;
  margin-top: 0.5vh;
}

.description-input input {
  width: 26vw;
  height: 5vh;
}

.title2-container-ct {
  display: flex;
  flex-direction: row;
  background-color: #efefef;
  width: 100%;
  height: 5vh;
}

.title2-container-child-left-ct {
  width: 22%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  font: normal normal 12px/17px Roboto;
  font-weight: 450;
  padding-left: 0.75vw;
}

.title2-container-child-right-ct {
  width: 13%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  font: normal normal 12px/17px Roboto;
  font-weight: 450;
  /* padding-right: 0.75vw; */
}

.body-row-container-white-ct {
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 100%;
  height: 5vh;
  border-top: thin solid rgb(235, 235, 235);
  background-color: #ffffff;
}

.body-row-container-blue-ct {
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 100%;
  height: 5vh;
  border-top: thin solid rgb(235, 235, 235);
  background-color: #eef8ff;
}

.body-row-container-child-left-ct {
  width: 22%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  font: normal normal 12px/17px Roboto;
  font-weight: 400;
  padding-left: 1vw;
  /* border-right: thin solid rgb(235, 235, 235); */
}

.body-row-container-child-right-ct {
  width: 13%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  font: normal normal 12px/17px Roboto;
  font-weight: 400;
  padding-right: 0.75vw;
}

.feed-popup-bottom-ct {
  height: 6vh;
  width: 100%;
  background-color: #efefef;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 10vw;
}
