@import url('../../../global.css');
.treeMenuGen {
	height: 100%;
	width: 95%;
}

/* .treeOptCont {
  display: grid;
  grid-template-columns: 6fr 5fr 1.5fr;
  padding: 0.2rem;
  margin: 0.1rem;
  cursor: pointer;
  border-radius: 0.2rem;
  transition: background-color 0.3s;
} */

.treeOptCont {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	padding: 0.2rem;
	margin: 0.1rem;
	cursor: pointer;
	border-radius: 0.2rem;
	transition: background-color 0.3s;
}
.treeOptCont:hover {
	background-color: #00000015;
}

.treeOptCont:focus {
	color: orange !important;
	border: solid orange 2px !important;
	outline: none;
	box-shadow: none !important;
}

.selected {
	/* background-color: #00000015; */
}

.iconObj {
	height: 1rem;
	width: 1rem;
	margin: 0.2rem;
}

.title {
	margin: 0;
	font-family: var(--def-font);
	font-size: var(--font-size);
	/* transform: rotate(0deg); */
	/* margin-left: 0.3rem; */
	/* justify-self: start; */
}

.rotate {
	transform: rotate(90deg);
	transition: transform 0.3s;
}

.noRotate {
	transform: rotate(0deg);
	transition: transform 0.3s;
}

.showTree {
	height: fit-content;
	transition: height 1s;
	overflow: hidden;
}

.hide {
	height: 0;
	transition: height 1s;
	overflow: hidden;
}

.rightIcon {
	height: 0.7rem;
	width: 0.7rem;
	margin-top: 4px;
}

.displayArea-3 {
	max-height: 20rem;
	overflow-y: auto;
	text-align: left;
	scrollbar-width: none;
	border: #0078d7 solid 1px;
	position: absolute;
	top: 0.5rem;
	z-index: 20;
	right: 0.5rem;
	background-color: white;
}

.search-xy-3 {
	/* flex-grow: 1; */
	width: 9vw;
	height: 4vh;
	margin-right: 0.35vw;
}

.search-xy-3 input {
	/* width: 10vw; */
}

.search-container {
	display: flex;
	flex-direction: row;
	/* background-color: #ffffff; */
	width: 100%;
	height: 5vh;
	border: none;
}

.search-container-child-left {
	width: 60%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: left;
	margin-top: 1.1vh;
	/* font: normal normal 12px/17px Roboto; */
	/* font-weight: 450; */
	/* padding-left: 0.25vw; */
}

.search-container-child-right {
	width: 50%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: end;
	margin-bottom: 0;
	/* font: normal normal 12px/17px Roboto; */
	/* font-weight: 450; */
	/* padding-right: 0.25vw; */
}
