@import url('../../../global.css');

.container {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 10vh;
}

.deleteIcon {
	height: 4vh;
	width: 4vh;
	margin: 0 auto 0 auto;
	cursor: pointer;
}
