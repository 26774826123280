@import url('../../../../global.css');

.risk-hier-cont {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  border: 1px solid #00000029;
}

.risk-hier-s1-cont {
  width: 100%;
  height: 6vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.risk-hier-s1-img {
  height: 2.4vh;
  width: 2.4vh;
  margin-right: 1vw;
  transform: rotate(90deg);
}

.risk-hier-s1-label {
  font-family: var(--def-font);
  margin-left: 1vw;
  font-size: var(--font-size) * 1.4;
}
