@import '../../../../assets/scss/variable.scss';
@import '../../../../assets/scss/mixins.scss';

.market-pagination {
  position: static;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // Update name wrapper header
  .updateName {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    margin-right: 60px;
    margin-bottom: 10px;
    position: relative;
    @include breakpoint(lgdesktop) {
      margin-right: 30px;
    }
    &:hover {
      span {
        display: flex !important;
      }
    }
    span {
      position: absolute;
      width: 30px;
      height: 30px;
      box-shadow: 0px 0px 6px #00000014;
      background: #fff;
      color: #97999f;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      right: -13px;
      top: -13px;
      cursor: pointer;
      z-index: 9;
      display: none;
      @include breakpoint(lgdesktop) {
        right: 0px;
        top: -10px;
        width: 25px;
        font-size: 13px;
        height: 25px;
      }
    }
    .input-btn {
      color: $white;
      font-size: 0.7em;
      font-family: $roboto;
      border: 1px solid #c3c3c3;
      border-radius: 5px;
      // box-shadow: 0 3px 6px rgb(0, 0, 0 / 5%);
      border-color: #4f4f4f;
      margin-bottom: 0;
      padding: 9px 28px;
      margin-right: 8px;
      max-width: 250px;
      margin: 0px 0 0px 0px;
      background: #1c1c2b !important;
      @include breakpoint(lgdesktop) {
        font-size: 0.7em;
        margin-right: 10px;
      }
    }
    .update-name {
      font-family: $roboto;
      font-size: 0.8em;
      color: $white;
      padding: 5px 25px;
      font-weight: 600;
      text-transform: uppercase;
      width: 170px;
      margin-bottom: 0px;
      @include breakpoint(lgdesktop) {
        font-size: 10px;
      }
    }
  }
  .page-no {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    color: #000;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    margin-right: 5px;
  }
  .active-page {
    border: 1px solid #fff9f9;
    background: #fff9f9;
  }
  .inactive-page {
    border: 1px solid #707070;
    background: #707070;
  }
  .add-new-page {
    width: 100px;
    margin-left: 10px;
    color: #fff;
    height: 25px;
    background: #2f78d7;
    border-radius: 8px;
    opacity: 1;
    text-align: center;
    font: bold 12px Roboto;
    text-transform: uppercase;
    border: 0;
  }
}
