@import url('../../../../global.css');

.edit-app-popup-cont {
  width: 60vw;
  height: auto;
  background-color: white;
}

.edit-app-popup-title-cont {
  height: 9vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--primary-color);
  /* width: ; */
}

.edit-app-popup-title {
  color: white;
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 1.3);
  margin: 1vw 1.5vw;
  font-weight: 500;
}

.edit-app-popup-middleArea {
  margin: 2vw 1.5vw;
}

.edit-app-popup-middleArea-s1 {
  margin-bottom: 2vw;
}

.edit-app-popup-middleArea-title-cont {
  margin: 1vw 0;
}

.edit-app-popup-middleArea-title {
  font-weight: 600;
  font-size: calc(var(--font-size) * 1.2);
}

.edit-app-popup-checkbox-cont {
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
}

.edit-app-popup-hours-container {
  display: flex;
}

.edit-app-popup-hour {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0 2vw 0 0;
  width: 8vw;
  height: 3vw;
  /* border: 1px solid #d5d5d5; */
  padding: 0.3vw 0.5vw;
}

.edit-app-popup-hour-input {
  width: 5vw;
  height: 1.5vw;
  border: none;
  display: block;
  color: #97999f;
}

.edit-app-popup-checkbox-cont .customCheckbox {
  margin-right: 2vw;
}

.edit-app-popup-checkbox-cont label {
  margin-left: 1.5vw;
  font-weight: 400;
  color: #40414a;
  text-align: center;
  font-size: calc(var(--font-size) * 1.2);
  /* font: normal normal normal 16px/20px Segoe UI;
        line-height: 17px !important; */
  /* @include breakpoint(lgdesktop) {
          font-size: 10px;
        } */
}

.edit-app-popup-checkbox-cont .customCheckbox #formBasicCheckbox {
  width: 1rem;
  height: 1rem;
}
