@import url('../../../../global.css');

.remark-table-container {
  height: 70vh;
  width: 70vw;
}

.remark-table {
  height: 100%;
  width: 100%;
}

.remark-header {
  background-color: #f4f4f4;
  position: sticky;
  top: 0;
  height: 6vh;
}

.remark-header-th {
  font-family: var(--def-font);
  padding-left: 1vw;
  font-size: calc(var(--font-size) * 1.2);
}

.remark-table-td {
  height: 6vh;
  padding-left: 1vw;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
}

.remark-table-tr:nth-child(even) {
  background-color: #f4fbff;
}
