@import url('../../../global.css');

.urgent {
	background: #ff6666;
}

.warning {
	background: #f8bc66;
}

.alert {
	background: #fffa66;
}

.normal {
	background: #00ff0050;
}

.thHeadMain {
	background: #f4f4f4;
	white-space: nowrap;
	text-align: left;
}

.tableGen-cell {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.GenTable-filIcon {
	height: 1.5vh;
	width: 1.5vh;
	cursor: pointer;
}

.riskUtilTr td {
	font-family: 'Roboto', sans-serif;
	font-size: 1vw;
	text-align: center;
	padding: 0.5vh 0 0.5vh 0 !important;
	vertical-align: middle !important;
}

.thHeads {
	margin: 0;
	width: max-content;
	color: #4a4545;
	font-weight: 600;
	font-family: 'Roboto', sans-serif;
	font-size: 1.6vh;
}

.thHeads-x {
	display: flex;
	flex-direction: row;
	margin: 0;
	width: max-content;
	color: #4a4545;
	font-weight: 600;
	font-family: var(--def-font);
	font-size: var(--font-size);
}

.tableContainer {
	overflow: auto;
	white-space: nowrap;
}

.riskMonitorContainer {
	overflow: auto;
	height: 400px;
}

.bodyScrollTable {
	overflow: auto;
	scrollbar-width: thin;
}

.bodyScrollTable thead tr th,
.riskMonitorContainer thead tr th {
	position: sticky;
	top: 0;
}

.tablenGen-currPage-act {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #413f46;
	color: white;
	height: 3vh;
	width: 3vh;
	border-radius: 2vh;
	margin: 0.5vh;
}

.tablenGen-currPage-ina {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #c6c6c6;
	color: white;
	height: 3vh;
	width: 3vh;
	border-radius: 2vh;
	margin: 0.5vh;
	cursor: pointer;
}

.genTable-pager-cont {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 1vh auto 2vh auto;
	width: fit-content;
}

.genTable-pager-cont-extra {
	width: auto;
	justify-content: center;
}

.genTable-pager-cont-extra > *:first-child {
	margin: 0.5vh;
	flex: 1;
	text-align: left;
	padding-left: 0.5vh;
}

.genTable-pager-cont-extra::after {
	content: '';
	flex: 1;
}
