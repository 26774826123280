@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
body {
	padding: 0;
	margin: 0;
}
#notfound {
	position: relative;
	height: 100vh;
	.notfound {
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
}
    .notfound {
        position: absolute;
        max-width: 920px;
        width: 100%;
        line-height: 1.4;
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;
        top: 50%;
        left: 30%;
        @include breakpoint(lgdesktop){
            position: absolute;
            max-width: 700px;
            width: 100%;
            line-height: 1.4;
            text-align: center;
            padding-left: 15px;
            padding-right: 15px;
            top: 50%;
            left: 27%;
        }
	.notfound-404 {
		position: absolute;
		height: 100px;
		top: 0;
		left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		z-index: -1;
		h1 {
            font-family: 'Maven Pro', sans-serif;
            color: black;
            opacity: 20%;
            font-weight: 900;
            font-size: 250px;
            margin: 0px;
            position: absolute;
            left: 50%;
            bottom: 0%;
            transform: translate(-50%, -50%);

            @include breakpoint(lgdesktop){
                font-size: 150px;
                bottom: 20%;
            }
		}
	}
	h2 {
		font-family: 'Maven Pro', sans-serif;
		font-size: 46px;
		color: $black;
		font-weight: 900;
		text-transform: uppercase;
		margin: 0px;

        @include breakpoint(lgdesktop){
            font-size: 30px;
		color: $black;
		font-weight: 900;
        }
	}
	p {
		font-family: 'Maven Pro', sans-serif;
		font-size: 16px;
		color: #000;
		font-weight: 400;
		text-transform: uppercase;
		margin-top: 15px;

        @include breakpoint(lgdesktop){
		font-size: 12px;

        }
	}
	.error-btn {
		font-family: 'Maven Pro', sans-serif;
		font-size: 18px;
		text-decoration: none;
		text-transform: uppercase;
		background: #090a38;
		display: inline-block;
		padding: 16px 38px;
		border: 2px solid transparent;
		border-radius: 40px;
		color: #fff;
		font-weight: 400;
		-webkit-transition: 0.2s all;
		transition: 0.2s all;
        @include breakpoint(lgdesktop){
            font-size: 12px;
		padding: 12px 28px;

    
            }
		&:hover {
			background-color: #fff;
			border-color: #090a38;
			color: #090a38;
            text-decoration: none;
		}
	}
}


