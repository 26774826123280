@import url('../../../global.css');

.StrategyAnalyzerTable-th {
  height: 2.5rem;
  display: flex;
  font-family: var(--def-font);
  background-color: #f4f4f4;
  border-right: 1px solid #d5d5d5;
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
  overflow-x: hidden;
  overflow-y: visible;
}

.StrategyAnalyzerTable-td {
  min-height: 2.5rem;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: 1px solid #d5d5d5;
  white-space: nowrap;
  padding: 0 0.3rem;
}

.StrategyAnalyzerTable-tr {
  height: fit-content;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d5d5d5;
}
.StrategyAnalyzerTable-tr:nth-child(even) {
  background-color: #f4fbff;
}

.StrategyAnalyzerTable-tr:focus {
  border-top: 1px solid var(--focus);
  border-bottom: 1px solid var(--focus);
}

.StrategyAnalyzerTable-legs-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.StrategyAnalyzerTable-legs-expand {
  color: var(--primary-color);
  cursor: pointer;
  text-decoration: underline;
  user-select: none;
  margin: 0.2rem 0;
}
.closeRule-drilldown-container {
  img.logo {
    display: block;
    width: 20%;
    margin: 2vh auto;
  }
  .view-data-title {
    height: 2vh;
    span {
      font-weight: 500;
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
}

.closeRule-drilldown-title {
  font-family: var(--def-font);
  font-size: 2.4vh;
  margin: 1vh;
  font-weight: 500;
  display: block;
  margin: 1.5vh auto;
  text-align: center;
}

.leg-data-table {
  width: 100%;
  background-color: #ffffff;
  border-collapse: collapse;
  text-align: center;
  font-size: 15px;
}

.leg-data-table th,
.leg-data-table td {
  text-align: center;
  border: 1px solid #ddd;
  padding: 8px;
}

.leg-data-table td:nth-child(1) {
  width: 20%; /* Adjust as needed */
}
.leg-data-table td:nth-child(2) {
  width: 40%; /* Adjust as needed */
}
.leg-data-table td:nth-child(3) {
  width: 20%; /* Adjust as needed */
}
.leg-data-table td:nth-child(4) {
  width: 20%; /* Adjust as needed */
}

.table-container {
  height: 250px; /* Adjust the height as needed */
  width: 90%;
  padding-right: 1rem;
  overflow-y: auto;
  margin-bottom: 10vh;
}

.strategy-container {
  border: 1px solid #ddd;
  background-color: #efefef;
  border-radius: 5px;
  margin-bottom: 2vw; /* Optional: for rounded corners */
}

.strategy-id {
  font-weight: 500;
  text-align: left;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.direction.buy {
  color: green;
}

.direction.sell {
  color: red;
}

.StrategyAnalyzerTable-legs-expand:focus {
  color: var(--focus);
}

.StrategyAnalyzerTable-accidContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.StrategyAnalyzerTable-accId {
  margin: 0.2rem 0;
}

.StrategyAnalyzerTable-mainContainer {
  width: 100%;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.StrategyAnalyzerTable-action-area {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.StrategyAnalyzerTable-pill-spacing {
  margin: 0 0.2rem;
}

.StrategyAnalyzerTable-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0.4rem 0 0.4rem 0;
}

.StrategyAnalyzerTable-tablecontainer {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  overflow-x: auto;
}

.InlineStrategyEditor-container {
  height: fit-content;
  width: 96vw;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: 2px 2px 2px #00000011;
  margin: 0.1rem 0.2rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}

.InlineStrategyEditor-container-input {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.StrategyAnalyzerTable-thead {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  z-index: 3;
  /* overflow-x: auto;
  overflow-y: visible; */
}

.StrategyAnalyzerTable-tbody {
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;
  min-height: 10rem;
  overflow-x: auto;
}

.StrategyAnalyzerTable-th-title {
  margin: auto auto auto 0.2rem;
  font-family: var(--def-font);
  white-space: nowrap;
}

.StrategyAnalyzerTable-inlineTr {
  width: 100%;
}

.active-inline {
  border-bottom: 1px solid var(--primary-color);
  border-top: 1px solid var(--primary-color);
}
