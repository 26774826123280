@import '../../../assets/scss/variable.scss';
@import '../../../assets/scss/mixins.scss';
.risk-viewer-table {
  .add-rule-btn {
    border: none;
    width: auto;
    height: auto;
    background: $white;
    font: normal normal bold 20px/27px Segoe UI;
    color: #0022a8;
    @include breakpoint(lgdesktop) {
      margin-top: 0;
      font: normal normal bold 14px/27px Segoe UI;
    }
    img {
      width: 52px;
      height: 38px;
      @include breakpoint(lgdesktop) {
        height: 30px;
      }
    }
  }
  .table {
    width: 100%;
    font-size: 14px;
    color: #040402;
    background: #fff;
  }
  .cp_tree-table .cp_tree-table_header {
    color: #0022a8;
    height: 29px;
    background: #fff !important;
    border-top: none !important;
    font-weight: 700;
    padding: 2px 0.75rem;
    font-size: large;
    border-bottom: 1px solid rgba(55, 53, 47, 0.09);
  }
  .cp_tree-table_header .cp_tree-table_header-cell:first-child {
    max-width: 350px !important;
  }
  .cp_tree-table .cp_tree-table_cell,
  .cp_tree-table .cp_tree-table_header-cell {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }
  .cp_tree-table_header span {
    font: normal normal 700 16px/21px Segoe UI;
    color: #0022a8;
    width: 100%;
    padding-bottom: 10px;
    @include breakpoint(lgdesktop) {
      font: normal normal 700 10px/18px Segoe UI;
    }
  }
  .cp_tree-table_mover .cp_tree-table_row:first-child {
    background: #f1f0f0;
  }
  .cp_tree-table_row {
    height: 45px !important;
  }
  element.style {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    width: 100%;
    flex-grow: 1;
    flex-basis: 0px;
  }
  .cp_tree-table_row .cp_tree-table_cell:first-child {
    text-align: left;
    max-width: 350px;
  }
  .cp_tree-table .cp_tree-table_cell,
  .cp_tree-table .cp_tree-table_header-cell {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }
  .cp_tree-table_row .cp_tree-table_cell {
    text-align: center;
  }
  .cp_tree-table_cell .with-children {
    width: 100%;
    height: 45px !important;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }
  .col-blue {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }
  .cp_tree-table .cp_tree-table_cell .toggle-button {
    position: relative;
    display: inline-block;
    border: none;
    height: 14px;
    vertical-align: middle;
    padding: 0 25px 0 5px;
    margin: 0;
    cursor: pointer;
    background-color: transparent;
    outline: none;
  }
  .cp_tree-table .cp_tree-table_cell .toggle-button:after {
    content: '';
    display: inline-block;
    margin-top: -11px;
    width: 9px;
    height: 9px;
    border-right: 0.1em solid rgba(0, 0, 0, 0.45098039215686275);
    border-top: 0.1em solid rgba(0, 0, 0, 0.45098039215686275);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    padding: 0;
    vertical-align: middle;
  }
  .cp_tree-table .cp_tree-table_cell > .with-children > span {
    font-weight: 600;
  }
  .cp_tree-table .cp_tree-table_cell .toggle-button + span {
    vertical-align: middle;
  }
  .cp_tree-table .cp_tree-table_cell > .with-children > span {
    font-weight: 400 !important;
    text-transform: capitalize;
  }
  .cp_tree-table_row .cp_tree-table_cell span {
    width: 100%;
  }
  .cp_tree-table_cell span {
    color: #000;
    font: normal normal normal 16px/21px Segoe UI;
    @include breakpoint(lgdesktop) {
      font-size: 10px;
      line-height: 21px;
    }
  }
  .defaultwidth {
    width: 200px !important;
    display: inline-block;
  }
  .cp_tree-table .cp_tree-table_cell input {
    margin-right: 14px;
  }
  .with-children img,
  .without-children img {
    position: absolute;
    right: 35%;
    @include breakpoint(lgdesktop) {
      position: absolute;
      right: 25%;
      top: 37%;
      height: 12px;
    }
  }
  .table-bordered th {
    color: #0022a8;
  }
  .table-bordered td,
  .table-bordered th {
    border: none !important;
  }
  .table-bordered th {
    @include breakpoint(lgdesktop) {
      font-size: 10px;
    }
  }
  .table-bordered tr td {
    background: #f1f0f0;
  }
  .table-bordered {
    border: none !important;
  }
  .monitor-tree-table {
    width: 100%;
    overflow-x: auto;
    th {
      vertical-align: top;
    }
    td {
      white-space: nowrap;
    }
    @include breakpoint(mddesktop) {
      width: 100%;
      overflow-x: auto;
    }
  }
  .monitor-tree-table td {
    padding: 0 !important;
    margin: 0;
    position: relative;
  }
  .monitor-tree-table td div {
    padding: 1rem;
    font-weight: 600 !important;
    text-transform: capitalize;
    @include breakpoint(lgdesktop) {
      font-size: 10px;
      padding-bottom: 20px;
    }
  }
  .monitor-tree-table td div.index-column {
    padding: 1rem;
    font-weight: 400 !important;
    text-transform: capitalize;
    @include breakpoint(lgdesktop) {
      padding: 1.13rem;
    }
    @include breakpoint(mddesktop) {
      padding: -0.3rem;
    }
  }
  .monitor-tree-table td:nth-child(1) div.team {
    padding-left: 30px !important;
  }
  .monitor-tree-table td div.team {
    background-color: rgba(0, 120, 215, 0.4);
    height: 50px;
  }
  .monitor-tree-table td div.team.index-column {
    background-color: rgba(0, 120, 215, 0.4);
    padding-left: 50px;
  }
  .monitor-tree-table td div.customer {
    background: $white;
    padding-left: 30px;
  }
  .monitor-tree-table td:nth-child(1) div.admin {
    padding-left: 30px !important;
  }
  .monitor-tree-table td div img {
    height: 11px;
    width: 11px;
    margin-left: 13px;
  }
  .monitor-tree-table td span.iconPadding {
    padding: 0;
  }
  .monitor-tree-table td {
    span {
      margin-left: 0 !important;
      font-weight: 400 !important;
      text-transform: capitalize;
    }
  }
  .monitor-tree-table td span svg {
    position: absolute;
    top: 35%;
    left: 5px;
  }
  input[type='checkbox'],
  input[type='radio'] {
    box-sizing: border-box;
    padding: 64px;
    margin: 7px;
  }
  .monitor-tree-table .risk-table {
    overflow-x: auto;
    min-height: 35vh;
  }
  .risk-Dashboard .svg-inline--fa.fa-angle-right.fa-w-8.fa-fw {
    cursor: pointer;
    height: 25px;
    width: 25px;
    top: 25%;
    left: 2%;
    z-index: 99;
  }

  .risk-Dashboard .svg-inline--fa.fa-sort-down.fa-w-10.fa-fw {
    cursor: pointer;
    height: 25px;
    width: 25px;
    top: 25%;
    left: 2%;
    z-index: 99;
  }
  .risk-Dashboard .svg-inline--fa.fa-angle-right.fa-w-8.fa-fw path {
    display: none;
  }
  .risk-Dashboard .svg-inline--fa.fa-sort-down.fa-w-10.fa-fw path {
    display: none;
  }
  .risk-Dashboard .treeIcon {
    font-size: 14px;
    position: absolute;
    top: 35%;
    left: 5px;
  }
}

.trading-dashboard-menu {
  background: transparent linear-gradient(180deg, #1f1f86, #00001a) 0 0
    no-repeat padding-box;
}

.trading-dashboard-menu {
  a {
    padding: 3px 22px;
    font-size: 16px;
    font-weight: 400;
    color: #f3f5f9;
    font-family: $popin;
    @include breakpoint(xlgdesktop) {
      padding: 18px 22px;
    }
  }
}

.trading-dashboard-menu a.active {
  background: transparent linear-gradient(180deg, #0000e8, #00001a) 0 0
    no-repeat padding-box !important;
}

.trading-container h3 {
  margin: 15px 0;
}

.tradingHeaderwrapper {
  .trading-dashboard-menu {
    border-radius: 0;
  }
}

.risk-viewer-window {
  .toogledropdown {
    left: 15px;
  }
  .risk-table {
    min-height: 80vh !important;
  }
}
