@import url('../../../global.css');
.ToggleTabs-container {
	width: fit-content;
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: #e4ebf2;
	height: 2rem;
}

.ToggleTabs-tab-act {
	margin: 0;
	background-color: white;
	font-size: var(--font-size);
	padding: 1vh 2vh 1vh 2vh;
	border-radius: 0.5vh;
	box-shadow: 0 0 0.7vh #00000025;
	cursor: pointer;
	color: var(--primary-color);
	transition: all 0.5s;
	font-weight: bold;
}

.ToggleTabs-tab-act-disabled {
	margin: 0;
	background-color: white;
	font-size: var(--font-size);
	padding: 1vh 2vh 1vh 2vh;
	border-radius: 0.5vh;
	box-shadow: 0 0 0.7vh #00000025;
	cursor: pointer;
	color: grey;
	transition: all 0.5s;
	font-weight: bold;
}

.ToggleTabs-tab-ina {
	margin: 0;
	font-size: var(--font-size);
	padding: 1vh 2vh 1vh 2vh;
	cursor: pointer;
	transition: all 0.5s;
	color: var(--black);
}

.ToggleTabs-container2 {
	width: fit-content;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 2rem;
}

.ToggleTabs-tab2-act {
	font-family: var(--def-font);
	padding: 0.5vh;
	border: 1px solid #4ea2e3;
	background-color: #f0f9ff;
	color: #4ea2e3;
	border-radius: 0.5vh;
	margin: 1vh;
}

.ToggleTabs-tab2-ina {
	font-family: var(--def-font);
	padding: 0.5vh;
	border: 1px solid #bec7e3;
	background-color: #fff;
	color: #bec7e3;
	border-radius: 0.5vh;
	margin: 1vh;
	cursor: pointer;
}
