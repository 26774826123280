@import '../../../assets/scss/variable.scss';
@import '../../../assets/scss/mixins.scss';

.dashboard-menu {
  // border: 1px solid #707070;
  border-radius: 5px 5px 0px 0px;
  // height: 80px;
  list-style: none;
  display: flex;
  margin-bottom: 0;
  font: normal normal normal 18px/14px Segoe UI;
  font-weight: unset;
  line-height: 78px;
  color: #97999f !important;
  overflow: hidden;

  a.nav-link {
    padding: 0 30px 0 30px;
    cursor: pointer;
    color: #97999f;
    text-transform: capitalize;
    height: 35px !important;
    font-size: 12px;
    display: flex;
    align-items: center;
    @include breakpoint(lgdesktop) {
      padding: 10px 22px;
      font-size: 12px;
    }
    &.active {
      border-bottom: 4px solid #3a506b !important;
      border-radius: 10px 10px 0px 0px;
      height: 80px;
      list-style: none;
      display: flex;
      margin-bottom: 0;
      // line-height: 78px;
      color: #000000 !important;
      overflow: hidden;
      border-radius: 0;
      a.nav-link {
        color: #000000;
        background: $white;
        border-bottom: 4px solid #3a506b !important;
      }
      @include breakpoint(lgdesktop) {
        height: 35px !important;
        font-size: 12px;
        display: flex;
        align-items: center;
      }
    }
    &.focus {
      // border-bottom: 4px solid #0078d7 !important;
      border-radius: 10px 10px 0px 0px;
      height: 80px;
      list-style: none;
      display: flex;
      margin-bottom: 0;
      // line-height: 78px;
      color: #0078d7 !important;
      overflow: hidden;
      border-radius: 0;
      a.nav-link {
        color: #0078d7;
        background: $white;
        // border-bottom: 4px solid #0078d7 !important;
      }
      @include breakpoint(lgdesktop) {
        height: 35px !important;
        font-size: 12px;
        display: flex;
        align-items: center;
      }
    }
  }
  &.trading-dashboard-menu a {
    // line-height: 47px;
    // height: 80%;
    padding: 3px 22px !important;
    font: normal normal normal 14px/12px Roboto !important;
  }
  &.trading-dashboard-menu a.active {
    background: #ffffff 0% 0% no-repeat padding-box !important;
    padding: 3px 14px !important;
    font: normal normal normal 16px/12px Segoe UI;
    color: #000000 !important;
    // line-height: 47px ;
    // height: 100%;
    border-bottom: 3px solid #f37c21 !important;
  }
  &.trading-dashboard-menu a:focus {
    background: #ffffff 0% 0% no-repeat padding-box !important;
    padding: 3px 22px !important;
    font: normal normal normal 16px/12px Segoe UI;
    color: orange !important;
    // line-height: 47px ;
    height: 100%;
    border-bottom: 4px solid orange !important;
  }
  .clearing-acc {
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 23px;
    margin-top: 17px;
    margin-bottom: 0;
    padding-right: 30px;
    height: 45px;
    @include breakpoint(lgdesktop) {
      margin-top: 0px !important;
      height: 37px;
    }
  }
}
