@import url('../../../global.css');

.container-window {
  width: 20%;
  background-color: white;
  border-radius: 1vh;
  border: 1px solid #cecece;
}

.container-window-header {
  border-bottom: 1px solid #cecece;
  height: 6vh;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container-window-header-text {
  font-family: var(--def-font);
  font-size: 2vh;
  margin: 0 0 0 1vw;
  color: #000000;
}
