@import url('../../../../global.css');

.TableV2-tableContainer {
  height: fit-content;
  width: 100%;
  overflow-x: visible;
  overflow-y: visible;
}

.TableV2-table {
  height: fit-content;
  width: 100%;
}

.TableV2_th {
  border: var(--inDdInfieldBorder);
  background-color: #f4f4f4;
  padding: 0.3rem;
  font-weight: 600 !important;
}

.TableV2_td {
  border: var(--inDdInfieldBorder);
  padding: 0.3rem;
  height: fit-content;
  width: fit-content;
}
