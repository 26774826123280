@import url('../../../global.css');
.treeMenuGen {
	height: 100%;
	width: 95%;
}

.treeOptCont-x {
	display: flex;
	/* grid-template-columns: 60% 20% 10% 10%; */
	flex-direction: row-reverse;
	align-items: center;
	padding: 0.2rem;
	margin: 0.1rem;
	cursor: pointer;
	border-radius: 0.2rem;
	transition: background-color 0.3s;
}
.treeOptCont-x:hover {
	background-color: #00000015;
}

.treeOptCont-x:focus {
	color: orange !important;
	border: solid orange 2px !important;
	outline: none;
	box-shadow: none !important;
}

.selected {
	/* background-color: #00000015; */
}

.iconObj {
	height: 1rem;
	width: 1rem;
	margin: 0.2rem;
}

.title {
	margin: 0;
	font-family: var(--def-font);
	font-size: var(--font-size);
}

.rotate {
	transform: rotate(90deg);
	transition: transform 0.3s;
}

.noRotate {
	transform: rotate(0deg);
	transition: transform 0.3s;
}

.showTree {
	height: fit-content;
	transition: height 1s;
	overflow: hidden;
}

.hide {
	height: 0;
	transition: height 1s;
	overflow: hidden;
}

.rightIcon {
	height: 0.7rem;
	width: 0.7rem;
	margin-top: 4px;
}

.displayArea {
	max-height: 20rem;
	overflow-y: scroll;
	text-align: left;
	/* scrollbar-width: thin; */
	scrollbar-width: none;
	border: #dddddd solid 0.1vw;
}

.search-xy {
	flex-grow: 1;
}

.search-xy input {
	width: 10vw;
}
