@import url('../../../../global.css');

.preview-btn {
  border: 1px solid #d5d5d5;
  border-radius: 26px;
  position: fixed;
  right: -75px;
  top: 120px;
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  transition: right 0.3s ease-in-out;
  cursor: pointer;
  z-index: 100;
  height: fit-content;
  width: 110px;
  padding: 0.5rem;
}

.preview-btn:hover {
  right: -0.5rem;
}

.preview-icon {
  height: 1.2rem;
  width: 1.2rem;
  fill: black;
  margin-right: 1rem;
}

.preview-head {
  font-family: var(--def-font);
  font-size: var(--font-size);
  line-height: 1rem;
  letter-spacing: 0px;
  font-weight: 500;
}
