.error-heading {
	font-family: 'Poppins', sans-serif;
	font-size: 2.1vh;
	font-weight: bold;
	color: #f02121;
	margin: 2.4vh;
}
.error-item {
	font-family: 'Poppins', sans-serif;
	font-size: 1.85vh;
	margin: 1.2vh 2.4vh;
	.highlight-text {
		color: red;
	}
}
.submitsbuttons {
	background-color: white;
	padding-top: 5vh;
	padding-right: 1vw;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	.button_group {
		display: flex;
		flex-direction: row;
        align-items: center;
		.btn-default {
			border: 2px solid #2479de;
			padding: 10px 0px;
			background: none;
			text-transform: uppercase;
			border-radius: 5px;
			color: #2479de;
			font-weight: 700;
			width: 130px;
			font-size: 0.85em;
			&.ml-10 {
				margin: 0px 10px;
			}
		}

		.btn-primary {
			padding: 10px 20px;
			width: 130px;
			border-radius: 5px;
			text-transform: uppercase;
			font-size: 0.85em;
			border: none;
			height: 44px;
		}
	}
	.colormodal-global .popup form {
		margin: -20px 50px !important;
		label {
			text-align: center !important;
		}
	}
}
