@import url('../../../../global.css');

.user-main-menue-expand {
  border: 1px solid #dbdcde;
  border-radius: 0.5vh;
}

.user-main-menue-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.user-main-menue-5 {
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 1.2);
  color: #000;
  margin: 0 0 0 1vw;
}

.user-main-menue-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 4vh;
  width: 6vw;
  margin: 1vh 1vw;
  border: 1px solid #cecece;
  border-radius: 0.5vh;
  cursor: pointer;
}

.user-main-menue-2:hover {
  border: 1px solid #000;
}

.user-main-menue-3 {
  height: 2vh;
  width: 2vh;
}

.user-main-menue-4 {
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 0.9);
  color: #9fadd6;
}

.menue-table-c-1 {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #cecece;
  margin: 1vh auto 1vh auto;
  border-radius: 0.5vh;
}

.menue-table-c-2 {
  background-color: #f4f4f4;
  height: 4vh;
  position: sticky;
  top: 0;
  z-index: 0;
}

.menue-table-c-3 {
  font-family: var(--def-font);
  font-size: calc(var(--def-font) * 1.1);
  font-weight: 500;
  padding: 1vh 0 1vh 1vw;
}

.menue-table-c-6 {
  border: 1px solid #dadada;

  width: 30%;
}

.menue-table-c-8 {
  border: 1px solid #dadada;
}

.menue-table-c-9 {
  border: 1px solid #dadada;
}

.menue-table-c-body {
  height: 10vh;
  overflow-y: scroll;
}

.settings-view {
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 1.2);
}
