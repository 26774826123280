@import url('../../../../global.css');

.CompLabel-c-h {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.CompLabel-c-v {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
}

.CompLabel-label {
  font-family: var(--def-font);
font-size: calc(var(--font-size) * 1);
  color: var(--dark-black);
  margin: 0.5vh 1vh 0.5vh 0;
}
