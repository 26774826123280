@import url('../../../global.css');

.CustomMarketWatchSearch-searchContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	border: var(--table-border);
	border-radius: 1vh;
	height: 5vh;
	width: 30vw;
	overflow: hiddens;
}

.CustomMarketWatchSearch-searchContainer-focused {
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 1vh;
	height: 5vh;
	width: 30vw;
	overflow: hiddens;
	border: 1px solid #000;
}

.CustomMarketWatchSearch-searchIcon {
	height: 3vh;
	width: 3vh;
	margin: auto 1vw auto 1vw;
}

.CustomMarketWatchSearch-inputBox {
	height: 5vh;
	flex-grow: 1;
	border: 0px solid #ffffff00;
	background-color: #ffffff00;
	font-family: var(--def-font);
	font-size: var(--font-size);
}

.CustomMarketWatchSearch-mainContainer {
	position: relative;
}

.CustomMarketWatchSearch-searchableArea {
	top: 5vh;
	left: 0;
	height: 40vh;
	width: 30vw;
	overflow-y: scroll;
	z-index: 2;
	box-shadow: 3px 3px 6px #00000055;
	border-radius: 1vh;
	position: absolute;
	background-color: var(--white);
	display: flex;
	flex-direction: column;
}

.SecurityOption-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	min-height: 6vh;
}

.SecurityOption-add {
}

.SecurityOption-title {
	font-family: var(--def-font);
	font-size: var(--font-size);
	margin: 0 0 0 1vw;
}

.SecurityOption-add {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 3vh;
	width: 4vh;
	color: white;
	background-color: green;
	border-radius: 0.5vh;
	margin-right: 1vw;
	cursor: pointer;
}

.SecurityOption-remove {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 3vh;
	width: 4vh;
	color: white;
	background-color: red;
	border-radius: 0.5vh;
	margin-right: 1vw;
	cursor: pointer;
}

.CustomMarketWatchSearch-errorMessage {
	font-family: var(--def-font);
	font-size: var(--font-size);
	margin: auto;
}
