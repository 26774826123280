@import url('../../../global.css');

.CheckBox-container {
  height: 4vh;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.CheckBox-container-radio {
  height: 4vh;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 0.5vh 1vh;
  border-radius: 3vh;
  border: 0.5px solid #0078d7;
}

.CheckBox-img {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
}
