@import url('../../../../global.css');

.app-header-v2-cont {
  height: 8vh;
  width: 100vw;
  box-shadow: 0 1px 3px #00000019;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 8fr 1fr;
  z-index: 999;
}

.application-logo {
  height: 6vh;
}

.nav-menu-container {
  height: 8vh;
  cursor: pointer;
  width: 8vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.nav-menu-img {
  height: 3vh;
  width: 3vh;
  margin: 0.5vh 0 0.5vh 0;
}

.bottom-selection-bn {
  height: 0.5vh;
  border-radius: 1vh;
  width: 4vw;
  background-color: #f37c21;
}

.nav-menu-title {
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 0.8);
  white-space: nowrap;
  margin-bottom: 0.5vh;
}

.app-header-s2 {
  display: flex;
  flex-direction: row;
  margin: 0 auto 0 auto;
}

.app-header-s3 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.top-nav-dropdown {
  position: absolute;
  background-color: white;
  top: 8vh;
  left: 2.5vw;
  box-shadow: 0vh 0vh 3vh #00000022;
  z-index: 999;
  width: fit-content;
  min-width: 15vw;
  overflow: hidden;
  border-radius: 5px;
}

.top-nav-dropdown-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 10vw;
}

.top-nav-dropdown-option-focus {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 10vw;
  background-color: #00000011;
}

.top-nav-ddo-icon {
  height: 2vh;
  width: 2vh;
  margin: 1vh 1vh 1vh 1vh;
}

.top-nav-ddo-text {
  white-space: nowrap;
  font-family: var(--def-font);
  font-size: var(--font-size);
  margin: 0.5vh 0.5vh 0.5vh 0.5vh;
}

.top-nav-dropdown-option:focus {
  background-color: #00000011;
}

.top-nav-dropdown-option:hover {
  background-color: #00000011;
}
