.temp-head-cont {
  background-color: #F4F4F4;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #CECECE;
  display: flex;
  align-items: center;
  padding-left: 3vh;
}

.temp-head {
  height: fit-content;
  font-weight: bold;
}

.temp-icon {
  margin: 1vh 1vh 1vh 0;
  height: 3.5vh;
  width: 3.5vh;
}

.temp-fields-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 1.5vh;
}

.temp-fields-algoID {
  display: flex;
  flex-direction: row;
  margin: 0 1.5vh;
}

.temp-field {
  margin: 2vh 1.5vh;
  font-size: 0.9em;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

.temp-field .CompLabel-c-v {
  width: 100%;
}

.name-field {
  flex-grow: 1;
}

.des-field {
  flex-grow: 3;
}