@import url('../../../global.css');
.dropdown-label {
  margin: 0 1vh 2vh 0;
  font-family: var(--def-font);
  width: fit-content;
  font-size: 2vh;
}

.legend {
  width: 0;
}
