.feedback-popup-container-x {
  width: 35vw;
  display: flex;
  flex-direction: column;
}

.title-container-x {
  width: 100%;
  height: 7vh;
  color: white;
  background-color: #067bd8;
  display: flex;
  align-items: center;
  justify-content: left;
  font: normal normal 13px/21px Roboto;
  font-weight: 450;
  padding-left: 0.75vw;
}

.title2-container-x {
  display: flex;
  flex-direction: row;
  background-color: #efefef;
  width: 100%;
  height: 5.5vh;
}

.title2-container-child-left-x {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  font: normal normal 12px/17px Roboto;
  font-weight: 450;
  padding-left: 0.75vw;
}

.title2-container-child-right-x {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  font: normal normal 12px/17px Roboto;
  font-weight: 450;
  padding-right: 0.75vw;
}

.body-row-container-x {
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 100%;
  height: 5.5vh;
  border-top: thin solid rgb(235, 235, 235);
}

.body-row-container-child-left-x {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  font: normal normal 12px/17px Roboto;
  font-weight: 400;
  padding-left: 0.75vw;
  border-right: thin solid rgb(235, 235, 235);
}

.body-row-container-child-right-x {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  font: normal normal 12px/17px Roboto;
  font-weight: 400;
  padding-right: 0.75vw;
}

.global-unit-text-x {
  padding-right: 1vw;
}

.switch-container-x {
  display: flex;
  justify-content: flex-end;
  padding-right: 1vw;
}

.btn-x {
  text-align: center;
}

.feed-popup-bottom-x {
  height: 6vh;
  width: 100%;
  background-color: #efefef;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 10vw;
}
