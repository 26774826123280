@import url('../../../../global.css');

.show_multi_select_cont {
  border: 1px solid #cecece;
  border-radius: 0.5vh;
  height: 25vh;
  width: 30vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 3rem;
  overflow-y: auto;
}

.show-multiselect-text-cont {
  height: 2.5rem;
  width: 7rem;
  position: relative;
  border: 1px solid #cecece;
  border-radius: var(--border-radius);
  margin: 0.5rem auto 0.5rem auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.5rem;
}

.show-multiselect-text {
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--black);
  white-space: nowrap;
}

.show-multiselect-cross {
  position: absolute;
  top: -1vh;
  right: -1vh;
  height: 2vh;
  width: 2vh;
  cursor: pointer;
  visibility: hidden;
}

.show-multiselect-text-cont:hover .show-multiselect-cross {
  visibility: visible;
}

.Show_Multi_Select-textContainer {
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;
  align-items: center;
}
