@import url('../../../global.css');

.switchContainer2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.switchMessage {
  margin: 0;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  font-family: 'Roboto' sans-serif;
}

.GenericSwitch-container {
  height: fit-content;
  width: fit-content;
  display: flex;
  margin: 0.5rem;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 1.5rem;
  margin: 0 0.5rem 0 0.5rem;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 1rem;
  width: 1rem;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(1rem);
  -ms-transform: translateX(1rem);
  transform: translateX(1rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.checkIcon {
  height: 1.5rem;
  width: 1.5rem;
}

.GenericSwitch-icon {
  height: 1.2rem;
  width: 1.2rem;
  cursor: pointer;
  margin: 0rem 0rem 0rem 0.5rem;
}

.swcText {
  margin: 0.5rem;
}

.only-checkbox {
  height: 100% !important;
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}
.only-checkbox img {
  margin: 0 !important;
}
.only-checkbox #swcTitle {
  display: none;
  margin: 0;
}
