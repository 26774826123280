@import '../../../assets//scss/mixins.scss';
@import '../../../assets/scss/variable.scss';
.header-marketwatch {
  display: flex;
  justify-content: space-between;
  z-index: 999;
  padding: 6px 20px;
  padding-top: 10px;
  background: #edf0ef;
  // box-shadow: $dark-btm-shadow;
  font: normal normal normal 15px/16px Roboto !important;
  .live-trading-header {
    .livedrop {
      width: 12em;
      height: 4em;
      background: #ffffff !important;
      opacity: 1;
      text-align: center;
      // font: normal normal medium 20px/20px Roboto;
      letter-spacing: 0px;
      color: #0078d7;
      opacity: 1;
      // padding: 15px;

      @include breakpoint(xlgdesktop) {
        width: 9em;
        height: 2.1em;

        // padding: 3px;
      }
      a {
        color: #0078d7;
        font-weight: 500;
        line-height: 34px;
        padding: 0 0;
        border: 1px solid #0078d7;
        border-radius: 4px;
      }
      a:hover {
        background: #0078d7 !important;
        color: white;
      }
      a:focus {
        background: orange !important;
        border: 1px solid orange !important;
        color: white;
      }
      #strategyColor {
        background: #0078d7 !important;
        color: white;
      }
      .dropdown-toggle::after {
        position: relative;
        top: 3px;
        margin-left: 0.5em;
      }
      .dropdown-menu.show {
        z-index: 9999;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        position: absolute;
        background: white !important;
        border: 1px solid #339;
        width: 289px;
        border-radius: 0px 15px 15px 15px;
        padding: 5px;
        margin: 2px -7px !important;
        overflow: auto;
        max-height: 400px;
        min-height: 400px;
        transform: translate(7px, 65px) !important;
        .spinner-border {
          margin: 0 auto;
          left: 0;
          right: 0;
        }
        @include breakpoint(lgdesktop) {
          width: 200px;
          transform: translate(7px, 45px) !important;
        }
        a {
          display: block;
          padding: 5px 10px;
          line-height: 26px;
          font-size: 15px;
          @include breakpoint(lgdesktop) {
            white-space: break-spaces;
            padding: 10px 10px;
            line-height: 15px;
          }
        }
      }
      .dropdown-item.active,
      .dropdown-item:active {
        background-color: $white !important;
      }
      .dropdown-item {
        color: black !important;
      }
    }
    .livedrop.show {
      .dropdown-toggle::after {
        transform: rotate(180deg);
        top: 1px;
      }
    }
    .manualbtn {
      top: 96px;
      left: 408px;
      margin-left: 20px;
      width: 13em;
      height: 36px;
      background: #ffffff !important;
      opacity: 1;
      text-align: center;
      font: normal normal medium 1.2em/1.2em Roboto;
      letter-spacing: 0px;
      color: #0078d7;
      font-weight: 500;
      opacity: 1;
      @include breakpoint(xlgdesktop) {
        width: 9em;
        height: 2.3em;
        // padding: 3px;
      }
    }
    .manualbtn:hover {
      background: #0078d7 !important;
      a {
        color: white;
      }
      color: white;
    }
    .manualbtn:focus {
      background: orange !important;
      border: 1px solid orange !important;
      a {
        color: white;
      }
      color: white;
    }
  }
  .configbtn {
    top: 96px;
    left: 408px;
    margin-left: 20px;
    width: 13em;
    height: 4.2em;
    background: $white !important;
    border: 2px solid #63a8ed !important;
    border-radius: 5px;
    opacity: 1;
    text-align: center;
    font: normal normal medium 1.2em/1.2em Roboto;
    font-weight: 500;
    letter-spacing: 0px;
    color: #63a8ed !important;
    opacity: 1;
    font-weight: 500;
    @include breakpoint(xlgdesktop) {
      width: 11em;
      height: 2.5em;
      padding: 3px;
    }
  }
}

// // hack to change color of the button, can break when changes are made to genButton / DropDown component
// .live-trading-header-dropdown-wrapper > div > div > div > div {
//   border: 1px solid #0078d7;
// }

// .live-trading-header-dropdown-wrapper > div > div > div > div > p {
//   color: #0078d7;
// }
