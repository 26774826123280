:root {
	--dimension: 1.5rem;
}
.pill-container {
	height: var(--dimension);
	width: var(--dimension);
	cursor: pointer;
	/* border: 0.5px solid red; */
	display: flex;
	align-items: center;
	justify-content: center;
}

.pill-icon {
	height: var(--dimension);
	width: var(--dimension);
}
