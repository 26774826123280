@import url('../../../../global.css');

.StrategyAnalyzerFormula {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.HeaderStrategyBuilder-container {
  display: flex;
  flex-direction: row;
  background-color: #f4f4f4;
  border-bottom: 1px solid #cecece;
  border-top: 1px solid #cecece;
  margin: 0 0 1rem 0;
}

#HeaderStrategyBuilder-img {
  height: 2rem;
  width: 2rem;
  margin: 0.5rem 1rem;
}

#HeaderStrategyBuilder-title {
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 1.1);
  margin: auto 0;
  font-weight: 600;
}

#FormulaGroup-container {
  display: grid;

  grid-template-columns: 1fr 3fr;
  grid-auto-rows: 3rem;
}

#FormulaGroup-colNam {
  margin: auto auto auto 1rem;
  font-weight: 600;
}

#FormulaGroup-exp {
  margin: auto auto auto 1rem;
  font-weight: 600;
}
