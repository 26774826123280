@import url('../../../../global.css');

.manage-conf-popup-cont {
  height: 80vh;
  width: 70vw;
  background-color: white;
}

.manage-conf-popup-title-container {
  height: 6vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #cecece;
}

.manage-conf-popup-title {
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 1.3);
  margin: 1vw;
  font-weight: 500;
}

.manage-conf-popup-middlearea {
  height: 66vh;
}

.manage-conf-popup-buttonarea {
  height: 8vh;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.manage-conf-popup-middlearea-s1 {
  display: flex;
  flex-direction: row;
}
