@import url('../../global.css');

.multiScreen-container {
	height: 92vh;
	width: 100vw;
	display: flex;
	flex-direction: row;
	overflow-y: scroll;
}

.box {
	border: 2px solid black;
	border-radius: 8px;
	margin: 1em;
}

.multiScreen-screenArea {
	position: absolute;
	top: 8vh;
	height: 92vh;
	width: 100vw;
	z-index: 0;
}
