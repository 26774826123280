@import url('../../../global.css');

.DropDownV2-container {
  height: fit-content;
  width: fit-content;
  border-radius: var(--border-radius);
  border: var(--inDdInfieldBorder);
  position: relative;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 90% 10%;
  cursor: pointer;
  transition: all 0.3s;
}

.disabled {
  background-color: #cecece;
  cursor: pointer;
}

.DropDownV2-container:focus {
  border: 1px solid var(--focus);
  transition: all 0.3s;
}

.DropDownV2-container:hover {
  border: var(--borderHover);
  transition: all 0.3s;
}

.DropDownV2-text {
  color: var(--dark-black);
  font-weight: 500;
  margin-right: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DropDownV2-icon {
  height: 1rem;
  width: 1rem;
}

.DropDownV2-dropDownArea {
  max-height: 10.1rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 2rem;
  left: 0;
  background-color: white;
  border: var(--inDdInfieldBorder);
  overflow-y: auto;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  z-index: 999;
}

.DropDownV2-itemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  transition: all 0.2s;
}

.DropDownV2-itemContainer:hover {
  background-color: #cecece33;
  transition: all 0.2s;
}

.DropDownV2-item-text {
  white-space: nowrap;
  color: var(--black);
}

.focused {
  background-color: var(--focus);
}
