.treeMenuGen {
  height: 100%;
  width: 95%;
}

/* .treeOptCont {
  display: grid;
  grid-template-columns: 6fr 5fr 1.5fr;
  padding: 0.2rem;
  margin: 0.1rem;
  cursor: pointer;
  border-radius: 0.2rem;
  transition: background-color 0.3s;
} */

.treeOptCont {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0.2rem;
  margin: 0.1rem;
  cursor: pointer;
  border-radius: 0.2rem;
  transition: background-color 0.3s;
}
.treeOptCont:hover {
  background-color: #00000015;
}

.treeOptCont:focus {
  color: orange !important;
  border: solid orange 2px !important;
  outline: none;
  box-shadow: none !important;
}

.selected {
  /* background-color: #00000015; */
}

.iconObj {
  height: 1rem;
  width: 1rem;
  margin: 0.2rem;
}

.title {
  margin: 0;
  font-family: 'Robot', sans-serif;
  font-size: 2vh;
  /* transform: rotate(0deg); */
  /* margin-left: 0.3rem; */
  /* justify-self: start; */
}

.rotate {
  transform: rotate(90deg);
  transition: transform 0.3s;
}

.noRotate {
  transform: rotate(0deg);
  transition: transform 0.3s;
}

.showTree {
  height: fit-content;
  transition: height 1s;
  overflow: hidden;
}

.hide {
  height: 0;
  transition: height 1s;
  overflow: hidden;
}

.rightIcon {
  height: 0.7rem;
  width: 0.7rem;
  margin-top: 4px;
}

.displayArea {
  max-height: 20rem;
  overflow-y: scroll;
  text-align: left;
  /* scrollbar-width: thin; */
  scrollbar-width: none;
  border: #dddddd solid 0.1vw;
}

.search-xy {
  flex-grow: 1;
}

.search-xy input {
  width: 10vw;
}
