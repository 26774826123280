/*Scrollbar design*/
/* Set the width and height of the scrollbar */
::-webkit-scrollbar {
	width: 0.5rem;
	height: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 0.5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

/* Increase the visibility of the scrollbar */
::-webkit-scrollbar-corner {
	background: transparent;
}
