@import '../../../assets/scss/variable.scss';
@import url('../../../global.css');

.risk-monitering {
	box-shadow: $dark-btm-shadow;
	max-height: 88vh;
	height: 98%;
	width: 98%;
	margin: auto;
	overflow-y: auto;
}

.support {
	display: grid;
	grid-template-columns: 25% 50% 25%;
}

.logLiveIcon-icon {
	height: 1.2rem;
	width: 1.2rem;
}

.toggleStyle {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 1rem 0.5rem 1rem 1rem;
}

// .risk-internal-live-trade-th {
//   border-radius: 0px !important;
//   border: none !important;
//   height: 37px;
// }

// .risk-internal-live-trade-th p {
//   margin: 0px;
//   font-size: 10px;
// }

.risk-monitoring-live-trade-toggle-style {
	margin: 16px 0px 16px 0px;
	padding: 0px 0px 0px 10px !important;
	height: 35px;
}

.risk-monitoring-live-trade-toggle-btn-group {
	height: 30px;
}

.risk-monitoring-live-trade-search {
	height: 1rem;
	display: flex;
	flex-direction: row;
	flex-flow: row;
	justify-content: center;
	align-items: center;
}

.risk-monitoring-live-trade-search img {
	height: 25px;
	width: 25px;
}

.risk-monitoring-live-trade-search p {
	font-size: 12px;
}
