@import url('../../../../../global.css');

.ButtonAddLeg-container {
  height: fit-content;
  width: fit-content;
  padding: 0.6rem 0.9rem;
  background: #fff 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 4px 4px 8px #b6b8ba46, 0px 4px 8px #b6b8ba45;
  border-radius: 40px;
  opacity: 1;
  cursor: pointer;

  &:focus {
    border: var(--focusBorder);
  }

  &:active {
    box-shadow: inset 4px 4px 8px #b6b8ba45, inset -4px -4px 8px #b6b8ba46;
  }
}

.ButtonAddLeg-icon {
  height: 1.5rem;
  width: 1.5rem;
}

.ButtonAddLeg-label {
  margin-left: 0.5rem;
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 1.2);
  line-height: 1rem;
  letter-spacing: 0px;
  font-weight: 400;
}

.ButtonAddLeg-cat1-container {
  height: fit-content;
  width: fit-content;
  background: var(--white);
  border: 1px solid #bbbed6;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: var(--border-radius);
  opacity: 1;
  padding: 0.3rem 0.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s;

  &:focus {
    height: fit-content;
    width: fit-content;
    border: var(--focusBorder);
  }

  &:hover {
    box-shadow: 4px 4px 8px #b6b8ba45;
  }
}

.ButtonAddLeg-cat1-icon {
  height: 0.8rem;
  width: 0.8rem;
}

.ButtonAddLeg-cat1-label {
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 0.8);
  font-weight: 500;
  letter-spacing: 0.05rem;
  color: var(--unnamed-color-9fadd6);
  text-align: left;
  color: #9fadd6;
  opacity: 1;
  margin: 0 0 0 0.5rem;
}

.ButtonAddLeg-cat2-container {
  height: fit-content;
  width: fit-content;
  padding: 0.5rem 0.6rem;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 1px 3px 4px #90bfed46, 3px 4px 6px #acc9e545;
  border: 1px solid #a7c5db67;
  border-radius: 20px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s;

  &:focus {
    border: var(--focusBorder);
  }

  &:hover {
    box-shadow: 4px 4px 8px #b6b8ba45;
  }

  &:active {
    box-shadow: inset 4px 4px 8px #b6b8ba45, inset -4px -4px 8px #b6b8ba46;
  }
}

.ButtonAddLeg-cat2-icon {
  height: 1.2rem;
  width: 1.2rem;
}

.ButtonAddLeg-cat2-label {
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 1.2);
  font-weight: 600;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-9fadd6);
  text-align: left;
  letter-spacing: 0px;
  color: var(--black);
  opacity: 1;
  margin: 0 0 0 13px;
}

.ButtonAddLeg-cat3-container {
  background-color: #f7f7f7;
  height: 2rem;
  width: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  cursor: pointer;
  box-shadow: 2px 4px 8px #00000033;
  border: 1px solid white;
  transition: all 0.5s;

  &:focus {
    border: var(--focusBorder);
  }

  &:active {
    box-shadow: inset 4px 4px 8px #b6b8ba45, inset -4px -4px 8px #b6b8ba46;
  }

  &:hover {
    box-shadow: 4px 4px 8px #b6b8ba45;
  }
}

.ButtonAddLeg-cat3-icon {
  height: 0.9rem;
  width: 0.9rem;
}

.ButtonAddLeg-cat3-label {
}
