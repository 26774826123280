@import '../../../assets/scss/mixins.scss';
@import '../../../assets/scss/variable.scss';
@import url('../../../global.css');

.order-book {
  .buttons-wrapperdrill {
    button {
      font: normal normal bold 1em/24px Segoe UI;
      color: $white;
      border: none;
      margin: 0 11px;
      padding: 1px 8px 1px 0;
      border-radius: 50px 11px 11px 50px;
      box-shadow: $dark-btm-shadow;
      @include breakpoint(lgdesktop) {
        font: normal normal bold 10px/16px Segoe UI;
        height: 26px;
      }
      img {
        margin-right: 5px;
        height: 31px;
        @include breakpoint(lgdesktop) {
          margin-right: 5px;
          height: 24px;
          margin-bottom: 3px;
        }
      }

      &.cancel {
        background: transparent
          linear-gradient(180deg, #a50000 0%, #ff0000 100%) 0% 0% no-repeat
          padding-box;
      }
    }
  }

  .search-order {
    display: flex;
    padding: 20px 0 15px 0;
    align-items: center;
    .inner-header-render {
      box-shadow: none !important;
      margin-right: 25px;
      .search {
        margin-top: -13px;
        .icon {
          top: 3px !important;
          @include breakpoint(lgdesktop) {
            top: 0px !important;
          }
        }
      }
      .form-control {
        margin-top: 0px;
        border-radius: 0px;
      }
    }
  }
  .p-right {
    margin-right: -26px;
    margin-bottom: 0;
  }

  .p-left {
    margin-left: -11px;
    margin-bottom: 0;
  }

  .form-control {
    margin-top: -4px;
  }

  .clearing-acc {
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 23px;
    margin-top: 17px;
    @include breakpoint(lgdesktop) {
      margin-top: -7px !important;
      margin-bottom: 0px !important;
    }

    .book-tab-wrap {
      background: $white;
      box-shadow: 0px 3px 20px #e9e9e9;
      border-radius: 2px;
      padding: 5px 20px 5px 20px;
      .pr-3,
      .px-3 {
        padding-right: 2rem !important;
      }
      a {
        &.activated {
          background: #00166d;
          box-shadow: 0px 2px 5px #147aff;
          border-radius: 3px;
          color: #fff;
          display: inline-block;
          padding: 5px 10px 5px 10px;
          text-decoration: none;

          @include breakpoint(lgdesktop) {
            font-size: 10px;
          }
        }

        &.deactivated {
          text-align: center;
          font-size: 16px;
          line-height: 0px;
          letter-spacing: 0px;
          color: #545871;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          padding: 0px 0px 0px 0px;

          @include breakpoint(lgdesktop) {
            font-size: 10px;
          }
        }
      }
    }
    img {
      height: 45px;
      @include breakpoint(lgdesktop) {
        height: 37px;
      }
    }
  }
  table thead tr {
    background: $white !important;
    height: 40px !important;

    th {
      color: #070707 !important;
      font-size: 1em !important;
      line-height: 20px !important;
      font-family: $roboto !important;
      font-weight: 500 !important;

      @include breakpoint(lgdesktop) {
        font-size: 12px !important;
      }
      svg {
        @include breakpoint(lgdesktop) {
          width: 0.7em;
          height: 0.7em;
        }
      }
    }
  }
  tbody {
    td {
      // border-top: none !important;
      font-family: $roboto !important;
      @include breakpoint(lgdesktop) {
        border: 1px solid #dadada;
      }
      a {
        text-decoration: underline !important;
        color: #0000ff !important;
        cursor: pointer;
      }
    }
  }
  .genric-table table tbody tr:nth-of-type(even) {
    background-color: #f4fbff !important;

    border: 1px solid #dadada;
  }
  .genric-table table tbody tr:nth-of-type(odd) {
    background-color: #fff !important;
  }
  .pagination {
    nav {
      transform: translate(110px, 0);
    }
  }
}

.tradeModSubmit {
  width: 100%;
}

.drilldown {
  margin-top: 0 !important;
  .table-counter-drilldown {
    padding: 32px 40px 20px 40px;
    @include breakpoint(lgdesktop) {
      padding: 32px 20px 20px 20px;
    }
    .buttons-wrapperdrill {
      button {
        font: normal normal bold 1em/24px Segoe UI;
        color: $white;
        border: none;
        margin: 0 11px;
        padding: 1px 8px 1px 0;
        border-radius: 50px 11px 11px 50px;
        box-shadow: $dark-btm-shadow;
        @include breakpoint(lgdesktop) {
          font: normal normal bold 10px/16px Segoe UI;
          height: 26px;
        }
        img {
          margin-right: 5px;
          height: 31px;
          @include breakpoint(lgdesktop) {
            margin-right: 5px;
            height: 24px;
            margin-bottom: 3px;
          }
        }
        &.refresh {
          background: transparent
            linear-gradient(180deg, #00156d 0%, #4272c5 100%) 0% 0% no-repeat
            padding-box;
        }
        &.cancel {
          background: transparent
            linear-gradient(180deg, #a50000 0%, #ff0000 100%) 0% 0% no-repeat
            padding-box;
        }
      }
    }
    .heading-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h3 {
      font: normal normal bold 24px/20px Roboto;
      color: #070707;
      margin-bottom: 0;
      @include breakpoint(lgdesktop) {
        font: normal normal bold 18px/20px Roboto;
      }
    }

    .Trades {
      width: 100%;
    }
    .emty-wrapper {
      width: 30%;
      @include breakpoint(tablet) {
        width: 5%;
      }
    }
  }
  .genric-table table tbody tr:nth-of-type(even) {
    background-color: #eae9e633 !important;
  }
  .genric-table table tbody tr:nth-of-type(odd) {
    background-color: #eae9e633 !important;
  }

  // Trade-book scrollable-custommin css
  .genric-table {
    overflow-x: scroll;

    thead {
      .tradebook_exchange,
      .tradebook_exchange_segment,
      .tradebook_old_account_id,
      .tradebook_account_id,
      .tradebook_participant_id,
      .tradebook_user_id,
      .tradebook_order_id,
      .tradebook_exchange_order_id,
      .tradebook_rule_id,
      .tradebook_security,
      .tradebook_status,
      .tradebook_currency,
      .tradebook_order_px,
      .tradebook_order_qty,
      .tradebook_trigger_px,
      .tradebook_traded_qty,
      .tradebook_traded_qty_lots,
      .tradebook_buy_sell,
      .tradebook_exchange_accountid,
      .tradebook_exchange_timestamp,
      .tradebook_remarks {
        vertical-align: top;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .tradebook_exchange {
      min-width: 70px;
    }

    .tradebook_exchange_segment {
      min-width: 90px;
    }

    .tradebook_old_account_id {
      min-width: 90px;
    }

    .tradebook_account_id {
      min-width: 90px;
    }

    .tradebook_participant_id {
      min-width: 90px;
    }

    .tradebook_user_id {
      min-width: 70px;
    }

    .tradebook_order_id {
      min-width: 90px;
    }

    .tradebook_exchange_order_id {
      min-width: 120px;
    }

    .tradebook_rule_id {
      min-width: 90px;
    }

    .tradebook_security {
      min-width: 100px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .tradebook_status {
      min-width: 70px;
    }

    .tradebook_currency {
      min-width: 70px;
    }

    .tradebook_order_px {
      min-width: 70px;
    }

    .tradebook_order_qty {
      min-width: 70px;
    }

    .tradebook_trigger_px {
      min-width: 70px;
    }

    .tradebook_traded_qty {
      min-width: 70px;
    }

    .tradebook_traded_qty_lots {
      min-width: 90px;
    }

    .tradebook_buy_sell {
      min-width: 70px;
    }

    .tradebook_exchange_accountid {
      min-width: 110px;
    }

    .tradebook_exchange_timestamp {
      min-width: 110px;
    }

    .tradebook_remarks {
      min-width: 150px;
    }
  }
}

.orderWindow.order-book {
  margin-top: 0;
  padding: 5px 25px 5px 10px;
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .table-counter-drilldown .analyzer-left select {
    width: 60px;
  }

  .drilldown .table-counter-drilldown .buttons-wrapperdrill button {
    margin: 0 5px;
  }
}

.orderbook-drilldown-container {
  img.logo {
    display: block;
    width: 15%;
    margin: 5vh auto;
  }
  .view-data-title {
    height: 5vh;
    span {
      font-weight: 500;
    }
  }
  height: 90vh;
  width: 65vw;
}

.orderbook-drilldown-title {
  font-family: var(--def-font);
  font-size: 2.4vh;
  margin: 2.5vh;
  font-weight: 500;
  display: block;
  margin: 2.5vh auto;
  text-align: center;
}

.orderbook-sec1 {
  width: 60vw;
  // border: 1px solid black;
  margin: 3vh auto;
  display: grid;
  grid-template-columns: 1.33fr 2.66fr;
  grid-template-rows: fit-content 8vh;
  .orderbook-sec-1-1 {
    display: flex;
    justify-content: space-between;
    .switch-cont {
      margin-right: 4vh;
    }
  }
}

.orderbook-error-text {
  font-family: var(--def-font);
  font-size: 2vh;
  color: #a50000;
}

.orderbook-acc-text {
  font-family: var(--def-font);
  font-size: 1.9vh;
  color: #909090;
}

.orderbook-supporttext {
  font-family: var(--def-font);
  font-size: 2.4vh;
  font-weight: 500;
  text-align: left;
}

.orderbook-accountlabel {
  font-family: var(--def-font);
  font-size: 2.2vh;
  color: #070707;
}

.order-trades-new-font {
  font-family: var(--def-font);
  font-size: var(--font-size);
}

.total {
  display: flex;
  justify-content: space-between;
  border: 1px solid rgb(190, 190, 190);
  border-radius: 1vh;
  font-size: 11px;
  font-weight: 300;
  padding: 4px;
}

.green-text {
  color: rgb(44, 192, 44);
  font-weight: 600;
}

.red-text {
  color: #db3b3b;
  font-weight: 600;
}
