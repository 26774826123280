.customCheckbox{
    margin-top: 0.2rem;
    margin-bottom: 0.2rem !important;
    height: 2.56rem;
    
    #formBasicCheckbox{
        height: 1.56rem;
        width: 1.56rem;
    }

    .form-check:focus-within {
      border: solid orange 1px !important;
      outline: none;
      box-shadow: none !important;
    }
}