@use "../newGlobal" as global;
@import url('../../global.css');

.adv-inputbox {
	border-radius: 4px;
	outline: none;
	border: 1px solid #cecece;
	height: 2.5rem;
	padding: 0 0.5em 0 0.5em;
	font-size: global.$fl1;

	&:focus {
		outline: none;
		border: 1px solid global.$dark-black;
	}
}

.adv-dropdown-options {
	position: absolute;
	top: 4rem;
	z-index: 9;
	background-color: white;
	border-radius: 0 0 0.5em 0.5em;
	box-shadow: 0em 0em 0.5em #cecece;
	height: fit-content;
	max-height: 15vh;
	display: flex;
	flex-direction: column;
	overflow-y: auto;

	.option {
		color: global.$dark-black;
		font-size: global.$fl1;
		padding: 0.5em 0 0.5em 0.5em;
		cursor: pointer;

		&:hover {
			background-color: #cecece;
		}
	}
}

.V2DropDown-selection {
	font-size: var(--font-size);
	font-family: var(--def-font);
}

.V2DropDown-selection-container {
	border-radius: 4px;
	background-color: #ffffff;
	outline: none;
	border: global.$inDdInfieldBorder;
	height: 2.5rem;
	padding: 0 0.5rem 0 0.5rem;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
}
