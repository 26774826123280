@import '../../../../assets/scss/mixins.scss';
@import '../../../../assets/scss/variable.scss';

.step_1 {
  background: $white;
  border-top-left-radius: $ten;
  border-top-right-radius: $ten;
  text-align: left;
  
  .temp-field {
    p {
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-size: 2.1vh !important;
    }
  }
  p {
    text-align: left;
    line-height: 19px !important;
    font-size: 2.4vh !important;
    letter-spacing: 0px;
    margin: 1vh;
    padding-top: 0 !important ;
    padding-bottom: 0 !important ;
    font-family: $roboto;
    @include breakpoint(lgdesktop) {
      font-size: 2.4vh !important;
    }
  }
  label {
    text-align: left;
    line-height: 19px !important;
    font-size: 1.05em !important;
    letter-spacing: 0px;
    margin-top: 0;
    margin-bottom: 11px;
    padding-top: 0 !important ;
    padding-bottom: 0 !important ;
    font-family: $roboto;
    @include breakpoint(lgdesktop) {
      font-size: 1em !important;
    }
  }
  textarea {
    box-shadow: $dark-btm-shadow;
    border: 1px solid #c3c3c3;
    border-radius: $ten;
    resize: none;
    font-size: 1em;
    font-family: $roboto;
    @include breakpoint(lgdesktop) {
      font-size: 1em !important;
    }
    &:focus {
      border: 1px solid orange;
      box-shadow: 0px 3px 6px #0076ff29;
    }
  }
}
