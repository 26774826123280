@import url('../../../../global.css');

.table-settings-icon {
	height: 2.6vh;
	width: 2.6vh;
}

.table-settings {
	position: relative;
	height: fit-content;
	width: fit-content;
}

.table-setting-dropdown-cont {
	position: absolute;
	top: 6vh;
	right: 0;
	background-color: white;
	z-index: 999;
	height: fit-content;
	width: 15vw;
	border-radius: 1vh;
	overflow: auto;
	border: 1px solid #cecece;
}

.tasedr-td {
	border: 1px solid #cecece;
	border-collapse: collapse;
}

.famchevron-icon {
	height: 2vh;
	width: 2vh;
	margin: auto;
}

.child-container {
	height: 5vh;
	width: 90%;
	border: 1px solid #cecece;
	margin: 0.5vh auto 0.5vh auto;
	border-radius: 1vh;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
}

.child-icon {
	height: 1vh;
	width: 1vh;
	transform: rotate(-90deg);
	margin: 0 0.5vw 0 auto;
}

.child-label {
	font-family: var(--def-font);
	font-size: 1.8vh;
	margin: 0 0 0 0.5vw;
	color: #171725;
}

.cr-title {
	font-family: var(--def-font);
	font-size: 1.8vh;
	margin-left: 0.5vw;
}

.cr-backbutton {
	height: 2vh;
	width: 2vw;
}

.colre-sec-buttons {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: 5vh;
}

.colre-reset {
	border: 1px solid #5491e2;
	background-color: white;
	color: #5491e2;
	border-radius: 1vh;
	padding: 0.5vh 1vw;
	font-family: var(--def-font);
	font-size: 1.8vh;
	margin: 0 1vw 0 0;
	cursor: pointer;
}

.colre-apply {
	border: 1px solid #5491e2;
	background-color: #5491e2;
	color: #ffffff;
	border-radius: 1vh;
	padding: 0.5vh 1vw;
	font-family: var(--def-font);
	font-size: 1.8vh;
	margin: 0 1vw 0 auto;
	cursor: pointer;
}
