@import '../../../../assets/scss/variable.scss';
@import '../../../../assets/scss/mixins.scss';
@import url('../../../../global.css');

.step_3 {
  height: fit-content;
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  background: $white;
  box-shadow: $dark-btm-shadow;

  .Mui-disabled {
    .MuiAutocomplete-endAdornment {
      top: calc(50% - 16px);
    }
  }

  .MuiAutocomplete-endAdornment {
    top: calc(50% - 10px);
    .MuiSvgIcon-root {
      width: 0.85em;
      height: 0.9em;
    }
  }

  .MuiInput-underline {
    border: none !important;
  }

  .heading {
    text-align: left;
    line-height: 19px;
    letter-spacing: 0px;
    font-size: 2.4vh;
    font-weight: bold;
    color: $darkbluecolor;
    margin-top: 0;
    @include breakpoint(lgdesktop) {
      font-size: 2.4vh;
    }
  }
  .buy_sell {
    display: flex;
    align-items: center;
  }

  .sec-later-cont {
    .customCheckbox {
      height: fit-content;
    }
    label {
      font-family: $roboto;
      font-size: 2.1vh;
      font-weight: bold;
    }
    input {
      margin-top: 4.5px;
      height: 1rem !important;
      width: 1rem !important;
      margin-left: -1.5rem !important;
    }
  }

  .sec-check label {
    color: #0078d7;
  }

  .table-cont {
    padding: 0.5rem 0rem;
  }

  .table {
    margin-bottom: 1rem;
    color: #212529;
    border-collapse: separate;
    border-spacing: 0 7px;
    thead {
      color: $white;
      tr {
        height: fit-content !important;
      }
      th {
        cursor: default;
        background: white !important;
        font-size: 2.1vh !important;
        &:nth-child(1) {
          text-align: left;
          padding-left: 0;
        }
      }
    }
    tbody {
      height: 52px !important;
      tr {
        background-color: white !important;
      }
      td.sb_nickname {
        input {
          width: 13.5vw;
        }
      }
      td.sb_instrument {
        .instrument-cont {
          margin: 0 auto;
          width: fit-content;
        }
        select {
          width: 9.5vw;
        }
      }
      td.sb_exchange {
        select {
          width: 6.7vw;
        }
        .ex-cont {
          margin: 0 auto;
          width: fit-content;
        }
      }
      td.sb_expiry,
      td.sb_strike {
        select {
          width: 9.5vw;
        }
        .expiry-cont {
          margin: 0 auto;
          width: fit-content;
        }
        .strike-cont {
          margin: 0 auto;
          width: fit-content;
        }
      }
      td.sb_add_icon {
        .img-cont {
          margin: 0 auto;
          width: fit-content;
        }
        width: 5vw;
      }
      td {
        vertical-align: middle;
        border: none !important;
        input {
          width: 300px;
          height: 5vh !important;
          background: $white;
          box-shadow: none !important;
          border: 0.6000000238418579px solid #bab9b9 !important;
          border-radius: 4px !important;
          text-align: left;
          font-size: 2.4vh;
          @include breakpoint(mddesktop) {
            width: 180px;
          }
        }
        input:disabled {
          background-color: #f0f0f0;
          border: none !important;
        }
        select {
          width: 182px;
          height: 5vh;
          color: #97999f;
          background: $white;
          box-shadow: none !important;
          border: 0.6000000238418579px solid #bab9b9 !important;
          border-radius: 4px !important;
          display: inline;
          font-size: 2.4vh;
          font-family: $roboto;
          @include breakpoint(mddesktop) {
            width: 150px;
          }
        }
        select:disabled {
          background-color: #f0f0f0;
          border: none !important;
        }
        .circle {
          display: flex;
          align-items: center;
          justify-content: center;
          .close {
            cursor: pointer;
            font-size: 2.5rem;
            background: #f7f7f7;
            box-shadow: 0px 1px 3px #00000029;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 1vh;
            padding: 0 7px;
            height: 40px;
            width: 40px;
            position: relative !important;
            top: 0 !important;
            right: 0 !important;
            @include breakpoint(lgdesktop) {
              height: 5.7vh;
              width: 5.7vh;
            }
            img {
              margin-top: 0;
              @include breakpoint(lgdesktop) {
                height: 3.2vh;
                width: 3.2vh;
              }
            }
            span {
              position: absolute;
              top: -9px;
              left: 4px;
              font-size: 50px;
            }
          }
        }
      }
      .removeRow {
        width: fit-content;
      }
    }
  }
  .add_security {
    text-align: left;
    .centerContent {
      border-radius: 20px;
    }
    .centerContent:hover {
      background-color: #eaf6ff;
      p {
        color: #0078d7 !important;
      }
      img {
        filter: invert(35%) sepia(79%) saturate(1741%) hue-rotate(186deg)
          brightness(85%) contrast(103%);
      }
    }
    button {
      text-align: left;
      font-size: 2.4vh;
      line-height: 19px;
      letter-spacing: 0px;
      color: #7992ff;
      @include breakpoint(lgdesktop) {
        font-size: 1.9vh;
      }
    }
  }
  .additional {
    display: flex;
    align-items: center;
    margin-bottom: 1.5vh;
    .heading {
      text-align: left;
      font-size: 2.4vh;
      line-height: 20px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #171725;
      margin-right: 0px;
      padding-right: 11px;
      cursor: default !important;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      text-decoration: none;
      @include breakpoint(lgdesktop) {
        font-size: 2.1vh;
      }
    }
  }

  .MuiAutocomplete-hasPopupIcon {
    width: 182px;
    margin: 0 auto;
    height: fit-content;
    cursor: pointer;
    &.error-input {
      .MuiAutocomplete-inputRoot {
        input {
          border: 1px solid red;
        }
      }
    }
    @include breakpoint(lgdesktop) {
      width: 9vw;
    }
  }
  .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot {
    padding: 0 0 0 0 !important;
    input {
      width: 100% !important;
      padding: 0.375rem 0.75rem !important;
      height: 5vh;
      box-sizing: border-box;
      border-radius: 10px;
      font-family: $roboto;
      font-size: 2.4vh;
      cursor: pointer;
      text-align: left;
    }
  }
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }

  .Mui-disabled {
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    height: fit-content;

    input {
      height: 28px !important;
      padding: 0.375rem 0.75rem !important;
      font-size: 2vh;
      font-family: $roboto;
      &::placeholder {
        color: $black;
      }
    }
  }
  input.Mui-disabled::placeholder {
    color: #cebdbf;
    opacity: 0.36 !important;
    text-align: left;
    font-size: 2.4vh;
    font-family: $roboto;
  }
  input.MuiInput-input::placeholder {
    color: $black;
    opacity: 1;
    font-size: 2.4vh;
  }
}

.strategy .info {
  width: 2.5vh;
}
.search_select {
  display: flex;
  justify-content: center;
  height: 50px;
}
.MuiInput-underline {
  padding: 0 0 0 0 !important;
  border: none !important;
}
.search_select > div {
  width: 182px;
  border-radius: 10px;
  font-size: 1.9vh;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  box-shadow: 0px 1px 6px #aaaaaacc !important;
}

.search_select svg {
  fill: #00000085;
}

button:focus {
  box-shadow: none !important;
}

.ddExtraStyles-strategyBuilder {
  min-width: 9rem !important;
}

.SelectSecurities-container {
  display: flex;
  flex-direction: column;
  // overflow: auto;
  padding: 1rem;
}
