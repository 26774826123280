@import '../../../assets/scss/mixins.scss';
@import url('../../../global.css');

.market-click-modal {
	.modal-dialog {
		max-width: 720px;
		// margin: 0 !important;
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		width: 720px !important;
		height: 380px !important;
	}
	.modal-header {
		background: var(--white) !important;
		padding: 2.5rem 1rem;
		display: flex;
		align-items: center;
		@include breakpoint(lgdesktop) {
			padding: 1.8rem 1rem;
		}
		.close {
			top: 25px;
			right: 25px;
			img {
				height: 25px;
			}
			@include breakpoint(lgdesktop) {
				top: 14px;
			}
		}
	}
	.modal-content {
		overflow-x: hidden;
		background-color: transparent;
		width: 720px !important;
		@include breakpoint(lgdesktop) {
			transform: translate(0, 0);
			overflow-y: hidden !important;
		}
		.modal-body {
			background: #2e354b;
			// padding: 1rem !important;

			.row-one {
				padding-bottom: 1.75rem;
			}
			.row-two {
				// padding-bottom: 1rem;
			}
			.row-three {
				margin-bottom: 20px;
			}
			.row-four {
				background-color: #242b41;
			}

			.ticker-titles {
				color: #d3d3d3;
				margin-right: 5px;
				font-family: var(--def-font);
			}
			#ticker-time {
				text-align: center;
				font-family: var(--def-font);
				letter-spacing: 0px;
				color: #efd700;
				opacity: 1;
			}
			.ticker-green {
				text-align: center;
				font-family: var(--def-font);
				letter-spacing: 0px;
				color: #53bf3a;
				opacity: 1;
			}
			.ticker-red {
				text-align: center;
				font-family: var(--def-font);
				letter-spacing: 0px;
				color: #e44125;
				opacity: 1;
			}
		}
	}
}

.ticker-table-body {
	height: 85vh;
}

.ticker-table {
	background-color: #2e354b;
	width: 100%;
	height: 100%;
}

.ticekr-td {
	width: 12%;
	height: 6vh;
	padding-left: 1vw;
}

.ticker-titles {
	color: #d3d3d3;
	margin-right: 5px;
	font-family: var(--def-font);
	text-decoration: underline;
}

.ticker-title {
	font-family: var(--def-font);
	font-size: 28px;
	margin: 2vh 0 2vh 0;
}

#ticker-time {
	text-align: center;
	font-family: var(--def-font);
	letter-spacing: 0px;
	color: #efd700;
	opacity: 1;
}
.ticker-green {
	text-align: center;
	font-family: var(--def-font);
	letter-spacing: 0px;
	color: #53bf3a;
	opacity: 1;
}
.ticker-red {
	text-align: center;
	font-family: var(--def-font);
	letter-spacing: 0px;
	color: #e44125;
	opacity: 1;
}

.market-click-modal {
	background-color: #8e8e8e;
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
}

.ticker-popup-container {
	height: 100vh;
	width: 100vw;
	margin: auto;
	overflow: hidden;
}

.ticker-popup-title-container {
	height: 15vh;
	background-color: white;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 1vw;
}
