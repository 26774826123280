@import '../../../assets/scss/mixins.scss';
.strategy {
  background-color: transparent !important;
  .heading {
    h1 {
      color: var(--unnamed-color-ffffff);
      text-align: left;
      letter-spacing: 0px;
      color: #ffffff;
      font-size: 1.25em;
      @include breakpoint(lgdesktop) {
        font-size: 1.12em;
      }
    }
  }
}

