// font sizes
$thirty: 30px;
$twentytwo: 22px;
$twenty: 20px;
$eighteen: 18px;
$sixteen: 16px;
$fourteen: 14px;
$thirteen: 13px;
$twelve: 12px;
$ten: 10px;
$eight: 8px;

// color code
$darkblue: #090a38;
$blue: #202062;
$white: #ffffff;
$black: #000000;
// bluegradient ref - table header
$bluegradient: transparent linear-gradient(180deg, #04135d 0%, #010413 100%) 0%
  0% no-repeat padding-box;
$darkbluegradient: transparent linear-gradient(180deg, #1f1f86 0%, #00001a 100%)
  0% 0% no-repeat padding-box;
// lightblue, - header and sidebar
$lightblue: #1c76b2;
// lightgrey, darkgrey ref - pagination button
$darkgrey: #413f46;
$lightgrey: #c6c6c6;
$lightblack: #707070;
// cream ref - table row
$cream: #f1f0f0;
$darkbluecolor: #00166d;
// mediumblue ref - button
$mediumblue: #0078d7;
// red ref - error
$red: #ff0000;
// green ref - active
$green: #00aa0b;
// lightgreen ref - show backgound rishmonitoring
$lightgreen: #f0fff6;
// background colour for form
$backgroundform: #e9e9ef;

$violet: #ff00cc29;
$lightopacblue: #7992ff;

$dark-btm-shadow: 0px 3px 6px #00000029;
$orange: #f37c20;
$roboto: 'Roboto', sans-serif;
$popin: 'Poppins', sans-serif;