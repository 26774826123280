@import url('../../../../global.css');

.UM-cuf {
  height: 80vh;
  width: 50vw;
}

.UM-cuf-title-cont {
  background-color: #067bd8;
  height: 8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.UM-cuf-title {
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 1.3);
  color: white;
  margin: 0 0 0 1vw;
}

.UM-cuf-container {
  display: flex;
  flex-direction: column;

  height: 64vh;
  overflow-y: scroll;
}

.align-horizontally {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 10vh;
}

.umb-popupform-footer {
  background-color: #f5f5f5;
  height: 8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.unb-error-message {
  color: #e6604a;
  font-size: calc(var(--font-size) * 1.2);
  margin: 0 0 0 1vw;
}
