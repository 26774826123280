.dd-sec-name {
  font-family: var(--def-font);
  margin: 2vh auto 2vh auto;
  width: fit-content;
  height: fit-content;
  color: #0078d7;
}

.dd-cancel {
    cursor: pointer;
    position: absolute;
    height: 2vh;
    width: 2vh;
    top: -1vh;
    right: -1vh;
  }