@import '../../../../../assets/scss/variable.scss';
@import url('../../../../../global.css');

.position-container {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 2fr 2fr 2fr 1fr;
}
.close_position {
  position: relative;

  .circle_Position {
    position: absolute;
    right: 0;
    top: 0;
    // border: 1px solid black;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      height: 30px;
      width: 30px;
      i {
        position: absolute;
        top: 25%;
        left: 30%;
      }
    }
  }
}

.position-parent-container {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.logic-label {
  font-family: var(--def-font);
  font-size: var(--font-size);
  line-height: 1rem;
  letter-spacing: 0px;
  font-weight: 400;
  white-space: nowrap;
}

.postion-row {
  height: 3rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.2rem 0;
}

.uiSelector-position {
  border: var(--borderHover) !important;
  box-shadow: var(--normal-shadow) !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.5rem !important;

  &:focus {
    border: var(--focusBorder) !important;
  }
}
