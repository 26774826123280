@import url('../../../global.css');

.PageSizePicker-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: fit-content;
	width: fit-content;
	padding: 0.5rem;
}

.PageSizePicker-enteries-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: fit-content;
	width: fit-content;
	border: var(--inDdInfieldBorder);
	border-radius: 4px;
	padding: 0.5rem;
	cursor: pointer;
	transition: all 0.5s;
	margin: 0rem 0.5rem;
	position: relative;
}

.PageSizePicker-enteries-container:hover {
	border: var(--borderHover);
}

.PageSizePicker-enteries-container:focus {
	border: 1px solid var(--focus);
}

.PageSizePicker-dropdownIcon {
	height: 1rem;
	width: 1rem;
	margin-left: 0.5rem;
}

.PageSizePicker-label {
	color: var(--black);
}

#PageSizePicker-currentSize {
	font-weight: 600;
	color: var(--dark-black);
}

.PageSizePicker-dropdown {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 2rem;
	left: 0;
	background-color: white;
	overflow-y: auto;
	transition: all 0.3s;
}

.PageSizePicker-ddItemContainer {
	background-color: var(--white);
	padding: 0.5rem;
	border: 1px solid #ffffff;
}

.PageSizePicker-ddItemContainer:hover {
	border: var(--inDdInfieldBorder);
	background-color: #cecece11;
}

.PageSizePicker-ddClass {
	margin: 0.5rem;
}
