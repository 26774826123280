@import url('../../../../global.css');

.sec-head-cont {
  width: 100%;
  background-color: #f4f4f4;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  flex-grow: 1;
}

.sec-head {
  height: fit-content;
  font-weight: bold;
  margin: 1vh;
  font-family: 'Roboto', sans-serif;
  font-size: 2.4vh;
  font-weight: bold;
}

.sec-icon {
  margin: 1vh 1vh 1vh 0;
  height: 3.5vh;
  width: 3.5vh;
}

.buy-sell-cont {
  display: flex;
  margin: 2vh 0;
}

.buy_sell {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 3vw;
}

.info-icon {
  height: 2.4vh;
  width: 2.4vh;
}

.buy-sell-label {
  font-size: calc(var(--font-size) * 1.2) !important;
  font-weight: 500 !important;
  white-space: nowrap;
}

.sec-later-cont {
  display: flex;
  align-items: center;
  margin-right: 3vw;
}

.security-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.security-filter-text {
  font-weight: bold;
  font-size: 2.1vh;
}

.sb-instrument-allowed {
  width: fit-content;
}
