@import '../../../../assets/scss/variable.scss';
@import '../../../../assets/scss/mixins.scss';
@import url('../../../../global.css');

.uploadexcel {
  color: #fff !important;
  margin-left: 26px !important;
  background: white;
  border-radius: 12px;
  height: 35px !important;
  cursor: pointer;
  border: 1px black solid;
  @include breakpoint(lgdesktop) {
    font: normal normal bold 14px/30px Roboto;
  }
  a {
    display: block;
    padding: 5px 18px;
  }
  img {
    margin-right: 12px;
    margin-left: 0px !important;
    width: 40%;
    margin-bottom: 10px !important;
    margin: auto;
    @include breakpoint(lgdesktop) {
      height: 25px !important;
      width: 25px;
    }
  }
}

.familyDataTable {
  width: 100%;
  thead th {
    white-space: nowrap;
    font: none;
    font-family: var(--def-font) !important;
    font-size: 2vh !important;
    color: var(--dark-color) !important;
  }
  tbody tr td {
    // border-top: none !important;
    // border-bottom: none !important;
    font-family: var(--def-font) !important;
    font-size: 2vh !important;
    color: var(--dark-color) !important;
  }

  tbody tr td:nth-child(3) {
    width: 160px;
  }
  tbody tr td:nth-child(4) {
    width: 160px;
  }
  tbody tr td:nth-child(5) {
    width: 160px;
  }
  tbody tr td:nth-child(6) {
    width: 160px;
  }
  tbody tr td.elipsis {
    max-width: 160px;
    width: 160px;
  }

  .iconButton {
    &:focus {
      color: grey !important;
      border: solid orange 2px !important;
      outline: none;
      box-shadow: none !important;
    }
  }

  @include breakpoint(lgdesktop) {
    tbody tr td {
      font-size: 10px !important;
      .buttons {
        padding: 2px 0;
        .iconButton {
          margin: 10px;
        }
      }
    }
  }
}

.FamilyDashTable {
  .pagination {
    nav {
      // transform: translate(80px, 0);
    }
  }
}
.familyDasboard-popup {
  .all-popups {
    background: transparent !important;
  }
}

.buttons-area-fd {
  display: grid;
  grid-template-columns: 15% 15% 50% 20%;
  margin: 1vh 1vh 2vh 1vh;
}

.familyPopup .popup .create_2 {
  min-width: 400px;
}

.family-dashboard-top-btns {
  display: flex;
  height: 10vh;
  align-items: center;
  justify-content: space-between;
}

.family-table-text {
  font-family: var(--def-font);
  color: var(--dark-black);
  font-size: 2vh;
}

.fam-dash-td-w1 {
  width: 3vw;
  font-family: var(--def-font);
  text-align: left;
  white-space: nowrap;
  font-size: 1.8vh;
  border: 1px solid #dadada !important;
}

.fam-dash-td-w2 {
  width: 8vw;
  font-family: var(--def-font);
  text-align: left;
  white-space: nowrap;
  font-size: 1.8vh;
  border: 1px solid #cecece !important;
}

.fam-dash-td-w3 {
  width: 10vw;
  font-family: var(--def-font);
  text-align: left;
  white-space: nowrap;
  font-size: 1.8vh;
  border: 1px solid #cecece !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.familyTabContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.familyTabContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.familyDashboard-view {
  height: 80vh;
  width: 95vw;
}

.familyDashboard-top-bar {
  background-color: #067bd8;
  height: 8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.familyDashboard-text {
  font-family: var(--def-font);
  font-size: 2vh;
  color: white;
  margin-left: 1.5vw;
}

.familyDashboard-view-bottomBar {
  height: 8vh;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
}

.familyDashboard-view-body {
  height: 64vh;
  overflow: scroll;
}

.family-view-td-cont {
  display: grid;
  height: fit-content;
  width: 100%;
}

.family-view-td-key {
  font-size: 1.8vh;
  color: #171725;
  font-family: var(--def-font);
  margin: 0.6vh 0 auto 1vw;
  font-weight: 500;
}

.family-view-td-value {
  font-size: 1.8vh;
  color: #171725;
  font-family: var(--def-font);
  margin: auto 0 auto 1vw;
  font-weight: 400;
}

.startContainer {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 1vh 1.5vw;
}

.midContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 75vw;
}

.endContainer {
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 1vh 1.5vw;
}

.td-content-text {
  font-family: var(--def-font);
  font-size: var(--font-size);
  margin: 0 auto 0 0;
}

.fam-dash-tr:nth-child(even) {
  background-color: #f4fbff !important;
}
