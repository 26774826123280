.simple-table {
  display: table !important;
  width: 100%;
  margin: auto;
  border: 1px solid #cecece;
  border-radius: 5px !important;
  border-spacing: 2px !important;
}

.child-row,
.heading-row {
  font-size: 2vh;
  display: flex;
  align-items: center;

  width: 100%;
  height: 5vh;
}

.child-row {
  font-size: 1.8vh;
  height: 7vh;
}

.child-row-content,
.head-row-content {
  width: 100%;
  height: 100%;
  text-align: left;

  padding: 0.5vw;
  flex-grow: 1;
  border-right: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
}

.child-row-content {
  overflow-y: scroll;
}
.head-row-content {
  font-weight: 600;
}

.heading-row {
  background-color: #f4f4f4;
}

.no-results {
  color: red;
  font-size: 2vw;
  text-align: center;
}

.child-row-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.child-row-content {
  -ms-overflow-x-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
