@import url('../../../../global.css');

.dealerDash-topNav {
	display: flex;
	height: 10vh;
	align-items: center;
	justify-content: space-between;
}

.td-text {
	font-family: var(--def-font);
	font-size: var(--font-size);
	color: #171725;
	text-align: left;
	white-space: nowrap;
	border: var(--table-border);
}

.sm-iconButton-cont {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	height: 3.2vh;
	width: 3.2vh;
	background-color: #0078d7;
	border-radius: 4vh;
	outline: none;
}

.sm-iconButton-icon {
	height: 2vh;
	width: 2vh;
}

.adminDashboard-dealer-remark {
	font-family: var(--def-font);
	font-size: 2vh;
	border: 2px solid grey;
	border-radius: 1vh;
	height: 4vh;
	width: 12vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.dealerDashboard-feedback-popup {
	font-family: var(--def-font);
	font-size: 2vh;
	padding: 1vh;
}

.Edit-form-s1 {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	height: 10vh;
	margin: 1vh 1.5vw 1vh 1.5vw;
}

.dealer-view-header {
	height: 7vh;
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: #067bd8;
}

.Edit-form-title {
	color: white;
	font-family: var(--def-font);
	font-size: 2vh;
	margin: 1vh 0 1vh 1.5vw;
}

.Edit-form-s2 {
	border: 1px solid #d0d0d0;
	border-radius: 0.5vh;
	margin: 3vh 1.5vw 3vh 1.5vw;
}

.Edit-form-s2-title {
	background-color: #f4f4f4;
	display: flex;
	flex-direction: row;
	border-radius: 0.6vh 0.5vh 0vh 0vh;
}

.Edit-form-s2-txt {
	font-family: var(--def-font);
	font-size: 2vh;
	margin: 1.5vh auto 1vh 2vh;
	color: #0078d7;
}

.Edit-form-s2-cont {
	display: flex;
	flex-direction: column;
}

.ban-level-segment {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 0.5fr 0.5fr 0.5fr;
	height: 12vh;
}

.view-form-s1 {
	display: grid;
	grid-template-columns: 1fr 1.25fr;
}

.dvdd-cont {
	display: grid;
	grid-template-columns: 1fr 2fr;
	height: 5vh;
}

.dvdd-cont-key {
	font-family: var(--def-font);
	font-size: 2vh;
	color: #000;
	margin-left: 1vh;
	font-weight: 500;
	margin: 1vh;
}

.dvdd-cont-val {
	font-family: var(--def-font);
	font-size: 2vh;
	color: var(--black);
	margin-left: 4vh;
	margin: 1vh;
}

.dealer-footer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 1vh;
	height: 8vh;
	background-color: #f5f5f5;
}

.delete-segment-icon-cont {
	height: 4.5vh;
	width: 4.5vh;
	background-color: #0078d7;
	border-radius: 4.5vh;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 6vh auto auto auto;
	cursor: pointer;
}

.delete-segment-icon {
	height: 2vh;
	width: 2vh;
}

.dealer-footer-error {
	font-family: var(--def-font);
	color: red;
	font-size: 2vh;
}

.dealer-middle-content {
	height: 64vh;
	overflow-y: scroll;
}

.dealer-view-td-cont {
	display: grid;
	grid-template-columns: 1fr 3fr;
	height: 6vh;
}

.dealer-view-td-key {
	font-size: 1.7vh;
	color: #171725;
	font-family: var(--def-font);
	margin: auto 0 auto 1vh;
	font-weight: 600;
	white-space: nowrap;
}

.dealer-view-td-value {
	font-size: 2vh;
	color: #171725;
	font-family: var(--def-font);
	margin: auto 1vw auto 1vw;
	font-weight: 400;
}

.dealer-view-data-remark {
	font-family: var(--def-font);
	font-size: 2vh;
	margin: 1vh;
	color: #171725;
}

.dealerPopup .popup .create_2 {
	min-width: 400px;
}

.startContainer {
	display: flex;
	align-items: center;
	justify-content: start;
	padding: 1vh 1.5vw;
}

.midContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 1 75vw;
}

.endContainer {
	display: flex;
	align-items: center;
	justify-content: end;
	margin: 1vh 1.5vw;
}

.dealer-table-row:nth-child(even) {
	background-color: #f4fbff;
}

.dealer-edit-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 30vh;
}
