@use '../../newGlobal' as global;

.adv-label {
	display: flex;
	flex-direction: column;
	
	.label {
		color: global.$dark-black;
		font-family: global.$def-font;
		font-size: global.$fl1;
	}
}
