@import '../../../assets/scss/mixins.scss';
@import url('../../../global.css');
.analyzer-left {
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0 !important;
    font-family: var(--def-font);
  }
  select {
    margin: 0 10px;
    background: #ffffff;
    border-radius: 5px;
    width: 75px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(../../../assets/img/arrow-down-filled-triangle.svg);
    background-repeat: no-repeat;
    background-position: 90% 55%;
    background-size: 10px;
    cursor: pointer;
    border-radius: 4px;
    border: var(--inDdInfieldBorder);
    @include breakpoint(lgdesktop) {
      font-size: 1rem;
      width: 69px;
    }
  }
  select:focus {
    border: solid orange 2px !important;
    outline: none;
    box-shadow: none !important;
  }
}
