@import '../../../assets/scss/mixins.scss';
@import '../../../assets/scss/variable.scss';



.MuiPickersBasePicker-pickerView {
  max-width: 100% !important;
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
}
