.select-param-popup-cont {
  height: 70vh;
  width: 55vw;
  background-color: white;
}

.select-param-popup-title-container {
  height: 6vh;
  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: var(--primary-color);
  border-bottom: 1px solid #cecece;
}

.select-param-popup-title {
  color: white;
  font-family: var(--def-font);
  font-size: calc(var(--font-size) * 1.3);
  margin: 1vw;
  font-weight: 500;
}

.select-param-popup-middlearea {
  margin-top: 1vh;
  margin-bottom: 1vh;
  height: 54vh;
  display: flex;
  flex-direction: column;
}
.select-param-popup-middlearea-1 {
  height: 12vh;
  width: 94%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: center;
  justify-content: space-between;
}
.select-param-popup-middlearea .input-6-7 {
  height: 12vh;
  width: 94%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  margin-top: 1vh;
}
.input-6-7 .input-6 div {
  /* overflow: hidden; */
}
.input-6-7 .input-6 div div {
  /* overflow-y: scroll; */
}
.input-6-7 .input-6 .dropdownMenu {
  max-height: 20vh !important;
  overflow-y: scroll !important;
}

.select-param-popup-middlearea .input-6 {
  margin-right: 2.5vw;
}

.upload-file {
  width: 94%;
  height: 8vh;
  margin: 0 auto;
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.upload-file p {
  margin: 0;
  font-weight: 500;
  margin-bottom: 0.5vh;
}

.upload-file-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
label.file-upload-label {
  margin-bottom: 0 !important;
}
.file-upload-label img {
  height: 3.7vh;
  margin-left: 0.5vw;
  aspect-ratio: 1;
  padding: 0.5vh;

  border: 1px solid var(--primary-color);
  border-radius: 5px;
}
.file-upload-label img:hover {
  cursor: pointer;
}

.file-upload-placeholder {
  width: 97%;
  height: 3.5vh;
  padding: 1vh 0.5vw;
}

.image-blue-filter {
  filter: invert(36%) sepia(74%) saturate(4903%) hue-rotate(190deg)
    brightness(94%) contrast(101%);
}

.select-param-popup-buttonarea {
  height: 8vh;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.select-param-popup-middlearea .input-2 label {
  font-size: calc(var(--font-size));
  margin: 0 !important;
  padding-left: 0.3vw;
}
.select-param-popup-middlearea .input-2 .MuiFormControl-marginNormal {
  margin: 0 !important;
}
.select-param-popup-middlearea .input-3 label {
  font-size: calc(var(--font-size));
  margin: 0 !important;
  padding-left: 0.3vw;
}
.select-param-popup-middlearea .input-3 .MuiFormControl-marginNormal {
  margin: 0 !important;
}

.select-param-popup-middlearea .input-4 .lightText,
.select-param-popup-middlearea .input-5 .lightText {
  color: #7a7a7a !important;
}
